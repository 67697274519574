<div class="tooltip tooltip_{{id}} {{staticRight ? 'static_right' : ''}}">
  <ng-container *ngIf="hasIconAndBtnLabel; else hasIconOrBtnLabel">
    <button
    class="tooltipIcon tooltipIcon_{{id}} {{largeHitBox ? 'largeHitBox' : ''}} {{hoverStates ? 'hoverStates' : ''}}"
    (click)="handleOpenAndPosition($event)" (mousedown)="handleOpenAndPosition($event)" type="button"
    id="openButton_{{id}}" attr.aria-label="{{tooltipContent.btnHiddenText}} {{ assetName}}"
    [attr.aria-haspopup]="isMenuPopup ? true : false" (keydown)="handleKeyDownOpen($event)"
    [attr.aria-expanded]="isToopTipOpen">
    <span class="span_{{id}}">{{tooltipContent.openBtnLabel}}</span>
    <span class="{{openIconName}} icon_{{id}}" id="icon_{{id}}"></span>
  </button>
  </ng-container>
  <ng-template #hasIconOrBtnLabel>
 
    <button *ngIf="iemobile == true"
    class="ietooltipIcon  tooltipIcon_{{id}} {{openIconName}} {{largeHitBox ? 'largeHitBox' : ''}} {{hoverStates ? 'hoverStates' : ''}}"
    (click)="handleOpenAndPosition($event)" (mousedown)="handleOpenAndPosition($event)" type="button"
    id="openButton_{{id}}" attr.aria-label="{{tooltipContent.btnHiddenText}} {{ assetName}}"
    [attr.aria-haspopup]="isMenuPopup ? true : false" (keydown)="handleKeyDownOpen($event)"
    [attr.aria-expanded]="isToopTipOpen">{{tooltipContent.openBtnLabel}}</button>
    
    <button *ngIf="iemobile == false"
    class="tooltipIcon tooltipIcon_{{id}} {{openIconName}} {{largeHitBox ? 'largeHitBox' : ''}} {{hoverStates ? 'hoverStates' : ''}}"
    (click)="handleOpenAndPosition($event)" (mousedown)="handleOpenAndPosition($event)" type="button"
    id="openButton_{{id}}" attr.aria-label="{{tooltipContent.btnHiddenText}} {{ assetName}}"
    [attr.aria-haspopup]="isMenuPopup ? true : false" (keydown)="handleKeyDownOpen($event)"
    [attr.aria-expanded]="isToopTipOpen">{{tooltipContent.openBtnLabel}}</button>
  </ng-template>

  <div id="tooltipParent_{{id}}" style="display: none" class="tooltipParent">
    <!-- <div class="arrow" id="arrow_{{id}}" [ngClass]="{'top': this.showTopTooptip}">
      <div class="arrow-white" [ngClass]="{'top': this.showTopTooptip}"></div>
    </div> -->

    <div class="tooltipContainer" [class]="tooltipClass" id="tooltipContainer_{{id}}"
      [ngClass]="{'tooltipContainer_top': this.showTopTooptip, 'tooltipContainer_left':this.tooltipLeft}"
      (blur)="defocusTooltip($event)" (keydown.esc)="handleCloseTooltip($event)">

      <div class="tip-header">
        <div class="tip-x">
          <button type="button" class="icon-Close color03" *ngIf="!isMenuPopup" (click)="handleCloseTooltip($event)"
            id="closeButton_{{id}}" [attr.aria-label]="tooltipContent.cancel" (keydown)="handleKeyDownClose($event)"
            (blur)="defocusTooltip($event)"></button>
        </div>
      </div>

      <span tabindex="-1" (click)="handleOnFocusTooltipContent($event)" (blur)="defocusTooltip($event)">
        <div>{{tooltipContent.content}}</div>
        <ng-content></ng-content>
      </span>



      <button type="button" *ngIf="isMenuPopup" class="closeButton" (click)="handleCloseTooltip($event)"
        id="closeButton_{{id}}" attr.aria-label="{{tooltipContent.cancel}} {{ assetName }} {{tooltipContent.menu}}"
        (keydown)="handleKeyDownClose($event)" (blur)="defocusTooltip($event)">{{tooltipContent.cancel}}</button>
    </div>
  </div>
</div>


<app-modal *ngIf="mode.startsWith('modal')" [attr.id]="'ToolTip' + id" [id]="'ToolTip' + id" [class]="'tooltip_' + id"
  (keydown.esc)="handleClosemodal($event)" style='display: none;' [header]="'tooltipModalHeader' + id"
  [targetElement]="'openButton_' + id">
  <div class="modal-body" [ngClass]="mode">
    <div class='gridModal'>
      <div class="modal-header">
        <div class="modal-x">
          <button [attr.id]="'tooltipModalClose' + id" type="button" (click)='handleClosemodal($event)'
            class="icon-Close color03" [attr.aria-label]="tooltipContent.cancel"></button>
        </div>
        <div *ngIf="this.mode === 'modal' || this.mode === 'modal-soft' || this.mode === 'modal-add-acc-option'" [attr.id]="'tooltipModalHeader' + id"
          class='modal-title'>
          {{tooltipContent.title}}</div>
      </div>
      <div id="'tooltipModalContent' + id"  class="modal-content" [ngClass]="{'add-acc-content': this.mode === 'modal-add-acc-option'}">
        {{tooltipContent.content}}
        <ng-content select=".tooltip-lightbox"></ng-content>
      </div>
    </div>
  </div>
</app-modal>