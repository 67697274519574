import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Output, Input, ViewEncapsulation, OnDestroy, Inject } from '@angular/core';
import { AppStore } from '../../models/app-store';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { ObservableSubscriptionService } from '../../services/observable-subscription.service';
import { Subject, Subscription } from 'rxjs';
import { GlobalSignOffService } from '../../../core/services/global-signoff.service';
import { DOCUMENT, Location } from '@angular/common';
import { RoutePermissionService } from '../../services/route-permission.service';
import { Lang, Lob } from '../../models/lob.enum';
import { isDefined, openWindow } from 'src/app/shared/services/utils.service';
import { HttpService } from 'src/app/core/services/http.service';
import { CommonService } from '../../services/common.service';
import { GlobalHeaderService } from 'src/app/core/components/global-header/service/global-header.service';
import { QuotesResearchBridgeStore } from 'src/app/modules/txn/quotesResearch/bridge/data/quotes-research-bridge-store';
import { ModalService } from '../modal/services/modal.service';
import { PersistenceService } from 'src/app/core/services/persistence.service';
import { ExpansionStates } from '../../models/general.enum';
import { QuotesDetailStore } from 'src/app/modules/txn/quotesWatchList/quoteDetails/data/quotes-detail-store';
import { _User } from '../../models/user';
declare let require: any;
const gatewayConfig = require('../../../config/gateway-config.json');

enum Markets {
  CDN = "CDN",
  CA = "CA",
  US = "US",
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class HeaderComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  showMenu: boolean;
  lob: string;
  lang: string;
  predictiveQuery: string = "";
  isHome = false;
  isHelpButton = false;
  isHelp = false;
  isApp: boolean;
  isAnyApp: boolean;
  isInApp: boolean;
  PEEnabled: boolean = false;
  isSignedOn;
  predictiveState: ExpansionStates = ExpansionStates.CONTRACTED;
  queryParamDco:any;
  isSourceDCO:boolean = false;

  helpButtonPages: any = {
    "/home": true,
    "/signon": true,
    "/signOn/forgotSignOnPassword": true,
    "/signOn/changeSignOnPassword": true,
    "/signOn/changeSignOnPass/confirm": true,
    "/registerOnline/landing": true,
    "/registerOnline/createPassword": true,
    "/registerOnline/confirm": true,
    "/onlineRegistration/password": true,
    "/onlineRegistration/username": true,
    "/onlineRegistration/mailingOptions": true,
    "/onlineRegistration/confirmation": true
  }

  modernPageList: any = {
    "/signon": true,
    "/registerOnline/landing": true,
    "/registerOnline/createPassword": true,
    "/registerOnline/confirm": true,
    "/signOn/forgotSignOnPassword": true,
    "/signOn/changeSignOnPassword": true,
    "/signOn/changeSignOnPass/confirm": true,
    "/SSOpage": true,
    "/olbsso/signon": true
  }

  @Input() breakPoint;
  @Input() tabIndexValue: number;
  @Input() enableLogoClick: boolean;
  @Input() cibcLogoWithDesc = true;
  @Input() preHeader = false;
  @Input() showChevron: boolean;
  @Input() isSSO = false;
  @Input() unreadNews = false;
  @Input() globalRefresh = false;
  @Input() closeSearch: Subject<any>;
  @Output() menuClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() greyOut: EventEmitter<any> = new EventEmitter<any>();


  @ViewChild('sidebar') myDiv: ElementRef;
  queryParamLocale: string;

  get EXPAN() { return ExpansionStates; }


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public appStore: AppStore,
    private location: Location,
    private subscriptionService: ObservableSubscriptionService,
    public globalContent: GlobalContentStore,
    private globalSignOffService: GlobalSignOffService,
    private persistenceService: PersistenceService,
    public _routePermissionService: RoutePermissionService,
    private httpService: HttpService,
    private commonService: CommonService,
    private modalService: ModalService,
    private globalHeaderService: GlobalHeaderService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  ngOnInit() {
    this.isApp = this.appStore.isApp('app');
    this.isInApp = this.appStore.isApp('inapp');
    this.isAnyApp = this.appStore.isApp();
    this.lob = this.appStore.lob.toLowerCase();
    const storedDco = this.persistenceService.sessionStoreRetrieve('queryParamDco');
    const storedLocale = this.persistenceService.sessionStoreRetrieve('queryParamLocale');
    const queryParams = this.route.snapshot.queryParams;
    if(storedDco !== 'undefined' && storedDco !== null) {
      this.queryParamDco = storedDco;
    }
    else {
      if(queryParams["prtId"] !== 'undefined' && queryParams["prtId"] !== null){
        this.queryParamDco = queryParams["prtId"];
      }
    }
    if(storedLocale !== 'undefined' && storedLocale !== null) {
      this.queryParamLocale = storedLocale;
    } else {
      if(queryParams["locale"] !== 'undefined' && queryParams["locale"] !== null){
        this.queryParamLocale = queryParams["locale"];
      }
    }
    this.persistenceService.sessionStorePersist('isSourceDCO',this.isSourceDCO);
    if(this.queryParamDco !== undefined && this.queryParamDco !== null) {
      this.persistenceService.sessionStorePersist('queryParamDco',this.queryParamDco);
    }
    if(this.queryParamLocale !== undefined && this.queryParamLocale !== null) {
    this.persistenceService.sessionStorePersist('queryParamLocale',this.queryParamLocale);
    }

    this.persistenceService.sessionStorePersist('isSourceDCO',this.isSourceDCO);

    if(this.queryParamDco == 'dco' ||(storedDco == "dco" || storedLocale == "fr")){
      this.isSourceDCO = true;
      if(this.queryParamLocale == 'fr'){
        this.appStore.initUser({
          lang: Lang.FR,
          lob: Lob.IE,
          status: _User.Status.GUEST,
          platformInfo: {
            mode: this.appStore.platformInfo.mode,
            platform: this.appStore.platformInfo.platform,
            container: 'N/A',
            appVersion: 'N/A',
            version: 'N/A',
            revision: 'N/A',
            build: 'N/A',
            layout: document.body.clientWidth < 900 ? 0 : 1
          },
          userlob: undefined,
          premiumUser: false,
        }); 
      }
    }else{
      this.isSourceDCO = false;
      this.lang = this.appStore.lang;
    }
    this.persistenceService.sessionStorePersist('isSourceDCO',this.isSourceDCO);
    this.isHome = this.appStore.state.state.currentUrl == '/txn/accounts/myaccounts';
    this.isHelpButton = this.showHelpButton(this.appStore.state.state.currentUrl);
    this.isHelp = this.appStore.state.state.currentUrl == '/txn/help';
    this.isSignedOn = this.persistenceService.sessionStoreRetrieve('isSignedOn');
    if (this.lob === Lob.IE) {
      this.PEEnabled = this.appStore.state.user.premiumUser;
    }
    else {
      this.PEEnabled = false;
    }
    this.subscriptions.add(this.subscriptionService.stringLob.subscribe(
      (data) => {
        if (data) {
          this.lang = this.appStore.state.user.lang;
          this.lob = (data.toLowerCase() === 'cfpi') ? 'iis' : data.toLowerCase();
          if (this.lob === Lob.IE) {
            this.PEEnabled = this.appStore.state.user.premiumUser;
          }
          else {
            this.PEEnabled = false;
          }
        }
      }
    ));

    this.subscriptions.add(this.subscriptionService.stringLocale.subscribe(
      (data) => {
        if (data && !this.isSourceDCO && this.queryParamLocale != 'undefined') {
          this.lang = data;
        }
      }
    ));
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isHome = event.url == '/txn/accounts/myaccounts';
        this.isHelpButton = this.showHelpButton(event.url);
        this.isHelp = event.url == '/txn/help';
      }
    });
    if (!this.preHeader) {
      this.commonService.checkEdocNotification();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  showHelpButton(url) {
    return this.helpButtonPages[url] || url.startsWith("/home");
  }

  navigateToMyAccounts() {
    if (this.enableLogoClick) {
      this.router.navigate(['/txn/accounts/myaccounts']);
    } else {
      if (this.lob == Lob.IE && this.displayIEModernHeader(this.appStore.state.state.currentUrl)) {
        window.location.href = "https://www.investorsedge.cibc.com/" + this.lang + "/home.html"
      }
    }
  }

  displayIEModernHeader(url) {
    return this.lob == Lob.IE && (this.modernPageList[url] || url.indexOf("/home/") != -1);
  }

  navigateBack() {
    if (this.appStore.headerBackfn) {
      this.appStore.headerBackfn();
    } else {
      this.location.back();
    }
  }

  Menu() {
    this.menuClicked.emit(true);
  }

  signOff() {
    this.globalSignOffService.signOff();
  }


  isDesktop() {
    return this.commonService.isDesktop()
  }

  openHelp() {
    this.commonService.openHelpWindow();
  }

  toggleLocale() {
    if (this.appStore.lang === Lang.EN) {
      this.subscriptionService.passLocale('fr');
    } else {
      this.subscriptionService.passLocale('en');
    }
  }

  isPermission(routeEnum) {
    return this.commonService.checkPermissionForRoutes(routeEnum);
  }

  goToOnlineBanking() {
    this.getConnectOnlineURL().subscribe(
      (data: any) => {
        if (data) {
          const url = data.ssourl;
          window.location.href = url;
        }
        // this.loadingService.dismiss();
      },
      (err) => {
        // super.handleError(err);
        if (isDefined(err.error.Exception) && err.error.Exception !== null && isDefined(err.error.Exception.ErrorCode) &&
          err.error.Exception.ErrorCode === 'OLBSSO406') {
          window.location.href = err.error.Exception.OlbRedirecturl;
        } else {
          // Should be External Signon - AEM page For now redirecting to OBR2 Signon
          // this.router.navigate(['/signon']);
          throw err;
        }
      });
  }

  getConnectOnlineURL() {
    return this.httpService.post(gatewayConfig.APIServices.goToBanking.url, {});
  }

  selectSymbol(symbol) {
    if (this.isAnyApp) {
      if (this.router.url == '/txn/quotesWatchList/detail') {
        this.subscriptionService.headerSearchRefresh({
          SymbolName: symbol?.SymbolName,
          Market: symbol.Market === Markets.CDN || symbol.Market === Markets.CA ? Markets.CA : Markets.US
        });
      } else {
        QuotesDetailStore.inFlow = {
          Symbols: [{
            SymbolName: symbol?.SymbolName,
            Market: symbol.Market === Markets.CDN || symbol.Market === Markets.CA ? Markets.CA : Markets.US
          }]
        };
        this.router.navigate(['/txn/quotesWatchList/detail']);
      }
    } else {
      QuotesResearchBridgeStore.crossFlow = {
        action: 'eqQuotes',
        symbol: symbol.SymbolName,
        market: symbol.Market === Markets.CDN || symbol.Market === Markets.CA ? Markets.CA : Markets.US
      };
      this.router.navigate(['/txn/bridge/quotesResearch'], {
        skipLocationChange: true,
      });
      this.predictiveQuery = " ";
      setTimeout(() => {
        this.predictiveQuery = "";
      }, 200);
    }
  }

  selectExtra(destination) {
    if (destination.crossFlow) {
      QuotesResearchBridgeStore.crossFlow = destination.crossFlow;
      this.router.navigate([destination.url], {
        skipLocationChange: true
      });
    } else {
      this.router.navigate([destination.url]);
    }
    this.predictiveQuery = " ";
    setTimeout(() => {
      this.predictiveQuery = "";
    }, 200);
  }

  print() {
    window.print();
  }

  openNews() {
    this.modalService.open('HeaderNewsModal', 'header-news-modal-title');
    let modal = document.getElementById("HeaderNewsModal");
    if (modal) {
      let mainBody = modal.getElementsByClassName("header-news-modal-body")[0];
      if (mainBody) {
        mainBody.className = "header-news-modal-body opening";
      }
    }
  }

  changePredictiveState(val: ExpansionStates) {
    this.predictiveState = val;
    this.greyOut.emit(val);
  }

  goToMyAccountPage() {
    this.router.navigate(['/txn/accounts/myaccounts']);
  }

  openAEMHelp(url) {
    if(this.appStore.isApp()) {
      openWindow(url, this.appStore);
    } else {
      window.open(url, "_self");
    }
  }

  isToggleLocaleEnable() {
    return this._routePermissionService.enableToggleLocaleButton;
  }
}
