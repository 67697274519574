// replaced dyamically during build by jenkins

export interface BuildInfo {
    id: string;
    num: string;
    branch: string;
    tag: string;
}

export const buildInfo: BuildInfo = {
    id: '181069',
    num: '20250328.6',
    branch: 'OBRE-3895',
    tag: '1.0.364-snapshot-sit4-both'
};

