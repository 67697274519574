import { Component, OnDestroy, OnInit, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { AppStore } from 'src/app/shared/models/app-store';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { _User } from 'src/app/shared/models/user';
import { GlobalHeaderService } from './service/global-header.service';
import { SignonStore } from '../../../modules/pre-txn/signon/data/signon-store';
import { CommonService } from 'src/app/shared/services/common.service';
import { GlobalSignOffService } from '../../services/global-signoff.service';
import { of, Subscription } from 'rxjs';
import { isDefined } from 'src/app/shared/services/utils.service';
import { HttpService } from '../../services/http.service';
import { PersistenceService } from '../../services/persistence.service';
import { ModalService } from 'src/app/shared/components/modal/services/modal.service';
import { ContentService } from '../../services/content.service';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { inject } from '@angular/core/testing';
import { DOCUMENT } from '@angular/common';
declare let require: any;
const gatewayConfig = require('../../../config/gateway-config.json');

@Component({
  selector: 'app-global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss']
})

export class GlobalHeaderComponent implements OnInit, OnDestroy {
  @Input() unreadNews = false;

  lob: any;
  currentLob: any;
  subscriptions = new Subscription();
  showProfileMenu = false;
  profileMenuIndex = -1;
  profileMenuTimeout;
  isAnyApp: boolean;
  uniqueId: string = 'tpheaderotvc'
  profileTimer: any;

  focusableElments: { overlayContent: { focusableElements: any[] } };

  desktopContainer: HTMLElement;
  headerContainer: HTMLElement;
  footerContainer: HTMLElement;
  pageHeader: HTMLElement;
  pagefooter: HTMLElement;
  chatButton: HTMLElement;
  thirdLevelNavigation: HTMLElement;
  thirdLevelNavDropdown: HTMLElement;
  globalNav: HTMLElement;
  pageTitle: HTMLElement;


  constructor(
    private router: Router,
    public globalContent: GlobalContentStore,
    public appStore: AppStore,
    private modalService: ModalService,
    private _subscriptionService: ObservableSubscriptionService,
    private globalHeaderService: GlobalHeaderService,
    private _commonService: CommonService,
    private globalSignOffService: GlobalSignOffService,
    private persistentService: PersistenceService,
    private contentService: ContentService,
    private trackingService: TrackingService,
    private httpService: HttpService,
    @Inject(DOCUMENT) private document: Document
  ) { }
  ngOnInit() {
    this.isAnyApp = this.appStore.isApp();
    this.subscriptions.add(this._subscriptionService.stringLob.subscribe(lobData => {
      if (lobData) {
        this.currentLob = lobData.toUpperCase();
      }
    }));
  }

  signOff() {
    this.globalSignOffService.signOff();
  }

  goToMarketLens() {
    // Check whether marketlens trading password feature is disable or enable
    this.httpService.post(gatewayConfig.APIServices.marketlensSettings.url, {})
      .subscribe((res) => {

        if ((window as any).TrackingEnabled) {
          of(this.trackingService.tagInteraction('global-header:marketlens', false, true, false, true));
        }
        if (res.TradingPasswordValidation) {
          this.openMTPAppModal()
        } else {
          this._commonService.goToMarketlensWithoutPassword()
        }
      },
        (err) => {
          this.appStore.showErrorInPopup = true;
          throw err;
        });
  }

  tagmarketlensGuideInteraction() {
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagInteraction('global-header:marketlens-guide', false, true, false, true));
    }
  }
  

  

  openMTPAppModal() {
    if(this.document.getElementsByTagName('md-markets-overview')[0]) {
      this.document.getElementsByTagName('md-markets-overview')[0].setAttribute('tabindex','-1');
    }
    if(this.document.getElementsByTagName('md-my-watch-lists-widget')[0]) {
      this.document.getElementsByTagName('md-my-watch-lists-widget')[0].setAttribute('tabindex','-1');
    }
    // if(this.document.getElementsByTagName('md-markets-news-headlines')[0]) {
    //   this.document.getElementsByTagName('md-markets-news-headlines')[0].setAttribute('tabindex','-1');
    // }
    if(this.document.getElementById('nebula_div_btn')) {
      this.document.getElementById('nebula_div_btn').setAttribute('tabindex','-1');
    }
    if(this.document.getElementById('marketlensFocus')) {
      setTimeout(() => {
        this.document.getElementById('marketlensFocus').focus();
      },5);
    }

    this.modalService.open('marketlensTredingPwdModal', 'tradingPasswordInput')
  }

  closeMTPModal() {
    if(this.document.getElementsByTagName('md-markets-overview')[0]) {
      this.document.getElementsByTagName('md-markets-overview')[0].removeAttribute('tabindex');
    }
    if(this.document.getElementsByTagName('md-my-watch-lists-widget')[0]) {
      this.document.getElementsByTagName('md-my-watch-lists-widget')[0].removeAttribute('tabindex');
    }
    // if(this.document.getElementsByTagName('md-markets-news-headlines')[0]) {
    //   this.document.getElementsByTagName('md-markets-news-headlines')[0].removeAttribute('tabindex');
    // }
    if(this.document.getElementById('nebula_div_btn')) {
      this.document.getElementById('nebula_div_btn').setAttribute('tabindex','0');
    }
    if(this.document.getElementById('marketLensLink')) {
      setTimeout(() => {
        this.document.getElementById('marketLensLink').focus();
      },50)
    }
    this.modalService.close('marketlensTredingPwdModal', 'tradingPasswordInput')
  }

  isPermission(routeEnum) {
    return this._commonService.checkPermissionForRoutes(routeEnum);
  }

  goToAdvisorPage() {
    this.subscriptions.add(this.globalHeaderService.getAdvisorData().subscribe(
      (data: any) => {
        if (data && data.Url && data.HashRRCode) {
          let url = data.Url + data.HashRRCode;

          if (this.appStore.isApp()) {
            window.open(url, '_system', 'location=yes');
          } else {
            window.open(url, '_blank', 'location=yes, resizable=yes, scrollbars=yes');
          }
        }
      },
      (err) => {
        console.log('myAdvisor error = ');
        console.log(err);
      }));
  }

  goToOnlineBanking() {
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagInteraction('global-header:online-banking', false, true, false, true));
    }
    this.getConnectOnlineURL().subscribe(
      (data: any) => {
        if (data) {
          const url = data.ssourl;
          window.location.href = url;
        }
      },
      (err) => {
        if (isDefined(err.error.Exception) && err.error.Exception !== null && isDefined(err.error.Exception.ErrorCode) &&
          err.error.Exception.ErrorCode === 'OLBSSO406') {
          window.location.href = err.error.Exception.OlbRedirecturl;
        } else {
          throw err;
        }
      });
  }

  getConnectOnlineURL() {
    const currentURL = window.location.hash.slice(1);
    this.persistentService.sessionStorePersist('LastVisitedURLBeforeOLB', currentURL);
    return this.httpService.post(gatewayConfig.APIServices.goToBanking.url, {});
  }

  openHelp() {
    this._commonService.openHelpWindow();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  clickProfileMenu() {
    if (this.globalContent.list.profileMenu[0].target) {
      this.router.navigate([this.globalContent.list.profileMenu[0].target]);
    }
  }

  keyProfileMenu(event) {
    if (event.keyCode == 38) {
      this.openProfileMenu(false);
      event.preventDefault();
    }
    if (event.keyCode == 40) {
      this.openProfileMenu(true);
      event.preventDefault();
    }
  }

  focusOutProfileMenu(event) {
    this.profileMenuTimeout = setTimeout(() => {
      this.closeProfileMenu()
    });
  }

  cancelFocusOut() {
    clearTimeout(this.profileMenuTimeout);
  }

  closeProfileMenu() {
    this.profileTimer = setTimeout(() => {
      this.showProfileMenu = false;
      this.profileMenuIndex = -1;
    }, 200);
  }

  openProfileMenu(top) {
    this.showProfileMenu = true;
    if (top) {
      this.profileMenuIndex = 0;
    } else {
      this.profileMenuIndex = this.globalContent.list.profileMenu.length - 1;
    }
    setTimeout(() => {
      this.selectMenuItem(this.profileMenuIndex);
    });
    clearTimeout(this.profileTimer);
  }

  keyPofileMenuItem(event) {
    if (event.keyCode == 38) {
      if (this.profileMenuIndex == 0) {
        this.profileMenuIndex = this.globalContent.list.profileMenu.length - 1;
      } else {
        this.profileMenuIndex--;
      }
      this.selectMenuItem(this.profileMenuIndex);
      event.preventDefault();
    } else if (event.keyCode == 40) {
      if (this.profileMenuIndex == this.globalContent.list.profileMenu.length - 1) {
        this.profileMenuIndex = 0;
      } else {
        this.profileMenuIndex++;
      }
      this.selectMenuItem(this.profileMenuIndex);
      event.preventDefault();
    } else if (event.keyCode == 27) {
      this.closeProfileMenu();
      let el = document.getElementById("header-profile-button");
      if (el) {
        el.focus();
      }
      event.preventDefault();
    } else if (event.keyCode == 35) {
      this.profileMenuIndex = this.globalContent.list.profileMenu.length - 1;
      this.selectMenuItem(this.profileMenuIndex);
      event.preventDefault();
    } else if (event.keyCode == 36) {
      this.profileMenuIndex = 0;
      this.selectMenuItem(this.profileMenuIndex);
      event.preventDefault();
    } else if (event.keyCode >= 65 && event.keyCode <= 90) {
      let target = String.fromCharCode(event.keyCode).toLowerCase();
      let done = false;
      let current = this.profileMenuIndex;
      let i = current + 1;
      while (!done && i != current) {
        if (i == this.globalContent.list.profileMenu.length) {
          i = 0;
        }
        if (this.globalContent.list.profileMenu[i].firstLetter == target) {
          this.profileMenuIndex = i;
          this.selectMenuItem(this.profileMenuIndex);
          done = true;
          break;
        }
        if ( i == current && current == 0) {
          break;
        }
        i++;
      }
      event.preventDefault();
    }

  }

  selectMenuItem(i) {
    let el = document.getElementById("header-profile-menu-item-" + i);
    if (el) {
      el.focus();
    }
  }

  hoverProfileMenuItem(event, i) {
    if (i != this.profileMenuIndex) {
      this.profileMenuIndex = i;
      this.selectMenuItem(i);
    }
    event.preventDefault();
  }

  clickProfileMenuItem(item) {
    if (item.type == "link") {
      this.router.navigate([item.target]);
    } else if (item.type == "dco") {
      this.openDCOAppModal();
      //this.submitDCOApp();
    } else if (item.type == "signOff") {
      this.signOff();
    }
    this.profileMenuOmniture(item.omni);
    this.closeProfileMenu();
  }

  openDCOAppModal() {
    this.modalService.open('dcoAppGlobalHeader', 'dcoAppModalHeaderGlobal');
  }

  submitDCOApp() {
    this._commonService.goToDCOApplication().subscribe(
      (data) => {
        console.log("Success details", data);
        this.dcoFormSubmit(data);
      },
      (err) => {
        console.log("Error details", err);
        this.dcoFormSubmit();
      }
    )
  }
  dcoFormSubmit(data?) {
    this.modalService.close('dcoAppGlobalHeader', 'header-profile-button');
    let domain = "https://";
    if (window.location.host == 'm2.onlinebrokerage.cibc.com') {
      domain += 'm.onlinebrokerage.cibc.com';
    } else {
      domain += window.location.host;
    //enable for local testing depending on the environment u are connected to change the domain to either sit, dit
    //domain += 'm.sit4.onlinebrokerage.cibc.com';
    }
    const target = this.appStore.isApp() ? "_system" : "_self";
    const token = data?.DcoAcctOpenJwtToken;
    const lang = this.appStore.lang;
    let url = domain + "/dco/html/registerOnline.html#"+lang;
    if (token) {
      url = url + "/" + encodeURIComponent(token);
    }
    window.open(url, target);
    if (this.appStore.isApp('app')) {
      this.globalSignOffService.obr2DCOWrapperSignoff();
    } else if (this.appStore.isApp('inapp')) {
      this.globalSignOffService.signOff(true, true);
    }
  }

  closeDCOModal() {
    this.modalService.close('dcoAppGlobalHeader', 'header-profile-button');
  }

  isDesktop() {
    return this._commonService.isDesktop();
  }

  profileMenuOmniture(primaryName) {
    if (primaryName && primaryName != "") {
      let data = "user-icon:" + primaryName;
      if ((window as any).TrackingEnabled) {
        of(this.trackingService.tagInteraction(data, true, true, false, true));
      }
    }
  }

  openNews() {
    this.modalService.open('HeaderNewsModal', 'header-news-modal-title');
    let modal = document.getElementById("HeaderNewsModal");
    if (modal) {
      let mainBody = modal.getElementsByClassName("header-news-modal-body")[0];
      if (mainBody) {
        mainBody.className = "header-news-modal-body opening";
      }
    }
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagInteraction('global-header:whats-new', true, true, false, true));
    }
  }

  openWindow(moreListItem) {
    window.open(moreListItem, '_system', 'location=yes, resizable=yes, scrollbars=yes');  
  }
}