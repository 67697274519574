// replaced dyamically during build by jenkins

export interface BuildInfo {
    id: string;
    num: string;
    branch: string;
    tag: string;
}

export const buildInfo: BuildInfo = {
    id: '179485',
    num: '20250319.1',
    branch: 'OBRE-3895',
    tag: '1.0.362-snapshot-sit4-both'
};

