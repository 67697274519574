import { RouteGroup } from "../shared/models/route-config";


export const QuotesResearchConfig: RouteGroup = {
  "/txn/quotesResearch/marketCentre": {
    name: "QuotesResearch",
    service: "QuotesResearchService",
    store: "QuotesResearchStore",
    resolveContent: "quotesresearch",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      hierarchy: "quotes-research",
      name: "market-centre",
      nameGA: "Quotes and Research - Market Centre",
    },
  },
  "/txn/quotesResearch/watchLists": {
    name: "QuotesResearch",
    service: "QuotesResearchService",
    store: "QuotesResearchStore",
    resolveContent: "quotesresearch",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      hierarchy: "quotes-research",
      name: "watch-lists",
      nameGA: "Quotes and Research - Watch Lists",
    },
  },
  "/txn/quotesResearch/stockCentre": {
    name: "QuotesResearch",
    service: "QuotesResearchService",
    store: "QuotesResearchStore",
    resolveContent: "quotesresearch",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      hierarchy: "quotes-research",
      name: "stock-centre",
      nameGA: "Quotes and Research - Stock Centre",
    },
  },
  "/txn/quotesResearch/optionCentre": {
    name: "OptionCentre",
    service: "OptionCentreService",
    store: "OptionCentreStore",
    resolveContent: "optioncentre",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      hierarchy: "quotes-research",
      name: "option-centre",
      nameGA: "Quotes and Research - Option Centre",
    },
  },
  "/txn/quotesResearch/etfCentre": {
    name: "QuotesResearch",
    service: "QuotesResearchService",
    store: "QuotesResearchStore",
    resolveContent: "quotesresearch",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      hierarchy: "quotes-research",
      name: "etf-centre",
      nameGA: "Quotes and Research - Etf Centre",
    },
  },
  "/txn/quotesResearch/fundCentre": {
    name: "QuotesResearch",
    service: "QuotesResearchService",
    store: "QuotesResearchStore",
    resolveContent: "quotesresearch",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      hierarchy: "quotes-research",
      name: "fund-centre",
      nameGA: "Quotes and Research - Fund Centre",
    },
  },
  "/txn/quotesResearch/analystReports": {
    name: "QuotesResearch",
    service: "QuotesResearchService",
    store: "QuotesResearchStore",
    resolveContent: "quotesresearch",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      hierarchy: "quotes-research",
      name: "analyst-reports",
      nameGA: "Quotes and Research - Analyst Reports",
    },
  },
  "/txn/quotesResearch/myAlerts": {
    name: "QuotesResearch",
    service: "QuotesResearchService",
    store: "QuotesResearchStore",
    resolveContent: "quotesresearch",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      hierarchy: "quotes-research",
      name: "alerts",
      nameGA: "Quotes and Research - Alerts",
    },
  },
  "/txn/quotesResearch/factSheets": {
    name: "InvestmentFactSheets",
    service: 'InvestmentFactSheetsService',
    servicePath: "txn/investment-factsheets/service/investment-factsheets",
    store: "InvestmentFactSheetsStore",
    resolveContent: "investvestmentfactSheets",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      hierarchy: "quotes-research",
      name: "investment-fact-sheets",
      nameGA: "Quotes and Research - Investment Fact Sheets",
    },
  },
  "/txn/quotesResearch/technicalInsights": {
    name: "QuotesResearchTechnicalInsights",
    service: "QuotesResearchTechnicalInsightsService",
    store: "QuotesResearchTechnicalInsightsStore",
    resolveContent: "quotesresearchtechnicalinsights",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      hierarchy: "quotes-research",
      name: "technical-insights",
      nameGA: "Quotes and Research - Technical Insights",
    },
  },
  "/txn/quotesResearch/tradeIdeas": {
    name: "QuotesResearchStrategyBuilder",
    service: "QuotesResearchStrategyBuilderService",
    store: "QuotesResearchStrategyBuilderStore",
    resolveContent: "quotesresearchStrategyBuilder",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      hierarchy: "quotes-research",
      name: "trade-ideas",
      nameGA: "Quotes and Research - Trade Ideas",
    },
  },
};
