import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpService } from '../services/http.service';
import { AppStore } from 'src/app/shared/models/app-store';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
@Pipe({
  name: 'currencyFormatter',
  pure: true
})
export class CurrencyFormatterPipe implements PipeTransform {

  user: any = this.appStore.state.user;

  constructor(
    private sanitizer: DomSanitizer,
    public httpService: HttpService,
    public appStore: AppStore,
    public globalContent: GlobalContentStore
  ) {
    // console.log('Initializeing Currency Formatter ######');
  }

  transform(value: string, currencyCode: string, mode?: string, page?: string): any {
    if (this.globalContent !== undefined) {
      if (currencyCode == null) {
        currencyCode = 'CAD';
      }
      if (currencyCode == 'CA') {
        currencyCode = 'CAD';
      }
      if (currencyCode == 'US') {
        currencyCode = 'USD';
      }
      if (page === 'accounts') {
        if (mode == 'format-currency-sr') {
          const symbo = this.globalContent.list.currencyDisplay[currencyCode] ? this.globalContent.list.currencyDisplay[currencyCode] : '';
          // const changesym = this.currencymap[currencyCode].chSymbol;
          const accContent = this.globalContent.list.currencyLongName[currencyCode] ?
            this.globalContent.list.currencyLongName[currencyCode] : '';
          // const accCont_short = this.currencymap[currencyCode].accessibility[lang].short;
          if (!value) {
            return '<span> - </span>';
          } else {
            const dispVal = value + ' ' + symbo;
            return this.formatHtml(value + ' ' + accContent, dispVal);
          }
        } else {
          const symbo = this.globalContent.list.currencySymbol[currencyCode] ? this.globalContent.list.currencySymbol[currencyCode] : '';
          // const changesym = this.currencymap[currencyCode].chSymbol;
          const accContent = this.globalContent.list.currencyLongName[currencyCode] ?
            this.globalContent.list.currencyLongName[currencyCode] : '';
          // const accCont_short = this.currencymap[currencyCode].accessibility[lang].short;
          if (!value) {
            return '<span> - </span>';
          } else {
            const dispVal = this.user.lang === 'en' ? symbo + value : value + ' ' + symbo;
            if (value.indexOf('-') === 0) {
              return this.formatHtml((value.replace('-', this.globalContent.text.negativeSign) + ' ' + accContent),
                (this.user.lang === 'en' ? value.replace('-', '-' + symbo) : value + ' ' + symbo));
            } else {
              return this.formatHtml(value + ' ' + accContent, dispVal);
            }
          }
        }
      } 
      if(page === 'fx') {
        //$
        const symbo = this.globalContent.list.currencySymbol[currencyCode] ? this.globalContent.list.currencySymbol[currencyCode] : '';
        // Canadian or US dollars
        const accContent = this.globalContent.list.currencyLongName[currencyCode] ?
          this.globalContent.list.currencyLongName[currencyCode] : '';

        const currencyLabel = this.globalContent.list.currencyDisplayFx[currencyCode] ? this.globalContent.list.currencyDisplayFx[currencyCode] : '';

        if (!value) {
          return '<span> - </span>';
        } else {
          const dispVal = this.user.lang === 'en' ? symbo + value : value + ' ' + symbo;
          if (value.indexOf('-') === 0) {
            return this.formatCurrencyHtml((value.replace('-', this.globalContent.text.negativeSign) + ' ' + accContent),
              (this.user.lang === 'en' ? value.replace('-', '-' + symbo) : value + ' ' + symbo), currencyLabel);
          } else {
            return this.formatCurrencyHtml(value + ' ' + accContent, dispVal, currencyLabel);
          }
        }
      } else {
        const sym = this.globalContent.list.currencyDenotationSymbol[currencyCode] ?
          this.globalContent.list.currencyDenotationSymbol[currencyCode] : '';
        const changesym = this.globalContent.list.currencySymbol[currencyCode] ?
          this.globalContent.list.currencySymbol[currencyCode] : '';
        const accCont = (currencyCode === 'USD' ?
          this.globalContent.text.USDollars : (this.globalContent.list.currencyLongName && this.globalContent.list.currencyLongName[currencyCode]) ? this.globalContent.list.currencyLongName[currencyCode] : '');
        const accContShort = this.globalContent.list.currencyShortName[currencyCode] ?
          this.globalContent.list.currencyShortName[currencyCode] : '';
        if (mode === 'accessibilityOnly') {
          return this.formatPureAccessibility(accCont, value);
        } else if (!value) {
          return '<span> - </span>';
        } else {
          const dispVal = this.user.lang === 'en' ? sym + value : value + ' ' + sym;
          switch (mode) {
            case 'format-default': {
              if (value.indexOf('-') === 0) {
                return (this.user.lang === 'en' ? value.replace('-', '-' + changesym) : value + ' ' + changesym);
              } else {
                return (this.user.lang === 'en' ? changesym + value : value + ' ' + changesym);
              }
            }
            case 'format-dash': {
              if (value =="-") {
                return value
              }
              else if (value.indexOf('-') === 0) {
                return (this.user.lang === 'en' ? value.replace('-', '-' + changesym) : value + ' ' + changesym);
              } else {
                return (this.user.lang === 'en' ? changesym + value : value + ' ' + changesym);
              }
            }
            case 'format-default-sr': {
              if (value.indexOf('-') === 0) {
                return this.formatHtml((value.replace('-', this.globalContent.text.negativeSign) + ' ' + accCont),
                  (this.user.lang === 'en' ? value.replace('-', '-' + changesym) : value + ' ' + changesym));
              } else {
                return this.formatHtml(value + ' ' + accCont, (this.user.lang === 'en' ? changesym + value : value + ' ' + changesym));
              }
            }
            case 'format-symboless-sr': {
              if (value.indexOf('-') === 0) {
                return this.formatHtml((value.replace('-', this.globalContent.text.negativeSign) + ' ' + accCont), value);
              } else {
                return this.formatHtml(value + ' ' + accCont, value);
              }
            }
            case 'format-currency-sr': {
              if (this.globalContent.list.currencyDisplay[currencyCode]) {
                if (value.indexOf('-') === 0) {
                  return this.formatCurrencyHtml((value.replace('-', this.globalContent.text.negativeSign) + ' ' + accCont),
                    (this.user.lang === 'en' ? value.replace('-', '-' + changesym) : value + ' ' + changesym),
                    this.globalContent.list.currencyDisplay[currencyCode]);
                } else {
                  return this.formatCurrencyHtml(value + ' ' + accCont, (this.user.lang === 'en' ? changesym + value : value + ' ' + changesym), this.globalContent.list.currencyDisplay[currencyCode]);
                }
              } else {
                if (value.indexOf('-') === 0) {
                  return this.formatHtml((value.replace('-', this.globalContent.text.negativeSign) + ' ' + accCont),
                    (this.user.lang === 'en' ? value.replace('-', '-' + sym) : value + ' ' + sym));
                } else {
                  return this.formatHtml(value + ' ' + accCont, dispVal);
                }
              }
            }
            case 'format-currency-noDollar-sr': {
              if (this.globalContent.list.currencyDisplay[currencyCode]) {
                if (value.indexOf('-') === 0) {
                  return this.formatCurrencyHtmlUnbold((value.replace('-', this.globalContent.text.negativeSign) + ' ' + accCont),
                    (this.user.lang === 'en' ? value.replace('-', '-') : value),
                    this.globalContent.list.currencyDisplay[currencyCode]);
                } else {
                  return this.formatCurrencyHtmlUnbold(value + ' ' + accCont, (this.user.lang === 'en' ? value.replace('-', '-') : value), this.globalContent.list.currencyDisplay[currencyCode]);
                }
              } else {
                if (value.indexOf('-') === 0) {
                  return this.formatHtml((value.replace('-', this.globalContent.text.negativeSign) + ' ' + accCont),
                    (this.user.lang === 'en' ? value.replace('-', '-' + sym) : value + ' ' + sym));
                } else {
                  return this.formatHtml(value + ' ' + accCont, dispVal);
                }
              }
            }
            case 'neg-sr': {
              if (value.indexOf('-') === 0) {
                return this.formatHtml(value.replace('-', this.globalContent.text.negativeSign), value);
              } else {
                return value;
              }
            }
            case 'format-percent': {
              if (value =="-") {
                return value;
              }  else{
              return value + '%';
              }
            }
            case 'format': {
              if (value.indexOf('-') === 0) {
                return this.formatHtml((value.replace('-', this.globalContent.text.negativeSign) + ' ' + accCont),
                  (this.user.lang === 'en' ? value.replace('-', '-' + sym) : value + ' ' + sym));
              } else {
                return this.formatHtml(value + ' ' + accCont, dispVal);
              }
            }
            case 'format-noDollar': {
              if (value.indexOf('-') === 0) {
                return this.formatHtml((value.replace('-', this.globalContent.text.negativeSign) + ' ' + accCont),
                  (this.user.lang === 'en' ? value.replace('-','-') : value));
              } else {
                return this.formatHtml(value + ' ' + accCont, dispVal);
              }
            }
            case 'format-short': {
              if (value.indexOf('-') === 0) {
                return this.formatHtml((value.replace('-', this.globalContent.text.negativeSign) + ' ' + accContShort),
                  (this.user.lang === 'en' ? value.replace('-', '-' + sym) : value + sym));
              } else {
                return this.formatHtml(value + ' ' + accContShort, dispVal);
              }
            }
            case 'change': {
              const changeVal = (this.user.lang === 'en' ? changesym + value : value + ' ' + changesym);
              const changeValAcc = value + ' ' + accContShort;
              if (value.replace(/[^0-9.-]+/g, '') === '0.00' || value.replace(/[^0-9\.,]/g, '') === '0,00') {
                return this.formatupdown(changeValAcc, (changeVal), '', '');
              } else {
                if (value.indexOf('-') === 0) {
                  return this.formatupdown(value.replace('-', '') + accContShort,
                    (this.user.lang === 'en' ? value.replace('-', '-' + changesym) : value + ' ' + changesym),
                    this.globalContent.text.down, 'color31');
                } else {
                  return this.formatupdown(changeValAcc, '+' + changeVal, this.globalContent.text.up, 'color73');
                }
              }
            }
            case 'change-arrow': {
              const changeVal = (this.user.lang === 'en' ? changesym + value : value + ' ' + changesym);
              const changeValAcc = value + ' ' + accContShort;
              if (value.replace(/[^0-9.-]+/g, '') === '0.00' || value.replace(/[^0-9\.,]/g, '') === '0,00') {
                return this.formatupdown(changeValAcc, (changeVal), '', '');
              } else {
                if (value.indexOf('-') === 0) {
                  return this.formatupdown(value.replace('-', '') + accContShort,
                    (this.user.lang === 'en' ? value.replace('-', '-' + changesym) : value + ' ' + changesym),
                    this.globalContent.text.down, 'color31 icon-Arrow-Down');
                } else {
                  return this.formatupdown(changeValAcc, '+' + changeVal, this.globalContent.text.up, 'color73 icon-Arrow-Up');
                }
              }
            }
            case 'gainloss': {
              if (value === '0.00' || value === '0,00') {
                return this.formatupdown(value + accCont, dispVal, '', '');
              } else {
                if (value.indexOf('-') === 0) {
                  return this.formatupdown((value.replace('-', '') + ' ' + accCont),
                    (this.user.lang === 'en' ? value.replace('-', '-' + sym) : value + ' ' + sym), this.globalContent.text.down, 'color31');
                } else {
                  return this.formatupdown(value + ' ' + accCont, '+' + (dispVal), ', ' + this.globalContent.text.up, 'color73');
                }
              }
            }
            case 'gainloss-currency-sr': {
              if (this.globalContent.list.currencyDisplay[currencyCode]) {
                if (value === '0.00' || value === '0,00') {
                  return this.formatupdownCurrency(value + ' ' + accCont, (this.user.lang === 'en' ? changesym + value : value + ' ' + changesym), '', '', this.globalContent.list.currencyDisplay[currencyCode]);
                } else {
                  if (value.indexOf('-') === 0) {
                    return this.formatupdownCurrency((value.replace('-', '') + ' ' + accCont),
                      (this.user.lang === 'en' ? value.replace('-', '-' + changesym) : value + ' ' + changesym), this.globalContent.text.down, 'color31', this.globalContent.list.currencyDisplay[currencyCode]);
                  } else {
                    return this.formatupdownCurrency(value + ' ' + accCont, '+' + (this.user.lang === 'en' ? changesym + value : value + ' ' + changesym), ', ' + this.globalContent.text.up, 'color73', this.globalContent.list.currencyDisplay[currencyCode]);
                  }
                }
              } else {
                if (value === '0.00' || value === '0,00') {
                  return this.formatupdown(value + accCont, dispVal, '', '');
                } else {
                  if (value.indexOf('-') === 0) {
                    return this.formatupdown((value.replace('-', '') + ' ' + accCont),
                      (this.user.lang === 'en' ? value.replace('-', '-' + sym) : value + ' ' + sym), this.globalContent.text.down, 'color31');
                  } else {
                    return this.formatupdown(value + ' ' + accCont, '+' + (dispVal), ', ' + this.globalContent.text.up, 'color73');
                  }
                }
              }
            }
            case 'percent': {
              if (value === '0.00%' || value === '0,00 %') {
                return this.formatupdown(value, value, '', '');
              } else {
                if (value.indexOf('-') === 0) {
                  return this.formatupdown((value.replace('-', '') + ' '),
                    value, this.globalContent.text.down, 'color31');
                } else {
                  return this.formatupdown(value, '+' + value, ', ' + this.globalContent.text.up, 'color73');
                }
              }
            }
            case 'colorChange':{
            
              if (value.indexOf('-') === 0) {
                return this.formatupdown((value.replace('-', '') + ' '),
                    value, this.globalContent.text.down, 'color31');
              } else {
                return this.formatupdown(value, '+' + value, ', ' + this.globalContent.text.up, 'color73');
              
               }
            }

            case 'percentBracket': {
              if (value === '0.00%' || value === '0,00 %') {
                return this.formatupdownBracket(value, value, '', '');
              } else {
                if (value.indexOf('-') === 0) {
                  return this.formatupdownBracket((value.replace('-', '') + ' '),
                    value, this.globalContent.text.down, 'color31');
                } else {
                  return this.formatupdownBracket(value, '+' + value, ', ' + this.globalContent.text.up, 'color73');
                }
              }
            }
          }
        }
      }
    }
  }

  formatHtml(accessContent: string, val: string) {
    return this.sanitizer.bypassSecurityTrustHtml(
      '<span class="sr-only">' + accessContent + '</span><span aria-hidden="true">' + val + '</span>');
  }

  formatCurrencyHtml(accessContent: string, val: string, curr: string) {
    return this.sanitizer.bypassSecurityTrustHtml(
      '<span class="sr-only">' + accessContent + '</span><span aria-hidden="true">' + val + '</span><span aria-hidden="true" class="currency-inline"> ' + curr + '</span>');
  }

  formatCurrencyHtmlUnbold(accessContent: string, val: string, curr: string) {
    return this.sanitizer.bypassSecurityTrustHtml(
      '<span class="sr-only">' + accessContent + '</span><span aria-hidden="true">' + val + '</span><span aria-hidden="true" class="currency-inline-unbols"> ' + curr + '</span>');
  }

  formatupdown(accessContent: string, val: string, updown: string, className: string) {
    return this.sanitizer.bypassSecurityTrustHtml(
      '<span class="sr-only">' + updown + ' , ' + accessContent +
      '</span><span aria-hidden="true" class="' + className + '" >' + val + '</span>');
  }
  formatupdownBracket(accessContent: string, val: string, updown: string, className: string) {
    if (val.indexOf('-') === 0) {
   
    // return this.sanitizer.bypassSecurityTrustHtml('('+
    //   '<span class="sr-only">' + updown + ' , ' + accessContent +
    //   '</span><span aria-hidden="true" class="' + className + '" >' + val + '</span>'+')');
    return this.sanitizer.bypassSecurityTrustHtml(
       '<span class="color31">(' + '<span class="sr-only">' + updown + ', ' + accessContent + '</span>' + '<span aria-hidden="true" class="' + className + '">' + val + '</span>' + ')</span>' );
  }else{
    return this.sanitizer.bypassSecurityTrustHtml(
      '<span class="color73">(' + '<span class="sr-only">' + updown + ', ' + accessContent + '</span>' + '<span aria-hidden="true" class="' + className + '">' + val + '</span>' + ')</span>' );
  }
}

  formatupdownCurrency(accessContent: string, val: string, updown: string, className: string, curr: string) {
    return this.sanitizer.bypassSecurityTrustHtml(
      '<span class="sr-only">' + updown + ' , ' + accessContent +
      '</span><span aria-hidden="true" class="' + className + '" >' + val + '</span><span aria-hidden="true" class="currency-inline ' + className + '"> ' + curr + '</span>');
  }

  formatPureAccessibility(accessContent: string, val: string) {
    return this.sanitizer.bypassSecurityTrustHtml(
      '<span class="sr-only">' + accessContent + '</span><span aria-hidden="true">' + val + '</span>');
  }
}
