import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { FieldLevelErrorComponent } from 'src/app/shared/components/field-level-error/field-level-error.component';
import { FieldLevelErrorGlobalMessageComponent } from 'src/app/shared/components/field-level-error-global-message/field-level-error-global-message.component';
import { MaxlengthDirective } from 'src/app/shared/directive/maxlength-directive';
import { InputErrorDirective } from 'src/app/shared/directive/input-error-directive';
import { SelectDefaultDirective } from 'src/app/shared/directive/select-default-directive';
import { ButtonDisableDirective } from './directive/button-disable-directive';
import { PostalCodeDirective } from 'src/app/shared/directive/postalcode-directive';
import { PhoneNumberDirective } from 'src/app/shared/directive/phonenumber-directive';
import { BlockCopyPasteDirective } from './directive/block-copy-paste-directive';
import { CashNumberDirective } from './directive/cash-number-directive';
import { CurrencyMaskDirective } from './directive/currency-mask-directive';
import { PipeSharedModule } from './pipe-shared.module';
import { PasswordCriteriaDirective } from './directive/password-criteria-directive';
import { userIdCriteriaDirective } from './directive/userId-criteria-directive';
import { PasswordIndicatorsComponent } from './components/password-indicators/password-indicators.component';
import { SwipeDirective } from './directive/swipe.directive';
import { SmoothHeightAnimDirective } from './directive/smooth-height-directive';

@NgModule({
  declarations: [
    FieldLevelErrorComponent,
    FieldLevelErrorGlobalMessageComponent,
    MaxlengthDirective,
    SmoothHeightAnimDirective,
    InputErrorDirective,
    SelectDefaultDirective,
    ButtonDisableDirective,
    PostalCodeDirective,
    PhoneNumberDirective,
    CashNumberDirective,
    BlockCopyPasteDirective,
    CurrencyMaskDirective,
    PasswordCriteriaDirective,
    userIdCriteriaDirective,
    PasswordIndicatorsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    PipeSharedModule
  ],
  exports: [
    FieldLevelErrorComponent,
    FieldLevelErrorGlobalMessageComponent,
    MaxlengthDirective,
    SmoothHeightAnimDirective,
    InputErrorDirective,
    SelectDefaultDirective,
    ButtonDisableDirective,
    PostalCodeDirective,
    PhoneNumberDirective,
    CashNumberDirective,
    BlockCopyPasteDirective,
    CurrencyMaskDirective,
    PasswordCriteriaDirective,
    userIdCriteriaDirective,
    PasswordIndicatorsComponent,
    SwipeDirective
  ]
})
export class FormSharedModule { }
