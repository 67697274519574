<button [id]="id + '_' + index" (click)="next()" class="transfer-card-btn">
  <div class="transfer-card-container">
    <div>
      <ng-container *ngIf="cardContent">
        <div class="transfer-card-title" [ngClass]="cardContent.content? 'paddingBottom4': ''">
          {{cardContent.title}}
        </div>
        <div *ngIf="cardContent.content" class="transfer-card-content">
          {{cardContent.content}}
        </div>
        <div *ngIf="cardContent.content2" class="transfer-card-content">
          {{cardContent.content2}}
        </div>
        <span class="sr-only">
          {{cardContent.hdnTxt}}
        </span>
      </ng-container>
      <ng-container *ngIf="account">
        <div class="transfer-card-title" [ngClass]="account.balance? 'paddingBottom4': ''">
          <span tabindex="-1">
            <ng-container *ngIf="account.AccountType == 'BNK' && account.BankAccountInfo; else displayAccType">
              {{account.BankAccountInfo.InstitutionName}}
            </ng-container>
            <ng-template #displayAccType>
              {{globalContent.accountNickname(account)}}
            </ng-template>
            <span [innerHTML]="account.AccountNumber | srOnly:true"></span>
            <span *ngIf="account.Currency"
              [innerHtml]='" ("+globalContent.getCurrencyContent(account.Currency)+")"'></span>
          </span>
        </div>
        <div *ngIf="account.balance" class="transfer-card-content">
          <span>{{account.balance}}</span>
        </div>
      </ng-container>
    </div>
    <span aria-hidden="true" class="icon-Chevron-Right"></span>
  </div>
</button>