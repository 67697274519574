<div id="carousel_resize_{{id}}" [ngClass]="isBar ? (showBar ? 'carousel_resize_bar' : 'carousel_resize_no_bar') : ''">
  <div class="wrapper" id="carousel_wrapper_{{id}}" [ngStyle]="{ 'width': currentWidth + 'px' }">
    <div class="carousel carousel_{{id}}" [ngStyle]="{ 'left': (currentLeft * -1) + 'px'}">
      <ng-content></ng-content>
    </div>
    <div *ngIf="isBar && showBar && current > 1 && displayLeftRightBtn" [ngClass]="{'learnCardIcon-background ': id === 'learnCardCarousel'}" class="carousel_hover_left_arrow" (click)="leftClicked()"
      [ngStyle]="{ 'transform': 'translate(-'+hoverArrowOutset+', -50%) rotate(180deg)'}" aria-hidden="true">
      <span [ngClass]="{'learnCardIcon ': id === 'learnCardCarousel'}" class="icon-Chevron-Right"></span>
    </div>
    <div *ngIf="isBar && showBar && current < total && displayLeftRightBtn" [ngClass]="{'learnCardIcon-background ': id === 'learnCardCarousel'}"  class="carousel_hover_right_arrow" (click)="rightClicked()"
      [ngStyle]="{ 'transform': 'translate('+hoverArrowOutset+', -50%)'}" aria-hidden="true">
      <span [ngClass]="{'learnCardIcon ': id === 'learnCardCarousel'}"class="icon-Chevron-Right"></span>
    </div>
  </div>
</div>
<div *ngIf="!isBar" class="carousal-flex-row" [ngClass]="carouselStyle">
  <div class="leftChevronContainer">
    <button type="button" attr.aria-label="{{carouselContent.leftHdnText}}" id="carousel-leftClickedButton-{{id}}"
      class="icon-Chevron-Left leftChevron leftChevron_{{this.id}}" 
      href="javascript:void(0);" (click)="leftClicked();focusBacktoCard('redirectTarget_' + this.id + '-' + this.current)">
    </button>
  </div>
  <div (keydown)="keyboardNav($event)" class="carousal-container"
    [ngClass]="(i+1) == current? 'selected': 'not-selected'" *ngFor="let j of totalcollection; let i = index">
    <button *ngIf="carouselStyle != 'grey'" class="carousel-circle-btn" href="javascript:void(0);" [id]="'carousel-circle-' + id + '-' + (i+1)"
      (focus)="selectedItem(i+1);" type="button"
      (click)="indicatorClicked(i+1);focusBacktoCard('redirectTarget_' + this.id + '-' + (i+1))"
      attr.aria-label="{{carouselContent['circleHdnText'+(i+1)]}}">
      <div class="carousal-indicator">
      </div>
    </button>
    <div *ngIf="carouselStyle == 'grey'" class="carousel-circle-btn" [id]="'carousel-circle-' + id + '-' + (i+1)" aria-hidden="true">
      <div class="carousal-indicator">
      </div>
    </div>
  </div>
  <div class="rightChevronContainer">
    <button type="button" attr.aria-label="{{carouselContent.rightHdnText}}" id="carousel-RightClickedButton-{{id}}"
      class="icon-Chevron-Right rightChevron rightChevron_{{this.id}}" href="javascript:void(0);"
      (click)="rightClicked();focusBacktoCard('redirectTarget_' + this.id + '-' + this.current)">
    </button>
  </div>
</div>

<div class="carousel-bar-container" [attr.hidden]="isBar && showBar && total > 1 ? null : true" attr.aria-hidden="true" [ngStyle]="{ 'margin-left': barMargin + 'px', 'margin-right': barMargin + 'px' }">
  <div id="carousel_bar_{{id}}" class="carousel-bar">
    <div class="carousel-bar-visual"></div>
    <div id="carousel_bar_node_{{id}}" class="carousel-bar-node" [ngStyle]="{ 'width': barWidth + 'px', 'left': barLeft + 'px' }">
      <div class="carousel-bar-node-visual"></div>
    </div>
  </div>
</div>