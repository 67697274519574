import { forwardRef, Inject, Injectable, Injector } from '@angular/core';
import { HttpService } from 'src/app/core/services/http.service';
import { CompatibilityCheckReq } from '../models/compatability-req';
import { BehaviorSubject, Observable, pipe, Subject } from 'rxjs';
import { AppStore } from '../models/app-store';
import { DOCUMENT } from '@angular/common';
import {
  isDefined,
  openNewWindow,
  openWindow
} from 'src/app/shared/services/utils.service';
import { PersistenceService } from 'src/app/core/services/persistence.service';
import { _User } from '../models/user';
import { Router } from '@angular/router';
import { Lang, Lob } from '../models/lob.enum';
import { SpinnerService } from 'src/app/core/components/spinner/service/spinner.service';
import { buildInfo } from 'src/app/config/build-info';
import { EnvService } from 'src/app/env.service';
import { HttpHeaders } from '@angular/common/http';
import { GlobalContentStore } from '../store/global-content-store';
import { Location } from '@angular/common';
import { ErrorConstant } from 'src/app/core/models/app-error';
import { take } from 'rxjs/operators';
import { GlobalHeaderService } from './../../core/components/global-header/service/global-header.service';
import { InterestPaymentFrequencyEnum } from '../models/interest-payment-frequency.enum';
import { ContentStore } from '../store/content-store';
import { GicDetails } from '../../modules/txn/fixed-income/buyGics/order/data/buy-gic-order-data';
import { GICOrderReviewInfo } from '../../modules/txn/fixed-income/data/fixed-income-data';
import { BroadcastStore } from '../../modules/txn/broadcast/data/broadcast-store';
import { SplashStore } from 'src/app/modules/txn/splash/data/splash-store';
import { createFile, writeFile, removeFile, triggerFileOpen } from 'src/app/shared/services/file-utils.service';
import { QuickTradeStocksLandingStore } from '../components/quick-trade-stocks/landing/data/quick-trade-stocks-landing-store';
import { TradeStocksLandingStore } from 'src/app/modules/txn/tradeStocks/landing/data/trade-stocks-landing-store';
import { routeConfig } from 'src/app/config/router-mapping';
import { typeOf } from 'src/app/config/type-mapper';
import { QuickTradeOptionsLandingStore } from '../components/quick-trade-options/landing/data/quick-trade-options-landing-store';
import { GtagService } from 'src/app/shared/services/gtag.service';
import { GlobalSignOffService } from 'src/app/core/services/global-signoff.service';

declare let sessionStorage_transfer: any;
declare let window: any;
declare let require: any;
declare let BrazePlugin: any;
declare let StatusBar: any;

const pretxnConfig = require('../../config/pretxnpage-config.json');
const gatewayConfig = require('src/app/config/gateway-config.json');
const braze = require("@braze/web-sdk");

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  routePermission: Subject<string>;
  isHamburgerOpen: boolean;
  globalAlert = false;
  isNotch = false;
  showInterceptModal;
  performanceCookie: boolean = false;
  advertisingCookie: boolean = false;
  oneTrustPerformanceSubject: Subject<boolean>;
  oneTrustAdvertisingSubject: Subject<boolean>;
  oneTrustFunctionality = true;
  skipConsent: boolean = true;
  deepLinks = {
    transferForm: {
      lob: 'ie',
      route: '/txn/userPreferences/transfer-forms/landing'
    },
    enableUSTrading: {
      lob: 'ie',
      route: '/txn/userPreferences/enable-US-markets-trading/landing'
    },
    updateAddress: {
      lob: 'ie',
      route: '/txn/userPreferences/contact-info-update/landing'
    },
    linkCIBCBankAcc: {
      lob: 'ie',
      route: '/txn/userPreferences/link-bank-accounts/landing'
    },
    RRSPWithdraw: {
      lob: 'ie',
      route: '/txn/userPreferences/rrsp-withdrawal/landing'
    },
    cashTransfer: {
      lob: 'ie',
      route: '/txn/cashTransfer/landing'
    },
    stockTrade: {
      lob: 'ie',
      route: '/txn/tradeStock/landing',
      extraParams: [
        "symbol",
        "market"
      ]
    }
  };


  showQuickTrade = (window as any).showQuickTrade;
  showInterceptModalFlag = (window as any).showInterceptModal;
  showMyaccountInterceptModalFlag = (window as any).showMyaccountInterceptModal;
  feedbackButtonFormId = (window as any).feedbackButtonFormId;
  showQuickTradeOption = (window as any).showQuickTradeOption;
  showTradeIdeasInterceptModalFlag = (window as any).showTradeIdeasInterceptModal;
  showCommissionFreeInterceptModalFlag = (window as any).showCommissionFreeInterceptModal;
  gtagServiceEnabled = true;
  constructor(
    private router: Router,
    private httpService: HttpService,
    private appStore: AppStore,
    private persistenceService: PersistenceService,
    private spinnerService: SpinnerService,
    public content: ContentStore,
    private globalContentStore: GlobalContentStore,
    private location: Location,
    private globalHeaderService: GlobalHeaderService,
    private gtagService: GtagService,
    private env: EnvService,
    private injector: Injector,
    // other dependencies
    @Inject(DOCUMENT) private document: Document
  ) {
    this.routePermission = new BehaviorSubject(null);
    this.oneTrustPerformanceSubject = new Subject<boolean>();
    this.oneTrustAdvertisingSubject = new Subject<boolean>();
  }

  public checkCompatability(
    compatiabilityReq: CompatibilityCheckReq
  ): Observable<any> {
    return this.httpService.post(gatewayConfig.APIServices.compatibilityCheck.url, compatiabilityReq);
  }

  public checkIntegrity(value: string): Observable<any> {
    return this.httpService.post(gatewayConfig.APIServices.integrityCheck.url, { Data: value });
  }

  /**
   * Update the language in the lang attribute of the html element.
   */
  public updateLanguage(language?: string): void {
    const lang = document.createAttribute('lang');
    if (isDefined(language)) {
      // do nothing
    } else {
      language = this.appStore.lang;
    }
    lang.value = language;
    this.document.documentElement.lang = language;
    // this.el.nativeElement.parentElement.parentElement.attributes.setNamedItem(lang);
  }

  // common call to get user ID
  getUserID() {
    return this.httpService.post(gatewayConfig.APIServices.getCurrentUserId.url, null);
  }

  /* TEST CODE : TO BE REMOVED */
  getMarketLensData() {
    const marketLensURL = gatewayConfig.APIServices.iress.url;
    return this.httpService.post(marketLensURL, {});
  }

  setTabIndex(
    id,
    previousModalName: string,
    eachOpenModalModifiedELs,
    updateCurrentModalId,
    selector,
    currentThis
  ) {
    const focusableElementQuery =
      'a[href]:not([disabled]),' +
      'button:not([disabled]),textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="password"]:not([disabled]),' +
      'input[type="radio"]:not([disabled]), input[type="number"]:not([disabled]),' +
      'input[type="checkbox"]:not([disabled]), select:not([disabled]), input[type="tel"]:not([disabled])';
    const focusableEls = this.document.querySelectorAll(focusableElementQuery);

    this.findFocusableElements(
      id,
      previousModalName,
      focusableEls,
      eachOpenModalModifiedELs,
      updateCurrentModalId,
      selector,
      currentThis
    );
  }

  // update tabIndex -1 for focusable Elements
  findFocusableElements(
    currentModalId: string,
    previousModalName,
    focusableEls,
    eachOpenModalModifiedELs,
    updateCurrentModalId,
    selector,
    currentThis
  ) {
    let index = 0;
    [].forEach.call(focusableEls, (e) => {
      index++;
      if (e.hasAttribute('tabindex')) {
      } else {
        if (this.getClosest(e, selector, previousModalName)) {
        } else {
          e.setAttribute('tabindex', '-1');
          eachOpenModalModifiedELs[currentModalId]?.focusableElements.push(e);
        }
      }
      if (index === focusableEls.length && updateCurrentModalId) {
        // updating the currentModalId which is opened
        updateCurrentModalId(currentThis, currentModalId);
      }
    });
  }

  getClosest = (elem, selector, previousModalName) => {
    const menuElements = [
      'app-hamburger-menu',
      'app-bottom-sub-nav',
      'app-predictive-search'
    ]
    for (; elem && elem !== this.document; elem = elem.parentNode) {
      if (
        elem.matches('app-modal') ||
        (menuElements.findIndex((el) => el == selector) > -1 && elem.matches(selector))
      ) {
        return !(previousModalName && elem.id === previousModalName);
      }
    }
    return null;
  }

  // removing tabIndex -1
  undoTrapFocus(currentEls: any, id?: string) {
    for (const property in currentEls) {
      if (currentEls[property] instanceof HTMLElement) {
        currentEls[property].removeAttribute('tabindex');
      }
    }
  }

  // Remove after R2
  isRoutePermissioned(currentRoute) {
    this.routePermission.next(currentRoute);
  }

  clearPostSignOnSession() {
    this.persistenceService.sessionStorePersist('isSignedOn', false);
    this.appStore.state.user.status = _User.Status.GUEST;
    this.appStore.omnitureId = undefined;
    if(this.appStore.state.user.premiumUser && this.appStore.isApp("app")) {
      this.appStore.state.user.premiumUser = false;
      StatusBar.backgroundColorByHexString("#FFF");
    }
  }

  authorize(path: string) {
    this.persistenceService.sessionStorePersist('isSignedOn', true);
    this.appStore.state.user.status = _User.Status.AUTHZ;
    this.router.navigate([path]);
  }

  handleNextPage(
    next: string,
    component,
    tempPasswordFlag?: string,
    omnitureToken?: string,
    biometricType?,
    otvcRequired?: boolean,
    isSourceDCO? : string
  ) {
    if (omnitureToken) {
      this.appStore.omnitureId = encodeURIComponent(omnitureToken);
    }
    if (this.appStore.isApp('app')) {
      if (biometricType === "FACE") {
        this.appStore.omnitureAuthType = "face-id";
      } else if (biometricType === "FINGER") {
        this.appStore.omnitureAuthType = "touch-id";
      } else {
        this.appStore.omnitureAuthType = "biometric";
      }
    } else {
      this.appStore.omnitureAuthType = "user-pass";
    }
    if (next === 'BROADCAST') {
      this.httpService.post('/v2/auth/broadcast', {}).subscribe((data) => {
        this.openInterceptModal(data);
        if (this.compareBroadcastHashes(data.Message)) {

          let isSourceDCO = this.persistenceService.sessionStoreRetrieve("isSourceDCO");
          if(isSourceDCO == 'true') {
            this.handleNextPage(data.Next,component,'','','',false,isSourceDCO);
          } 
          else {
            this.handleNextPage(data.Next,component);
          }
        } else {
          BroadcastStore.inFlow = data;
          this.appStore.preTxnPagePermission = pretxnConfig.permission.BRDCST;
          this.authorize('/broadcast');
        }
      }, (err) => {
        throw err;
      });
    } else if (next === 'OTVC_SETUP') {
      this.authorize('/signOn/otvc/otvcsetup');
    } else if (next === 'OTVC_PROMPT') {
      if (isDefined(component.otvcPrompt)) {
        component.otvcPrompt();
      }
    } else if (next === 'CAMPAIGN') {
      this.httpService.post('/v2/campaign/init', {}).subscribe((data) => {
        SplashStore.inFlow = data;
        this.appStore.preTxnPagePermission = pretxnConfig.permission.SPLASH;
        this.authorize('/splash');
      })
    } else if (next === 'MYACCOUNTS') {
      this.deepLinkAuthorized();
    } else if (next === 'TOUCHID_SETUP') {
      // this.authorize('/txn/userPreferences/touchid/landing');
      this.persistenceService.sessionStorePersist('isSignedOn', true);
      this.appStore.state.user.status = _User.Status.AUTHZ;
      this.callNonAPI('txn/userPreferences/touchid/landing');
    } else if (next === 'FORGOTPWD') {
      this.router.navigate(['/signOn/forgotSignOnPassword']);
    } else if (next === 'SIGNON') {
      if (this.appStore.isApp("inapp")) {
        window.location.href = "cibcbanking://relaybacksso";
      } else {
        this.router.navigate(['/signon']);
      }
    } else if (next === 'TEMP_PASSWORD') {
      if (otvcRequired) {
        if (isDefined(component.otvcPrompt)) {
          component.otvcPrompt("tempPassword" + tempPasswordFlag);
        }
      } else if (tempPasswordFlag === 'B') {
        this.appStore.preTxnPagePermission = pretxnConfig.permission.CHGSNT;
        this.authorize('/signon/createSignonAndTradingPass');
      } else if (tempPasswordFlag === 'S') {
        this.appStore.preTxnPagePermission = pretxnConfig.permission.CHGSGN;
        this.authorize('/signon/createSignonPass');
      } else if (tempPasswordFlag === 'T') {
        this.appStore.preTxnPagePermission = pretxnConfig.permission.CHGTRD;
        this.authorize('/signon/createTradingPass');
      }
    } else if (next === 'EXTERNAL_AGREEMENT') {
      console.log('pradeep EAA');
      // alert('EXTERNAL_AGREEMENT TBD');
      this.authorize('/electronicAccessAgreement');
    } else if (next === 'REGISTER') {
      this.callNonAPI('registerOnline/landing');
    } else if (next === 'wgOnlineRegister') {
      this.router.navigate(['/onlineRegistration/password']);
    }
    else {
      console.log('Scenario Not Handled');
    }
  }

  // SSOToAzure(SsoLinkIDMnum: string, biometricType: string) {
  //   let host = 'https://m2.onlinebrokerage.cibc.com/#/ssoazure/signon';
  //   window.location.href = `${host}?Locale=${this.appStore.lang}&Lob=${this.appStore.lob.toUpperCase()}&SsoLinkIDMnum=${SsoLinkIDMnum}&biometricType=${biometricType}`;
  // }

  deepLinkAuthorized() {
    let deepLink = this.persistenceService.sessionStoreRetrieve("deepLink");
    let deepLinkExtras = this.persistenceService.sessionStoreRetrieve("deepLinkExtras");
    if (deepLink && deepLink.startsWith('/') && this.appStore.lob == this.persistenceService.sessionStoreRetrieve("deepLinkLOB")) {
      this.persistenceService.sessionStorePersist("deepLinkRedirect", true);
      this.setCrossFlow(deepLink, deepLinkExtras);
      this.authorize(deepLink);
    } else if (deepLink == 'stockTrade') {
      if (!deepLinkExtras || deepLinkExtras == "{}") {
        if (this.isDesktop()) {
          this.persistenceService.sessionStorePersist("deepLinkQuickTrade", true);
          this.authorize('/txn/accounts/myaccounts');
        } else {
          this.persistenceService.sessionStorePersist("deepLinkRedirect", true);
          this.authorize(this.deepLinks[deepLink].route);
        }
      } else {
        deepLinkExtras = JSON.parse(deepLinkExtras);
        if (this.isDesktop()) {
          QuickTradeStocksLandingStore.crossFlow = deepLinkExtras;
          this.persistenceService.sessionStorePersist("deepLinkQuickTrade", true);
          this.authorize('/txn/accounts/myaccounts');
        } else {
          TradeStocksLandingStore.crossFlow = deepLinkExtras;
          this.persistenceService.sessionStorePersist("deepLinkRedirect", true);
          this.authorize(this.deepLinks[deepLink].route);
        }
      }
    } else if (this.deepLinks[deepLink] && this.appStore.lob == this.deepLinks[deepLink].lob) {
      this.persistenceService.sessionStorePersist("deepLinkRedirect", true);
      this.authorize(this.deepLinks[deepLink].route);
    } else if (deepLink && deepLink.startsWith('/') && ((this.persistenceService.sessionStoreRetrieve("deepLinkLOB") === undefined) || this.persistenceService.sessionStoreRetrieve("deepLinkLOB") == null)) {
      this.persistenceService.sessionStorePersist("deepLinkRedirect", true);
      this.setCrossFlow(deepLink, deepLinkExtras);
      if(deepLink.includes('dco')) {
        this.goToDCOApplication().subscribe((data: any) => {
           let domain = "https://";
            if (window.location.host == 'm2.onlinebrokerage.cibc.com') {
              domain += 'm.onlinebrokerage.cibc.com';
            } else {
             domain += window.location.host;
              //enable for local testing depending on the environment u are connected to change the domain to either sit, dit
              // domain = 'https://m.uat1.onlinebrokerage.cibc.com';
            }
            const target = this.appStore.isApp() ? "_system" : "_self";
            const token = data?.DcoAcctOpenJwtToken;
            const lang = this.appStore.lang;
            let url = domain + deepLink + '#' + lang;
            if (token) {
              url = url + "/" + encodeURIComponent(token);
            }
            window.open(url, target);
            const globalSignOffService = this.injector.get(GlobalSignOffService);
            if (this.appStore.isApp('app')) {
              globalSignOffService.obr2DCOWrapperSignoff();
            } else if (this.appStore.isApp('inapp')) {
             globalSignOffService.signOff();
            } 
        });
      }
      else{
      this.authorize(deepLink);
      }
    } else {
      let isSourceDCO = this.persistenceService.sessionStoreRetrieve("isSourceDCO");
      if(isSourceDCO == 'true') {
        this.goToDCOApplication().subscribe((data: any) => {
          let domain = "https://";
           if (window.location.host == 'm2.onlinebrokerage.cibc.com') {
             domain += 'm.onlinebrokerage.cibc.com';
           } else {
            domain += window.location.host;
             //enable for local testing depending on the environment u are connected to change the domain to either sit, dit
             //domain = 'https://m.sit4.onlinebrokerage.cibc.com';
           }
           const target = this.appStore.isApp() ? "_system" : "_self";
           const token = data?.DcoAcctOpenJwtToken;
           const lang = this.appStore.lang;
           let url = domain + "/dco/html/registerOnline.html#"  + lang;
           if (token) {
             url = url + "/" + encodeURIComponent(token);
           }
           window.open(url, target);
           const globalSignOffService = this.injector.get(GlobalSignOffService);
           if (this.appStore.isApp('app')) {
             globalSignOffService.obr2DCOWrapperSignoff();
           } else if (this.appStore.isApp('inapp')) {
            globalSignOffService.signOff();
           } 
          });
      } else {
      this.authorize('/txn/accounts/myaccounts');
    }
    }
    this.persistenceService.removeSessionStoreValue("deepLink");
    this.persistenceService.removeSessionStoreValue("deepLinkExtras");
    this.persistenceService.removeSessionStoreValue("deepLinkLOB");
  }

  deepLink() {
    let deepLink = this.persistenceService.sessionStoreRetrieve("deepLink");
    let deepLinkExtras = this.persistenceService.sessionStoreRetrieve("deepLinkExtras");
    if (deepLink && deepLink.startsWith('/') && this.appStore.lob == this.persistenceService.sessionStoreRetrieve("deepLinkLOB")) {
      this.persistenceService.sessionStorePersist("deepLinkRedirect", true);
      this.setCrossFlow(deepLink, deepLinkExtras);
      this.router.navigate([deepLink]);
    } else if (deepLink == 'stockTrade') {
      if (!deepLinkExtras || deepLinkExtras == "{}") {
        if (this.isDesktop()) {
          this.persistenceService.sessionStorePersist("deepLinkQuickTrade", true);
          this.authorize('/txn/accounts/myaccounts');
        } else {
          this.persistenceService.sessionStorePersist("deepLinkRedirect", true);
          this.authorize(this.deepLinks[deepLink].route);
        }
      } else {
        deepLinkExtras = JSON.parse(deepLinkExtras);
        if (this.isDesktop()) {
          QuickTradeStocksLandingStore.crossFlow = deepLinkExtras;
          this.persistenceService.sessionStorePersist("deepLinkQuickTrade", true);
          this.authorize('/txn/accounts/myaccounts');
        } else {
          TradeStocksLandingStore.crossFlow = deepLinkExtras;
          this.persistenceService.sessionStorePersist("deepLinkRedirect", true);
          this.authorize(this.deepLinks[deepLink].route);
        }
      }
    } else if (this.deepLinks[deepLink] && this.appStore.lob == this.deepLinks[deepLink].lob) {
      this.persistenceService.sessionStorePersist("deepLinkRedirect", true);
      this.router.navigate([this.deepLinks[deepLink].route]);
    } else if (deepLink && deepLink.startsWith('/') && ((this.persistenceService.sessionStoreRetrieve("deepLinkLOB") === undefined) || this.persistenceService.sessionStoreRetrieve("deepLinkLOB") == null)) {
      this.persistenceService.sessionStorePersist("deepLinkRedirect", true);
      this.setCrossFlow(deepLink, deepLinkExtras);
      if(deepLink.includes('dco')) {
        this.goToDCOApplication().subscribe((data: any) => {
           let domain = "https://";
            if (window.location.host == 'm2.onlinebrokerage.cibc.com') {
              domain += 'm.onlinebrokerage.cibc.com';
            } else {
              domain += window.location.host;
                //enable for local testing depending on the environment u are connected to change the domain to either sit, dit
             // domain += 'm.uat1.onlinebrokerage.cibc.com';
            }
            const target = this.appStore.isApp() ? "_system" : "_self";
            const token = data?.DcoAcctOpenJwtToken;
            const lang = this.appStore.lang;
            let url = domain + deepLink + '#' + lang;
            if (token) {
              url = url + "/" + encodeURIComponent(token);
            }
            window.open(url, target);
            const globalSignOffService = this.injector.get(GlobalSignOffService);
            if (this.appStore.isApp('app')) {
              globalSignOffService.obr2DCOWrapperSignoff();
            } else if (this.appStore.isApp('inapp')) {
             globalSignOffService.signOff();
            } 
        });
      }
      else{
        this.router.navigate([deepLink]);
      }
    } else {
      let isSourceDCO = this.persistenceService.sessionStoreRetrieve("isSourceDCO");
      if(isSourceDCO == 'true'){
        this.goToDCOApplication().subscribe((data: any) => {
          let domain = "https://";
           if (window.location.host == 'm2.onlinebrokerage.cibc.com') {
             domain += 'm.onlinebrokerage.cibc.com';
           } else {
            domain += window.location.host;
            //enable for local testing depending on the environment u are connected to change the domain to either sit, dit
            //domain += 'm.sit4.onlinebrokerage.cibc.com';
           }
           const target = this.appStore.isApp() ? "_system" : "_self";
           const token = data?.DcoAcctOpenJwtToken;
           const lang = this.appStore.lang;
           let url = domain + "/dco/html/registerOnline.html#" + lang;
           if (token) {
             url = url + "/" + encodeURIComponent(token);
           }
           window.open(url, target);
           this.persistenceService.removeSessionStoreValue("isSourceDCO");
           this.persistenceService.removeSessionStoreValue('queryParamDco');
           if(this.persistenceService.sessionStoreRetrieve('queryParamLocale')){
              this.persistenceService.removeSessionStoreValue('queryParamLocale');
           }
           const globalSignOffService = this.injector.get(GlobalSignOffService);
           if (this.appStore.isApp('app')) {
             globalSignOffService.obr2DCOWrapperSignoff();
           } else if (this.appStore.isApp('inapp')) {
            globalSignOffService.signOff();
           } 
       });
      } else {
      this.router.navigate(['/txn/accounts/myaccounts']);
      }
    }
    this.persistenceService.removeSessionStoreValue("deepLink");
    this.persistenceService.removeSessionStoreValue("deepLinkExtras");
    this.persistenceService.removeSessionStoreValue("deepLinkLOB");
  }

  setCrossFlow(url, extras) {
    let route = routeConfig(url);
    if (route && extras && extras != "{}") {
      typeOf(route.store).crossFlow = JSON.parse(extras);
    }
  }

  getDeepLinks() {
    return this.deepLinks;
  }

  checkPermissionForRoutes(routeEnum) {
    const currentLOB = this.appStore.lob === 'cfpi' ? 'iis' : this.appStore.lob;
    const serverRespPermission = this.appStore.retrieveSessionInfo?.LobLinksMap
      ? this.appStore.retrieveSessionInfo.LobLinksMap[currentLOB.toUpperCase()]
      : '';
    return serverRespPermission.indexOf(routeEnum) !== -1;
  }

  /**
   * Method to get LOB from the URL based on the partIndex.
   * return valid lob if part of the enum, else return undefined
   * If the URL pattern is '/home/en/ie' then the partIndex for LOB is 3
   */
  getLOBFromUrl(url: string, partIndex: number) {
    const lobFromUrl = this.getUrlPart(url, partIndex);
    return lobFromUrl ? Lob[lobFromUrl.toLocaleUpperCase()] : undefined;
  }

  /**
   * Method to get lang from the URL based on the partIndex.
   * return valid lang if part of the enum, else return undefined
   * If the URL pattern is '/home/en/ie' then the partIndex for lang is 2
   */
  getLangFromUrl(url: string, partIndex: number) {
    const langFromUrl = this.getUrlPart(url, partIndex);
    return langFromUrl ? Lang[langFromUrl.toLocaleUpperCase()] : undefined;
  }

  private getUrlPart(url: string, partIndex: number) {
    let urlPart;
    const urlsParts = url.split('/');
    if (null != urlsParts && urlsParts.length > 0) {
      urlPart = urlsParts[partIndex];
    }
    return urlPart;
  }

  isNotchPhone() {
    return this.appStore.isNotch;
  }

  isUserSignedOn() {
    return (
      this.retrieveSessionStorageIsSignedOnFlag() && this.appStore.isLoggedIn()
    );
  }

  retrieveSessionStorageIsSignedOnFlag() {
    let isSignedOn = false;
    const sessionStorageIsSignedOnFlag = this.persistenceService.sessionStoreRetrieve(
      'isSignedOn'
    );
    if (
      null !== sessionStorageIsSignedOnFlag &&
      'true' === sessionStorageIsSignedOnFlag
    ) {
      isSignedOn = true;
    }
    return isSignedOn;
  }

  getFixedIncomePaymentFrequency(InterestPaymentFrequency: string) {
    switch (InterestPaymentFrequency) {
      case InterestPaymentFrequencyEnum.ANNUALLY:
        return this.content.text.annual;
      case InterestPaymentFrequencyEnum.SEMI_ANNUALLY:
        return this.content.text.semiAnnual;
      case InterestPaymentFrequencyEnum.QUARTERLY:
        return this.content.text.quarterly;
      case InterestPaymentFrequencyEnum.MONTHLY:
        return this.content.text.monthly;
      case InterestPaymentFrequencyEnum.DAILY:
        return this.content.text.daily;
      case InterestPaymentFrequencyEnum.COMPOUND:
        return this.content.text.compound;
      case InterestPaymentFrequencyEnum.AT_MATURITY:
        return this.content.text.atMaturity;
      default:
        return '-;';
    }
  }


  generateInterestRateYears(gicDetails: GicDetails | GICOrderReviewInfo) {
    const arr = [];
    for (let i = 1; i <= gicDetails.Term; i++) {
      if (gicDetails['InterestRateForYear' + i]) {
        arr.push(gicDetails['InterestRateForYear' + i]);
      }
    }
    return arr;
  }

  getUserLOB(isApp?, ssoCustType?: any, regOnline?: boolean): string {
    if (regOnline && this.getAEMOBRLob()) {
      return this.getAEMOBRLob();
    } else if (this.getMOBILob()) {
      return this.getMOBILob();
    } else if (isDefined(ssoCustType)) {
      switch (ssoCustType) {
        case 'R':
          return Lob.GEN;
        case 'I':
          return Lob.IIS;
        case 'W':
          return Lob.WG;
        case 'WG':
          return Lob.WG;
        case 'P':
          return Lob.CPIC;
        default:
          return Lob.IE;
      }
    } else if (this.getOBRLob()) {
      return this.getOBRLob();
    } else {
      if (isApp || this.appStore.isApp('app')) {
        return Lob.GEN;
      } else {
        return Lob.IE;
      }
    }
  }

  getUserLANG(regOnline?: boolean): string {
    if (!regOnline && this.getMOBILang()) {
      return this.getMOBILang();
    } else if (this.getOBRLang()) {
      return this.getOBRLang();
    } else {
      return Lang.EN;
    }
  }

  getUserLANGfromCache(): string {
    if (this.getOBRLang()) {
      return this.getOBRLang();
    } else if (this.getMOBILang()) {
      return this.getMOBILang();
    } else {
      return Lang.EN;
    }
  }

  goToMarketLens() {
    this.getMarketLensData().subscribe(
      (data: any) => {
        if (data) {
          if (!this.appStore.isApp()) {
            this.openWindowWithPost(
              data.Url ?? this.env.marketLenURL,
              data.SessionToken,
              '_blank'
            );
          }
        }
      },
      (err) => {
        if (err.error.Exception.ErrorCode === ErrorConstant.QUOTEEAAERROR) {
          this.router.navigate([
            '/txn/userPreferences/exchangeAgreements/landing',
          ]);
        } else {
          this.appStore.showErrorInPopup = true;
          throw err;
        }
      }
    );
  }

  goToMarketlensWithoutPassword() {
    this.httpService
      .post(gatewayConfig.APIServices.verifyTradingPassword.url, {})
      .subscribe((res) => {
        if (res) {
          if (!this.appStore.isApp()) {
            this.openWindowWithPost(
              res.Url,
              res.SessionToken,
              '_blank'
            );
          }
        }
      },
        (err) => {
          if (err.error.Exception.ErrorCode === ErrorConstant.QUOTEEAAERROR) {
            this.router.navigate([
              '/txn/userPreferences/exchangeAgreements/landing',
            ]);
          } else {
            this.appStore.showErrorInPopup = true;
            throw err;
          }
        }
      );
  }

  openWindowWithPost(action, token, target) {
    const f = document.createElement('form');
    f.setAttribute('name', 'iressForm');
    f.setAttribute('method', 'post');
    f.setAttribute('action', action);
    f.setAttribute('target', target);

    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'st';
    input.value = token;
    f.appendChild(input);
    document.body.appendChild(f);

    // window.open('', 'iressWindow');
    f.submit();
    f.parentNode.removeChild(f);
  }

  openWindowWithBlob(url: string, fileName: string): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.appStore.isApp('app')) {
        let mimeType = 'application/pdf';
        this.httpService.getBlob(url).subscribe((data: Blob) => {
          let fileData = new Blob([data], { type: mimeType });

          createFile(fileName, (fullPath) => {
            writeFile(fileName, fileData, () => {
              triggerFileOpen(fullPath, mimeType, () => {
                resolve(true);
              }, (error) => {
                resolve(false);
              });
            }, (error) => {
              resolve(false);
            });
          }, (error) => {
            resolve(false);
          });
        }, (err) => {
          resolve(false);
        });
      } else {
        openWindow(url, this.appStore);
        resolve(true);
      }
    });
  }

  private getMOBILang(): string {
    return this.persistenceService.cookieStoreRetrieve('MBI_LANG');
  }

  private getDCOLang(): string {
    return this.persistenceService.cookieStoreRetrieve('DCO_LOCALE');
  }

  private getOBRLang(): string {
    return this.persistenceService
      .cookieStoreRetrieve('OBR_LOCALE')
      ?.split('_')[0];
  }

  private getMOBILob(): string {
    return this.persistenceService.cookieStoreRetrieve('MBI_LOB');
  }

  private getOBRLob(): string {
    return this.persistenceService
      .cookieStoreRetrieve('OBR_SIGNOFF_CUSTOMER_TYPE')
      ?.split('-')[0];
  }

  private getAEMOBRLob(): string {
    return this.persistenceService
      .cookieStoreRetrieve('obr_cTyp')
      ?.split('-')[0];
  }

  openHelpWindow() {
    if ((window as any).TrackingEnabled && this.appStore.omnitureId) {
      window.sessionStorage.setItem('omnitureId', this.appStore.omnitureId);
    }
    const helpObj = {
      url: window.location.href.replace(this.router.url, '') + '/txn/help',
      height: window.innerHeight + 'px',
      width: window.innerWidth + 'px',
    };
    if (window.innerHeight >= 600) {
      helpObj.height = 600 + 'px';
    }
    if (window.innerWidth >= 750) {
      helpObj.width = 750 + 'px';
    }
    openNewWindow(helpObj);
  }

  callNonAPI(url) {
    this.spinnerService.onStarted();
    this.router.navigate([url]);
    setTimeout(() => {
      this.spinnerService.onFinished();
    }, 500);
  }

  callNonAPIBack() {
    this.spinnerService.onStarted();
    this.location.back();
    setTimeout(() => {
      this.spinnerService.onFinished();
    }, 500);
  }

  getCacheBustingVersion() {
    let fileVersion = new Date().getTime();
    if (
      null != buildInfo &&
      null != buildInfo.id &&
      buildInfo.id.trim().length > 0 &&
      !isNaN(Number(buildInfo.id))
    ) {
      // Cache busting strategy - Use build id which is build timestamp (set by jenkins), so that the file is cached once per build.
      // If build id is not available or not valid (in case of local dev env it will be default '<BUILD_ID>')
      // as a fallback use current timestamp
      fileVersion = Number(buildInfo.id);
    }
    return fileVersion;
  }

  loadScript(url: string) {
    const body = document.body as HTMLDivElement;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  loadScriptOnTop(url: string) {
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);
  }

  cleanWebOnlyCode() {
    if (window.addEventListener) {
      window.removeEventListener('storage', sessionStorage_transfer, false);
    } else {
      (window as any).detachEvent('onstorage', sessionStorage_transfer);
    }
  }

  setKeyboardShrinkView(bool) {
    if (this.appStore.isApp('app')) {
      if (!bool) {
        if (this.appStore.platform === _User.Platform.IOS) {
          this.appStore.features.keyboard.shrinkView(false);
        } else if (this.appStore.platform === _User.Platform.ANDROID) {
          this.appStore.features.keyboard.disableShrink();
        }
      } else {
        if (this.appStore.platform === _User.Platform.IOS) {
          this.appStore.features.keyboard.shrinkView(true);
        } else if (this.appStore.platform === _User.Platform.ANDROID) {
          this.appStore.features.keyboard.enableShrink();
        }
      }
    }
  }

  reloadURL(hash, appHash) {
    let url = window.location.href;
    if (this.appStore.isApp('app')) {
      if (isDefined(appHash)) {
        url = url.replace(window.location.hash, '');
        url += appHash;
      }
      url +=
        '?platform=' +
        this.appStore.platform +
        '&version=' +
        this.appStore.version +
        '&revision=' +
        this.appStore.revision +
        '&build=' +
        this.appStore.build;
    } else if (this.appStore.isApp('inapp')) {
      if (isDefined(appHash)) {
        url = url.replace(window.location.hash, '');
        url += appHash;
      }
      url += '?InAppBrowser=true'
    } else {
      if (isDefined(hash)) {
        url = url.replace(window.location.hash, '');
        url += hash;
      }
    }
    window.location.replace(url);
    window.location.reload();
  }

  aemRedirect(type: string) {
    let contactUrl: string;
    if (type.toLowerCase() === 'home') {
      contactUrl = this.globalContentStore.list.aemList.linkHref1;
    } else if (
      type.toLowerCase() === 'contact' &&
      this.appStore.lob !== Lob.CPIC
    ) {
      // No contactus url for cpic as of now
      contactUrl = this.globalContentStore.list.aemList.linkHref2;
    }
    window.open(contactUrl, '_self');
  }

  checkEdocNotification() {
      this.globalHeaderService
        .getmasterNotificationURL()
        .pipe(take(1))
        .subscribe(
          (data: any) => {
            if (
              data &&
              data.NotificationMasthead &&
              data.NotificationMasthead === '1'
            ) {
              this.appStore.setGlobalIndicator('edocs', true);
            } else {
              this.appStore.setGlobalIndicator('edocs', false);
            }
          },
          (err) => {
            this.appStore.setGlobalIndicator('edocs', false);
            console.log('Error');
            console.log(err);
          }
        );
  }

  viewport() {
    var e = window, a = 'inner';
    if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
    }
    return { width: e[a + 'Width'], height: e[a + 'Height'] };
  }

  isDesktop() {
    return this.viewport().width > 899 && !this.appStore.isApp();
  }

  netAmountCalculator(cashBalance, canadianResidency: boolean, province?: string) {

    if (canadianResidency) {
      if (province === 'QC' || province === this.globalContentStore.list.province['QC']) {
        if (cashBalance <= 5000) {
          return cashBalance * 0.8;
        } else if (cashBalance > 5000 && cashBalance <= 15000) {
          return cashBalance * 0.75;
        } else if (cashBalance > 15000) {
          return cashBalance * 0.7;
        }
      } else {
        if (cashBalance <= 5000) {
          return cashBalance * 0.9;
        } else if (cashBalance > 5000 && cashBalance <= 15000) {
          return cashBalance * 0.8;
        } else if (cashBalance > 15000) {
          return cashBalance * 0.7;
        }
      }
    } else {
      return cashBalance * 0.75;
    }
  }

  goToDCOApplication() {
    return this.httpService.post("/v2/dcoAccountOpen/createJWT", {});
  }

  compareBroadcastHashes(message: any) {
    let num = parseInt(this.persistenceService.localStoreRetrieve("broadcastHash" + this.appStore.lob.toUpperCase() + "Amount"));

    for (let i = 0; i < message.length; i++) {
      let hash = this.hashString(message[i].Subject + ":" + message[i].Body);
      let exists = false;
      for (let j = 0; j < num; j++) {
        if (this.persistenceService.localStoreRetrieve("broadcastHash" + this.appStore.lob.toUpperCase() + j) == hash) {
          exists = true;
        }
      }
      if (!exists) {
        return false;
      }
    }

    return true;
  }

  storeBroadcastHashes(message: any) {
    this.persistenceService.localStorePersist("broadcastHash" + this.appStore.lob.toUpperCase() + "Amount", message.length.toString());
    for (let i = 0; i < message.length; i++) {
      let str = message[i].Subject + ":" + message[i].Body;
      this.persistenceService.localStorePersist("broadcastHash" + this.appStore.lob.toUpperCase() + i, this.hashString(str));
    }
  }

  hashString(str: string): string {
    let hash = 0;

    if (str.length == 0) return "0";
    for (let i = 0; i < str.length; i++) {
      let ch = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + ch;
      hash = hash & hash;
    }
    return hash.toString();
  }

  clearTradeDrawerSession() {
    this.persistenceService.removeSessionStoreValue("landingStoreQTBig");
    this.persistenceService.removeSessionStoreValue("landingStoreQTSmall");
    this.persistenceService.removeSessionStoreValue("tradeDrawerPosition");
    this.persistenceService.removeSessionStoreValue("openOrCloseDrawer");
    this.persistenceService.removeSessionStoreValue('verifyStoreQTBig');
    this.persistenceService.removeSessionStoreValue('verifyStoreQTSmall');


    // clearing verify values
    QuickTradeStocksLandingStore.crossFlow = undefined;
    QuickTradeStocksLandingStore.savedInfo = undefined;
    QuickTradeStocksLandingStore.inFlow = undefined;
  }

  clearQuickTradeOptionsSession() {
    this.persistenceService.removeSessionStoreValue("quickOptionsLandingStore");
    this.persistenceService.removeSessionStoreValue("quickOptionsPosition");
    this.persistenceService.removeSessionStoreValue("quickOptionsOpenOrCloseDrawer");
    this.persistenceService.removeSessionStoreValue('quickOptionsVerifyStore');

    QuickTradeOptionsLandingStore.crossFlow = undefined;
    QuickTradeOptionsLandingStore.savedInfo = undefined;
    QuickTradeOptionsLandingStore.inFlow = undefined;
  }

  storeInterceptModalHashes(message: any) {
    this.persistenceService.localStorePersist("interceptModal" + this.appStore.lob.toUpperCase() + "Amount", message.length.toString());
    for (let i = 0; i < message.length; i++) {
      let str = message[i].Subject + ":" + message[i].Body;
      this.persistenceService.localStorePersist("interceptModal" + this.appStore.lob.toUpperCase() + i, this.hashString(str));
    }
  }

  compareInterceptModalHashes(message: any) {
    let num = parseInt(this.persistenceService.localStoreRetrieve("interceptModal" + this.appStore.lob.toUpperCase() + "Amount"));

    for (let i = 0; i < message.length; i++) {
      let hash = this.hashString(message[i].Subject + ":" + message[i].Body);
      let exists = false;
      for (let j = 0; j < num; j++) {
        if (this.persistenceService.localStoreRetrieve("interceptModal" + this.appStore.lob.toUpperCase() + j) == hash) {
          exists = true;
        }
      }
      if (!exists) {
        return false;
      }
    }

    return true;
  }


  openInterceptModal(data) {
    if (this.compareInterceptModalHashes(data.Message)) {
      this.showInterceptModal = false;
    }
    else {
      //getLob() == LOB.IE && isPermission('TRSTK') && showQuickTrade 
      if (this.showQuickTrade && this.showInterceptModalFlag) {
        this.showInterceptModal = true;
        this.storeInterceptModalHashes(data.Message);
      }
    }

  }

  getLob() { return this.appStore.lob }

  getFxData() {
    return this.httpService.post(gatewayConfig.APIServices.cashtransferGetFx.url, {}, { params: { skiploading: 'true' } });
  }

  invokeASGAjaxCall1(tokenUrl: string, clientToken: string) {
    console.log("service invokeASGAjaxCall1");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Basic " + clientToken
      }),
      params: { skiploading: 'true' }
    };
    let data = '{"grant_type":"client_credentials"}';
    return this.httpService.postToExternalURL(tokenUrl, data, httpOptions);
  }

  invokeASGAjaxCall2(dataUrl: string, accessToken: string, clientData: string) {
    console.log("service invokeASGAjaxCall2");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + accessToken
      }),
      params: { skiploading: 'true' }
    };
    let data = '{"data":"' + clientData + '"}';
    return this.httpService.postToExternalURL(dataUrl, data, httpOptions);
  }

  getBrazeData() {
    return this.httpService.post(gatewayConfig.APIServices.brazeMetaData.url, {}, { params: { skiploading: 'true' } })
  }

  brazeFlush() {
    if (this.appStore.brazeUserSession) {
      if(!this.appStore.isApp("app")) {
        braze.requestImmediateDataFlush();
      } else {
        BrazePlugin.requestImmediateDataFlush();
      }
      let delays = window.braze_refresh_delays;
      if (delays) {
        let delaysArray = delays.split(",");
        this.brazeRefreshLoop(delaysArray, 0);
      }
    }
  }

  brazeRefreshLoop(delays, i) {
    if (i < delays.length) {
      setTimeout(() => {
        this.refreshBrazeContentCards();
        this.brazeRefreshLoop(delays, i + 1);
      }, parseInt(delays[i]));
    }
  }

  startBrazeSession(newSession = false) {
    const obs = new Observable<any[]>((observable) => {
      if (!this.appStore.brazeUserSession && this.isUserSignedOn()) {
        // If a braze session has yet to be started in the current iteration of the app 
        // (refreshing the web page would start a new iteration for example) and
        // the user is past the sign on page then try to get the External Id
        this.appStore.brazeUserSession = true;
        this.getBrazeData().subscribe((res) => {
          if (this.appStore.brazeUserSession) {
            if (res) {
              // If they have and External Id, then change user and start a session
              if(!this.appStore.isApp("app")){
                braze.changeUser(res.ProfileAttributes.ExternalId);
                this.brazeSetLanguage();
                braze.openSession();
              } else {
                BrazePlugin.changeUser(res.ProfileAttributes.ExternalId);
                this.brazeSetLanguage();
              }
              setTimeout(() => {
                this.brazeFlush();
              }, 1000);
            }
            observable.next(res);
          } else {
            observable.next();
          }
        }, (err) => {
          if (this.appStore.brazeUserSession) {
            // If they are anonymous then wipedata and cookie their session if it's a newSession 
            // (there was no refresh and they are landing on a post signon page for the first time in their session)
            if (newSession) {
              this.persistenceService.cookieStorePersist("AnonymousBrazeLogIn", "true");
              if(!this.appStore.isApp("app")){
                braze.wipeData();
              } else {
                if(this.appStore.platform.toLowerCase() != 'ios' && BrazePlugin) {
                  BrazePlugin.wipeData();
                }
              }
            }
            this.brazeSetLanguage();
            // open an anonymous session
            if(!this.appStore.isApp("app")){
              braze.openSession();
            } else {
              // Do nothing ??
            }
            setTimeout(() => {
              this.brazeFlush();
            }, 1000);
            observable.next(err);
          } else {
            observable.next();
          }
        });
      } else if (!this.isUserSignedOn()) {
        if (this.appStore.brazeUserSession || this.persistenceService.cookieStoreRetrieve("AnonymousBrazeLogIn") == 'true') {
          // if the user is not past the signon page but they have an active braze session or 
          // our cookies say that an anonymous user was previously logged in, then wipe data
          this.appStore.brazeUserSession = false;
          if (this.persistenceService.cookieStoreRetrieve("AnonymousBrazeLogIn") == 'true') {
            if(!this.appStore.isApp("app")){
              braze.wipeData();
            } else {
              if (this.appStore.platform.toLowerCase() != 'ios' && BrazePlugin) {
                BrazePlugin.wipeData();
              }
            }
          }
          this.persistenceService.deletecookiespecificStorePersist("AnonymousBrazeLogIn");
        }
        observable.next();
      } else {
        observable.next();
      }
    });
    return obs;
  }

  brazeSetLanguage() {
    if(!this.appStore.isApp("app")){
      let u = braze.getUser();
      if (isDefined(u)) {
        u.setLanguage(this.appStore.lang);
      }
    } else {
      BrazePlugin.setLanguage(this.appStore.lang);
    }
  }
  
  brazeCustomEvents() {
    if (this.appStore.brazeUserSession && this.appStore.lob == Lob.IE) {
      let data = routeConfig(this.router.url);
      if (data && data.brazeID) {
        if(!this.appStore.isApp("app")){
          braze.logCustomEvent(data.brazeID, { "lang": this.appStore.lang});
        } else {
          BrazePlugin.logCustomEvent(data.brazeID, { "lang": this.appStore.lang});
        }
      }
    }
  }

  logBraze(event: string, options?: any) {
    if (this.appStore.brazeUserSession && this.appStore.lob == Lob.IE) {
      if (options) {
        options.lang = this.appStore.lang;
        if(!this.appStore.isApp("app")){
          braze.logCustomEvent(event, this.unfoldJSON(options));
        } else {
          BrazePlugin.logCustomEvent(event, this.unfoldJSON(options));
        }
      } else {
        if(!this.appStore.isApp("app")){
          braze.logCustomEvent(event, { "lang": this.appStore.lang});
        } else {
          BrazePlugin.logCustomEvent(event, { "lang": this.appStore.lang});
        }
      }
    }
  }

  refreshBrazeContentCards() {
    if(!this.appStore.isApp("app")){
      braze.requestContentCardsRefresh();
    } else {
      BrazePlugin.requestContentCardsRefresh();
    }
  }

  subscribeForBrazeContentCards(subscriber: (cards: any) => void) {
    if(!this.appStore.isApp("app")){
      braze.subscribeToContentCardsUpdates(subscriber);
    } else {
      BrazePlugin.getContentCardsFromServer(subscriber);
    }
  }

  getCachedBrazeContentCards(sort:boolean, filter?: string) {
    return new Promise((resolve) => {
      let cards;
      if(!this.appStore.isApp('app')) {
        if((window as any).isEnvPTE === true){
          cards = require('../../../assets/sampleJson/content_cards.json');
        } else {
          cards = braze.getCachedContentCards().cards;
        }
        resolve(this.processCards(cards, sort, filter));
      } else {
        BrazePlugin.getContentCardsFromCache((cardsForApp)=>{
          cards = this.transformCards(cardsForApp);
          resolve(this.processCards(cards, sort, filter));
        }, (error)=>{
          console.log(error);
          resolve([]);
        });
      }
    });
  }

  getBigCards(cards) {
    return cards.filter((card) => {
      if (isDefined(card.extras.breakpoint)) {
        return card.extras.breakpoint == 'big';
      } else {
        return true;
      }
    });
  }

  getSmallCards(cards) {
    return cards.filter((card) => {
      if (isDefined(card.extras.breakpoint)) {
        return card.extras.breakpoint == 'small';
      } else {
        return true;
      }
    });
  }

  filterCardsForLocation(cards, filter) {
    return cards.filter((card) => {
      return card.extras.location == filter;
    });
  }

  processCards(cards, sort:boolean, filter?: string) {
    if (isDefined(cards) && cards.length > 0) {
      cards = cards.filter((card) => {
        return isDefined(card.extras.lang) && card.extras.lang.toLowerCase() == this.appStore.lang.toLowerCase();
      });
      cards = cards.filter((card) => {
        if (isDefined(card.extras.appAvailability)) {
          switch(card.extras.appAvailability.toLowerCase()) {
            case 'app':
              return this.appStore.isApp('app');
            case 'olb':
              return this.appStore.isApp('inapp');
            case 'web':
              return !this.appStore.isApp();
            case 'notapp':
              return !this.appStore.isApp('app');
            case 'notolb':
              return !this.appStore.isApp('inapp');
            case 'notweb':
              return this.appStore.isApp();
          }
        } 
        else {
          return true;
        }
      });
      if (isDefined(filter)) {
        cards = cards.filter((card) => {
          return card.extras.location == filter;
        });
      }
      let cardsTemp = [];
      let cardsTempActivityNames = [];
      for (let i = 0; i < cards.length; i++) {
        if (isDefined(cards[i].extras.activityName) && !cardsTempActivityNames.includes(cards[i].extras.activityName)) {
          cardsTemp.push(cards[i]);
          cardsTempActivityNames.push(cards[i].extras.activityName);
        }
      }
      cards = cardsTemp;

      let cardsPerUser = [];
      for(let i = 0; i < cards.length; i++) {
        if(!isDefined(cards[i].extras.isPEUser)) {
          cardsPerUser.push(cards[i]);
        }
        if(isDefined(cards[i].extras.isPEUser) && String(cards[i].extras.isPEUser).toLowerCase() === 'true') {
          if(this.appStore.state.user.premiumUser) {
            cardsPerUser.push(cards[i]);
          }
        } 
        if(isDefined(cards[i].extras.isPEUser) && String(cards[i].extras.isPEUser).toLowerCase() === 'false') {
          if(!this.appStore.state.user.premiumUser) {
            cardsPerUser.push(cards[i]);
          }
        }
      }

      cards = cardsPerUser;
      if (sort) {
        cards.sort((a,b) => {
          if (isDefined(a.extras.priority)) {
            if (isDefined(b.extras.priority)) {
              return b.extras.priority - a.extras.priority;
            } else {
              return -1;
            }
          } else {
            if (isDefined(b.extras.priority)) {
              return 1;
            } else {
              if (a.updated > b.updated) {
                return -1;
              } else {
                return 1;
              }
            }
          }
        });
      }
      return cards;
    }
    return [];
  }

  transformCards(cards) {
    cards.forEach(card => {
      try{
      if(this.appStore.platform.toLowerCase() === 'android') {
        card.extras = card.extras;
      }
      if(this.appStore.platform.toLowerCase() === 'ios') {
        card.extras = JSON.parse(card.extras.replace(/\\/g, ""));
      }
        //format extras
       
      } catch(e) {
        card.extras = {};
      }
      //format properties
        if(card.domain && !card.linkText) {
          card.linkText = card.domain;
        }
        if(card.image && !card.imageUrl) {
          card.imageUrl = card.image;
        }
        if(card.cardDescription && !card.description) {
          card.description = card.cardDescription;
        }
        if(card.isTest && !card.test) {
          card.test = card.isTest;
        }
      
   
      
     


    });
    return cards;
  }

  logContentCardImpressions(cards) {
    if(!this.appStore.isApp('app')) {
      braze.logContentCardImpressions(cards);
    } else {
      for (let i = 0; i < cards.length; i++) {
        BrazePlugin.logContentCardImpression(cards[i].id);
      }
    }
    for (let i = 0; i < cards.length; i++) {
      cards[i].viewed = true;
    }
  }

  logContentCardClick(card) {
    if(!this.appStore.isApp('app')) {
      braze.logContentCardClick(card);
    } else {
      BrazePlugin.logContentCardClicked(card.id);
    }
  }

  logContentCardDismiss(card) {
    if(!this.appStore.isApp('app')) {
      braze.logCardDismissal(card);
    } else {
      BrazePlugin.logContentCardDismissed(card.id);
    }
    card.dismissed = true;
  }

  unfoldJSON(obj: any) {
    if (isDefined(obj) && obj.constructor == ({}).constructor) {
      let newObj = {};
      for (let item in obj) {
        let temp = this.unfoldJSON(obj[item]);
        if (isDefined(temp) && temp.constructor == ({}).constructor) {
          for(let jtem in temp) {
            newObj[item + "_" + jtem] = temp[jtem];
          }
        } else {
          newObj[item] = temp;
        }
      }
      return newObj;
    } else {
      return obj;
    }
  }

  obscureAccount(obj: any, name?: string, targetList?: any) {
    if (isDefined(obj) && obj.constructor == ({}).constructor) {
      let newObj = {};
      for (let item in obj) {
        newObj[item] = this.obscureAccount(obj[item], item, targetList);
      }
      return newObj;
    } else {
      if (isDefined(targetList)) {
        for(let i = 0; i < targetList.length; i++) {
          if (name && name.toLowerCase() == targetList[i]) {
            return '*'.repeat(obj.length - 3) + obj.substring(obj.length - 3, obj.length);
          }
        }
      }
      if (name && name.toLowerCase() == 'accountnumber') {
        return '*'.repeat(obj.length - 3) + obj.substring(obj.length - 3, obj.length);
      }
      return obj;
    }
  }

  //prioritizes obj2 content
  combineJsonObj(obj1: any, obj2: any) {
    if (isDefined(obj1) && obj1.constructor == ({}).constructor && isDefined(obj2) && obj2.constructor == ({}).constructor) {
      let newObj = {};
      for (let item in obj1) {
        newObj[item] = this.combineJsonObj(obj1[item], obj2[item]);
      }
      for (let item in obj2) {
        newObj[item] = this.combineJsonObj(obj1[item], obj2[item]);
      }
      return newObj;
    } else if (isDefined(obj1) && obj1.constructor == ({}).constructor && !isDefined(obj2)) {
      let newObj = {};
      for (let item in obj1) {
        newObj[item] = this.combineJsonObj(obj1[item], null);
      }
      return newObj;
    } else if (isDefined(obj2) && obj2.constructor == ({}).constructor) {
      let newObj = {};
      for (let item in obj2) {
        newObj[item] = this.combineJsonObj(obj2[item], null);
      }
      return newObj;
    } else {
      return obj2 ? obj2 : obj1;
    }
  }

  // OneTrust Web

  isOptanonAlertBoxClosed() {
    let OptanonAlertBoxClosed = this.getCookie("OptanonAlertBoxClosed")
    return OptanonAlertBoxClosed
  }

  checkOptanonCookie(cookieType: string) {
    let optanonCookie = this.getCookie("OptanonConsent");
    if (optanonCookie) { 
      var groupsIndex = optanonCookie.indexOf('groups='); 
      if (groupsIndex !== -1) { 
        var cookieGroups = optanonCookie.slice(groupsIndex).split('&')[0].split('=')[1]; 
        var groupsArray = cookieGroups.split(','); 
        for (var i = 0; i < groupsArray.length; i++) { 
          var group = groupsArray[i].split(':'); 
          if (group[0] === cookieType) { 
            return parseInt(group[1]); 
          } 
        }
      } 
    }
     return null;
  }

  listenOptanonCookie() {
    window.addEventListener("OneTrustGroupsUpdated", (event) => {
      if (this.skipConsent) {
        this.skipConsent = !this.skipConsent;
      }
      else {
        let value = (event as any)
        if (value.detail.includes("C0003")) {
          this.performanceCookie = true;
          (window as any).GaTrackEnabled = true;
          this.gtagServiceEnabled = true;
          console.log("PerformanceCookie Accepted")
          if (this.gtagServiceEnabled && (window as any).GaTrackEnabled)  {
            this.gtagService.googleAnalyticsHeadScripts(localStorage.getItem("username"))
          }
          this.oneTrustPerformanceSubject.next(true);
        } else {
          console.log("PerformanceCookie Rejected")
          this.performanceCookie = false;
          (window as any).GaTrackEnabled = false;
          this.gtagServiceEnabled = false;
          this.oneTrustPerformanceSubject.next(false);
        }
        if (value.detail.includes("C0004")) {
          this.advertisingCookie = true;
          console.log("AdvertisingCookie Accepted")
          this.oneTrustAdvertisingSubject.next(true);
        } else {
          console.log("AdvertisingCookie Rejected")
          this.advertisingCookie = false;
          this.oneTrustAdvertisingSubject.next(false);
        }
        if (value.detail.includes("C0002")) {
          console.log("AdvertisingCookie Accepted")
          this.oneTrustFunctionality = true;
        } else {
          console.log("AdvertisingCookie Rejected")
          this.oneTrustFunctionality = false;
        }
      }
  });
}

  getCookie(cookieName) {
    return this.persistenceService
      .cookieStoreRetrieve(cookieName)
  }

  openOneTrustUI() {
    if(this.appStore.isApp() && this.appStore.features.OneTrust) {
      this.appStore.features.OneTrust.showPreferenceCenterUI();
    }
  }
  
}
