import { Subject, forkJoin } from 'rxjs';
import { CustomAppErrorHandler } from 'src/app/core/components/error/custom-error/custom-app-error-handler';
import { QuickTradeStockValidator } from './quick-trade-stocks-landing.validator';
import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, HostListener, Inject, Renderer2, Input } from '@angular/core';
import { AppStore } from 'src/app/shared/models/app-store';
import { isDefined, openWindow } from 'src/app/shared/services/utils.service';
import { ModalService } from 'src/app/shared/components/modal/services/modal.service';
import { ContentStore } from 'src/app/shared/store/content-store';
import { GlobalContentStore, ContentType } from 'src/app/shared/store/global-content-store';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { _User } from 'src/app/shared/models/user';
import { ContentService } from 'src/app/core/services/content.service';
import { registerLocaleData, DOCUMENT, DatePipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import moment from 'moment';
import _ from 'lodash';
import { CommonService } from 'src/app/shared/services/common.service';
import { isNumber } from 'highcharts';
import { Subscription, of } from 'rxjs';
import { CurrencyFormatterPipe } from 'src/app/core/pipes/currency-formatter.pipe';
import { Lang, Lob } from 'src/app/shared/models/lob.enum';
import { QuickTradeStocksLandingService } from './services/quick-trade-stocks-landing.service';
import { HelpService } from 'src/app/modules/txn/help/services/help.service';
import { HttpService } from 'src/app/core/services/http.service';
import { QuickTradeStocksLandingStore } from './data/quick-trade-stocks-landing-store';
import { QuickTradeStocksLandingInputs } from './data/quick-trade-stocks-landing-data';
import { QuickTradeStocksVerifyStore } from '../verify/data/quick-trade-stocks-verify-store';
import { SpinnerService } from 'src/app/core/components/spinner/service/spinner.service';
import { PersistenceService } from 'src/app/core/services/persistence.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { OmniturePageDetail } from 'src/app/shared/models/route-config';
import { SymbolQuoteService } from '../../symbol-quote/services/symbol-quote.service';
import { TradeStocksLandingStore } from 'src/app/modules/txn/tradeStocks/landing/data/trade-stocks-landing-store';
import { TouchIDConfirmService } from 'src/app/modules/txn/userPreferences/touchid/submit/services/touchid-confirm.service';
import { TradeStocksVerifyStore } from 'src/app/modules/txn/tradeStocks/verify/data/trade-stocks-verify-store';
import { Router } from '@angular/router';
import { CarouselComponent } from 'src/app/shared/components/carousel/carousel.component';

declare let require: any;
const gatewayConfig = require('src/app/config/gateway-config.json');

@Component({
  selector: 'app-quick-trade-stocks-landing',
  templateUrl: './quick-trade-stocks-landing.component.html',
  styleUrls: ['./quick-trade-stocks-landing.component.scss'],
  animations: [
    trigger('fadeOutIn', [
      transition('void => *', [
        style({ opacity: 0, transform: 'translateX(130%)' }),
        animate('400ms ease-in-out')
      ]),

      transition('* => void', [animate('400ms ease-in-out'), style({ opacity: 1, transform: 'translateX(130%)' })]),
    ])
  ]
})

export class QuickTradeStocksLandingComponent implements OnInit, OnDestroy {

  @Input() public mode: string = 'quickTrade';
  @Input() public data: any;
  @Input() public fullData: any;
  @Output() submit = new EventEmitter();

  storeArray = {
    "quickTrade": QuickTradeStocksLandingStore,
    "smallTradeStocks": TradeStocksLandingStore
  }

  verifyStoreArray = {
    "quickTrade": QuickTradeStocksVerifyStore,
    "smallTradeStocks": TradeStocksVerifyStore
  }

  get LOB() { return Lob; }
  accData: any;
  toggleBtn: boolean;
  buyingSliderMax: any;
  limitprice: number;
  stopLimitPrice: number;
  stopPriceValue: number;
  trailingDollarPrice: any;
  trailingLimitOffset: number;
  trailingStopPercentMod: number;
  dateParam: any;
  accountList: any;
  stockHoldings: any = [];
  chooseSymbol = false;
  settlementAccountList: any = [];
  pagePermissions: any;
  hasBuyToCover;
  actionArray: any = [];
  priceArray: any = [];
  expiryArray: any = [];
  disabledExpiryArray: any = [];
  symbolLoading = false;
  selectedSymbol: any = {};
  quote: any = undefined;
  query = '';
  isQuote = false;
  isQuoteExpanded: number = 2; // 0 = contacted, 1 = expanding, 2 = expanded, 3 = contracting
  errorQuote = false;
  isPayFrom = true;
  inputs: QuickTradeStocksLandingInputs;
  showSwitchInput = false;
  buyingPowerContent: any;
  quoteContent: any;
  estimatedLimitPrice: number = undefined;
  estimatedTriggerPrice: number = undefined;
  quoteFromChildComponent: any;
  buyingPowerLoading = false;
  balanceLoading = false;
  oldActionMode = '';
  formError: any = {};
  quoteError: any;
  hidePage = false;
  ismain = false;
  readonly = false;
  modalId: string;
  customDateSelected: any;
  dateSelectorParam: any;
  monthList: any;
  dayList: any;
  isBalanceAvailable = false;
  dollarStr: any;
  percentageStr: any;
  limit = false;
  market = false;
  stop = false;
  trailing = false;
  toggle = false;
  quickTradeContent: ContentType = this.content;
  @ViewChild('quickTradeStockForm') quickTradeStockForm: any;
  agreementError;
  exchangeRate;
  value = 30;
  max: number = this.value * 2;
  min = 0;
  selectedAccount;
  singleAccount = false;
  sliderCuurentVal = 40;
  dash = '-';
  selectedIndexForSell = -1;
  showExtendedHours: boolean = false;
  notch: boolean;
  isScreenReaderON = false;
  isKeyboardOpen: boolean;

  priceToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };

  moreInfoToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };
  
  accountToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };

  buyToCoverToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };

  shortSellToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };

  buyingPowerToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };

  triggerDeltaToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };

  triggerDeltaPercentToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };

  limitOffsetToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };

  ehToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: '',
    title: ''
  };

  uniqueDateId: string;
  subscriptions = new Subscription();
  routeSubscription: any;
  idx = 0;
  currentModule = 'quick-landing';
  settleAmountCurrency = '';
  rangeSlider = 0;
  quotesData: any;
  selectedSymbolForStore: any;
  settlementAccountListData: any;
  formSubmitted = false;
  account: any;
  stocksHoldings: any;
  stockHoldingIndex: any;
  loadAccountSelector = false;
  sessionStore = { inFlow: {}, savedInfo: {} };
  symbolMessage: any;
  sessionStoreEnable = true;
  @Output() dataLoaded = new EventEmitter<any>();
  loadingQuote;
  loadingSlider;
  commission;
  uniqueName = "-quick-trade-big";
  contentCards: any = [];
  carouselContent: any = {};

  @ViewChild('small_carousel') smallCarousel: CarouselComponent;

  constructor(
    private contentService: ContentService,
    public store: QuickTradeStocksLandingStore,
    public appStore: AppStore,
    public modalservice: ModalService,
    private service: QuickTradeStocksLandingService,
    public content: ContentStore,
    public globalContent: GlobalContentStore,
    private subscriptionService: ObservableSubscriptionService,
    private commonService: CommonService,
    private currencyFormatterPipe: CurrencyFormatterPipe,
    private _helpService: HelpService,
    private httpService: HttpService,
    private spinnerService: SpinnerService,
    private errorHandler: CustomAppErrorHandler,
    private quoteService: SymbolQuoteService,
    private persistenceService: PersistenceService,
    private trackingService: TrackingService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) { }

  ngOnDestroy() {
    this.storeArray[this.mode].crossFlow = undefined;
    this.storingOndestroy();
    this.subscriptions.unsubscribe();
  }

  storingOndestroy() {
    if (this.formSubmitted == false) {
      if (this.inputs) {
        this.storeArray[this.mode].inFlow = {
          settlementAccountListData: this.settlementAccountListData,
          selectedSymbolForStore: this.selectedSymbolForStore,
          inputs: JSON.parse(JSON.stringify(this.inputs)),
          quotesData: this.quotesData,
          stocksHoldings: this.stocksHoldings,
          account: this.account,
          selectedAccount: this.selectedAccount,
          rangeSlider: this.rangeSlider,
          stockHoldingIndex: this.stockHoldingIndex,
          settlementIndex: this.inputs.settlementIndex,
          selectedIndexForSell: this.selectedIndexForSell,
          quoteFromChildComponent: this.quoteFromChildComponent,
          action: this.inputs.action,
          priceMode: this.inputs.priceMode,
          trailingMode: this.inputs.trailingMode
        };
        this.storeArray[this.mode].savedInfo = {
          inputs: JSON.parse(JSON.stringify(this.inputs))
        };
      }
    } else {
      this.storeArray[this.mode].inFlow = {
        settlementAccountListData: this.settlementAccountListData,
        settlementAccountList: this.settlementAccountList,
        selectedSymbolForStore: this.selectedSymbolForStore,
        inputs: JSON.parse(JSON.stringify(this.inputs)),
        quotesData: this.quotesData,
        stocksHoldings: this.stocksHoldings,
        account: this.account,
        selectedAccount: this.selectedAccount,
        rangeSlider: this.rangeSlider,
        stockHoldingIndex: this.stockHoldingIndex,
        settlementIndex: this.inputs.settlementIndex,
        selectedIndexForSell: this.selectedIndexForSell,
        quoteFromChildComponent: this.quoteFromChildComponent,
        action: this.inputs.action,
        priceMode: this.inputs.priceMode,
        trailingMode: this.inputs.trailingMode
      };
    }
    if (this.sessionStoreEnable) {
      this.sessionStore.inFlow = this.storeArray[this.mode].inFlow;
      this.sessionStore.savedInfo = this.storeArray[this.mode].savedInfo;
      this.persistenceService.sessionStorePersist(this.mode == 'quickTrade' ? 'landingStoreQTBig' : 'landingStoreQTSmall', JSON.stringify(this.sessionStore));
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unLoadHandler($event) {
    this.storingOndestroy();
  }

  mergeAccountDetails(accListTrade, accListHoldings) {
    const accList = [];
    //if both MRL and CSH are available, merge into 1 row
    for (let i = 0; i < accListTrade.length; i++) {
      for (let j = 0; j < accListHoldings.length; j++) {
        if (accListTrade[i].AccountNumber == accListHoldings[j].AccountNumber) {
          accListTrade[i].hasBothMarginCash = { 'USD': false, 'CAD': false };
          const filteredSubAccountsUSD = accListHoldings[j].Subaccounts.filter(acc => acc.Currency == 'USD');
          const filteredSubAccountsCAD = accListHoldings[j].Subaccounts.filter(acc => acc.Currency == 'CAD');
          let requiredSubAccountsCAD = [];
          let requiredSubAccountsUSD = [];
          if (filteredSubAccountsUSD.some(acc => acc.AccountType == 'MRL') &&
            filteredSubAccountsUSD.some(acc => acc.AccountType == 'CSH')) {
            requiredSubAccountsUSD = filteredSubAccountsUSD.filter(acc => acc.AccountType == 'MRL' || acc.AccountType == 'MRS');
            accListTrade[i].hasBothMarginCash['USD'] = true;
          } else {
            requiredSubAccountsUSD = filteredSubAccountsUSD;
          }
          if (filteredSubAccountsCAD.some(acc => acc.AccountType == 'MRL') &&
            filteredSubAccountsCAD.some(acc => acc.AccountType == 'CSH')) {
            requiredSubAccountsCAD = filteredSubAccountsCAD.filter(acc => acc.AccountType == 'MRL' || acc.AccountType == 'MRS');
            accListTrade[i].hasBothMarginCash['CAD'] = true;
          } else {
            requiredSubAccountsCAD = filteredSubAccountsCAD;
          }
          accListTrade[i].Subaccounts = requiredSubAccountsUSD.concat(requiredSubAccountsCAD);
          accListTrade[i].Subaccounts = accListTrade[i].Subaccounts.sort((a, b) => {
            if (a.Currency > b.Currency) {
              return 1;
            } if (a.Currency < b.Currency) {
              return -1;
            }
            return 0;
          });
          break;
        }
      }
      accList.push(accListTrade[i]);
    }
    return accList;
  }

  ngOnInit() {
    this.notch = this.commonService.isNotchPhone();
    this.isScreenReaderON = this.appStore.features.isScreenReaderON ? true : false;

    this.subscriptions.add(this.subscriptionService.isKeyboardOpen.subscribe(
      (data) => {
        this.isKeyboardOpen = data;
      }
    ));

    this.subscriptions.add(this.contentService.fetchContent('quicktradestockslanding').subscribe((data) => {
      this.quickTradeContent = data;

      this.defineToolTips();
    }));

    let self = this;
    this.subscriptions.add(this.subscriptionService.tradeDrawerCrossFlow.subscribe(() => {
      self.initPage(self.data, self.accData);
    }));

    this.subscriptions.add(this.subscriptionService.globalRefreshEvent$.subscribe((channel) => {
      if (this.mode == 'quickTrade') {
        if (channel == 'quickTrade') {
          self.refreshAll();
          this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:details:quote-refresh');
        }
      } else {
        if (channel == 'header') {
          self.refreshAll();
          this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:details:quote-refresh');
        }
      }
    }));

    if (this.mode == 'quickTrade') {
      // clear verify session
      this.persistenceService.removeSessionStoreValue(this.mode == 'quickTrade' ? 'verifyStoreQTBig' : 'verifyStoreQTSmall');
      // session to get on refresh
      const sessionValue = this.persistenceService.sessionStoreRetrieve(this.mode == 'quickTrade' ? 'landingStoreQTBig' : 'landingStoreQTSmall');

      if (sessionValue != null && sessionValue != undefined && sessionValue != 'null') {
        const jsonValue = JSON.parse(sessionValue);
        this.storeArray[this.mode].inFlow = jsonValue?.inFlow;
        this.storeArray[this.mode].savedInfo = jsonValue?.savedInfo;
      }
      
      const req: any = { IncludeBalance: true };
      this.spinnerService.setFocusElement(undefined);
      this.subscriptions.add(forkJoin({
        data: this.httpService.post(gatewayConfig.APIServices.stocksInit.url, req, { params: { skiploading: 'true' } }),
        accData: this.httpService.post(gatewayConfig.APIServices.holdingsNewLoad.url, {}, { params: { skiploading: 'true' } })
      }).subscribe(response => {
        const data = response.data;
        const accData = response.accData;
        this.initPage(data, accData);
      }, (err) => {
        this.accountList = [];
        this.inputs = this.store.state.input;
        this.inputs.action = 'BUY';
        this.inputs.stockHoldingIndex = -1;
        this.inputs.settlementIndex = -1;
        this.stockHoldings = [];
        this.settlementAccountList = [];
        this.inputs.accountIndex = -1;
        this.inputs.action = '';
        this.inputs.symbol = '';
        this.inputs.stockHoldingIndex = -1;
        this.inputs.market = '';
        this.inputs.quantity = undefined;
        this.inputs.quantityValue = undefined;
        this.inputs.priceMode = 'marketMode';
        this.inputs.limit = undefined;
        this.inputs.stopPrice = undefined;
        this.inputs.stopLimit = undefined;
        this.inputs.triggerDollarPrice = undefined;
        this.inputs.triggerPercentagePrice = undefined;
        this.inputs.limitOffsetDollar = undefined;
        this.inputs.expiryMode = 'day';
        this.estimatedTriggerPrice = undefined;
        this.estimatedLimitPrice = undefined;
        this.hasBuyToCover = false;
        this.setActionArray();
        this.dateParam = undefined;
        this.modalId = 'customDate';
        this.customDateSelected = this.content.text.customDate;
        this.dateSelectorParam = {
          dateParam: this.dateParam
        };
        this.pagePermissions = {};
        this.data = 'test';
        this.updateExtendedHours();
        this.setExpiryCustomDisabled();

        setTimeout(() => {
          this.dataLoaded.emit(true);
          this.initRangeEl(this.inputs.quantity);
          const rangeEl = document.getElementById('myInput');
          if (rangeEl) {
            this.updateRangeEl(rangeEl, this.inputs.quantity);
          }
          this.subscriptionService.setCustomError(err.error.Exception);
          this.setFocus('closeBtn');
        }, 200);
      }));
    } else {
      // clear verify session
      this.persistenceService.removeSessionStoreValue(this.mode == 'quickTrade' ? 'verifyStoreQTBig' : 'verifyStoreQTSmall');
      // session to get on refresh
      const sessionValue = this.persistenceService.sessionStoreRetrieve(this.mode == 'quickTrade' ? 'landingStoreQTBig' : 'landingStoreQTSmall');

      if (sessionValue != null && sessionValue != undefined && sessionValue != 'null') {
        const jsonValue = JSON.parse(sessionValue);
        this.storeArray[this.mode].inFlow = jsonValue?.inFlow;
        this.storeArray[this.mode].savedInfo = jsonValue?.savedInfo;
      }

      this.uniqueName = '-quick-trade-small';
      this.initPage(this.data[0], this.data[1], this.data);
      
      if (this.appStore.lob == 'ie') {
        let self = this;
        this.subscriptions.add(this.subscriptionService.brazeContentCardSubscriber.subscribe((cards) => {
          self.commonService.getCachedBrazeContentCards(true, "trade_stocks_details").then((cards) => {
            self.handleNewContentCards(cards);
          });
        }));
        self.commonService.getCachedBrazeContentCards(true, "trade_stocks_details").then((cards)=>{
          self.handleNewContentCards(cards);
        });
      }
    }
    setTimeout(() => {
      this.loadAccountSelector = true;
    }, 1000);

    //call fxrate api
    this.subscriptions.add(this.service.getFxRate({}).subscribe((response: any) => {
      if (response) {
        this.exchangeRate = response.FxRate;
      }
    }));
    this.subscriptions.add(this.subscriptionService.openOrCloseDrawer.subscribe((data) => {
      if (data == 'mobile') {
        if (isDefined(this.selectedSymbol) && isDefined(this.selectedSymbol.SymbolName) && (!isDefined(this.storeArray[this.mode].crossFlow) || this.storeArray[this.mode].crossFlow == null)) {
          this.refreshQuoteDetails(this.selectedSymbol);
        }
        if (this.inputs && this.inputs.accountIndex > -1) {
          this.getBuyingPowerForSelectedAccount(this.inputs.accountIndex);
        }
        this.setFocus('closeBtn');
      }
    }));

    if (this.mode !== 'quickTrade') {
      this.commonService.logBraze("viewed_trade_stock_details", { platform: "quickTrade"} );
    }
  }

  initPage(data, accData, fullData?) {
    if (data && accData) {
      this.data = data;
      this.accData = accData;
      this.accountList = this.mergeAccountDetails(data.AccountList, accData.AccountList);
      this.pagePermissions = this.data.PermissionFlags;
      this.stockHoldings = [];
      this.settlementAccountList = [];
      this.inputs = this.store.state.input;
      this.setActionArray();
      this.setPriceArray();
      this.setExpiryArray();

      if (this.mode !== 'quickTrade' || this.storeArray[this.mode].inFlow == undefined || this.storeArray[this.mode].inFlow == null) {
        this.inputs.accountIndex = -1;
        this.inputs.stockHoldingIndex = -1;
        this.inputs.settlementIndex = -1;
        this.changeActionMode('BUY');
      }

      this.inputs.market = '';
      this.inputs.quantity = undefined;
      this.inputs.quantityValue = undefined;
      this.inputs.priceMode = 'marketMode';
      this.inputs.trailingMode = 'dollar';
      this.inputs.limit = undefined;
      this.inputs.stopPrice = undefined;
      this.inputs.stopLimit = undefined;
      this.inputs.triggerDollarPrice = undefined;
      this.inputs.triggerPercentagePrice = undefined;
      this.inputs.limitOffsetDollar = undefined;
      this.inputs.expiryMode = 'day';
      this.estimatedTriggerPrice = undefined;
      this.estimatedLimitPrice = undefined;
      this.hasBuyToCover = false;
      this.dateParam = this.data.DatePickerAdditionalInfo;
      this.modalId = 'customDate';
      this.customDateSelected = this.quickTradeContent.text.customDate;
      this.inputs.extendedHours = false;
      this.dateSelectorParam = {
        dateParam: this.dateParam
      };

      if (this.data) {
        registerLocaleData(localeFr, 'fr');
        this.uniqueDateId = this.appStore.uniqueId;

        if (this.appStore.lang.toUpperCase() === 'FR') {
          this.dollarStr = '(' + this.quickTradeContent.text.dollarSign + ')';
          this.percentageStr = '(' + this.quickTradeContent.text.percentage + ')';
        } else {
          this.dollarStr = this.quickTradeContent.text.dollarSign;
          this.percentageStr = this.quickTradeContent.text.percentage;
        }

        const monthStart = moment(this.dateParam.MinGTDate).month();
        const monthEnd = moment(this.dateParam.MaxGTDate).month();

        if (monthStart <= monthEnd) {
          this.monthList = [];
          for (let i = monthStart; i < monthEnd + 1; i++) {
            this.monthList.push({ key: i, value: moment().locale(this.appStore.lang).localeData().months()[i] });
          }
        } else {
          this.monthList = [];
          for (let i = monthStart; i < 12; i++) {
            this.monthList.push({ key: i, value: moment().locale(this.appStore.lang).localeData().months()[i] });
          }
          for (let i = 0; i < monthEnd + 1; i++) {
            this.monthList.push({ key: i, value: moment().locale(this.appStore.lang).localeData().months()[i] });
          }
        }
        this.inputs.dateDay = moment(this.dateParam.DefaultGTDate).date();
        this.inputs.dateMonth = moment(this.dateParam.DefaultGTDate).month();
        this.readonly = this.isAccountSelectionReadonly(this.accountList);

        this.subscriptions.add(this.contentService.fetchContent('buyingpowertooltip').subscribe((data) => {
          this.buyingPowerContent = data;
        }, (err) => {

        }));
        // PREQUOTE COULD FAIL. NEED TO HANDLE

        if (isDefined(this.storeArray[this.mode].crossFlow) && this.storeArray[this.mode].crossFlow != null) {
          this.clearPage();
          if (this.storeArray[this.mode].crossFlow.action) {
            this.inputs.action = this.storeArray[this.mode].crossFlow.action;
          }
          if (this.mode != 'quickTrade') {
            if (this.storeArray[this.mode].crossFlow.action) {
              if (this.inputs.action === 'BUY' || this.inputs.action === 'SHORT') {
                this.handleOptionalResponse(fullData[2], 'quick');
              } else if (this.inputs.action === 'SELL' || this.inputs.action === 'BUYTOCOVER') {
                this.handleOptionalResponse(fullData[2], 'hold');
                this.handleOptionalResponse(fullData[3], 'quick');
              }
            } else {
              this.handleOptionalResponse(fullData[2], 'quick');
            }
          }
          if (this.storeArray[this.mode].crossFlow.accountNumber) {
            this.inputs.accountIndex = this.setCarryOverAccount(this.storeArray[this.mode].crossFlow.accountNumber);
          }
          const selectedAccountForDisplayFromStore = this.storeArray[this.mode].crossFlow.selectedAccount;
          if (selectedAccountForDisplayFromStore !== undefined && selectedAccountForDisplayFromStore !== null) {
            this.selectedAccount = selectedAccountForDisplayFromStore;
            this.AccountListReturn(selectedAccountForDisplayFromStore, true, true);
          }

          if (this.mode != 'quickTrade') {
            this.stockHoldings = this.stockHoldings.filter(ho => ho.AccountCurrency == this.settleAmountCurrency);
            if (this.stockHoldings && this.stockHoldings.length > 0) {
              const thisPage = this;
              let quant = this.storeArray[this.mode].crossFlow.quantity;
              if (isDefined(quant)) {
                quant = quant.replace(/[^\d]/g, '');
              }
              if (this.quote) {
                this.inputs.stockHoldingIndex = _.findIndex(this.stockHoldings, (o: any) => {
                  return o.Symbol.SymbolName === thisPage.quote.Symbol.SymbolName &&
                    o.Quantity.Data === quant;
                });
              } else {
                this.inputs.stockHoldingIndex = _.findIndex(this.stockHoldings, (o: any) => {
                  return (o.Symbol.SymbolName === this.storeArray[this.mode].crossFlow.symbol) &&
                    o.Symbol.Market === this.storeArray[this.mode].crossFlow.market &&
                    o.Quantity.Data === quant;
                });
                if (this.inputs.stockHoldingIndex > -1) {
                  this.inputs.market = this.stockHoldings[this.inputs.stockHoldingIndex].Symbol.Market;
                } else {
                }
              }

              if (this.inputs.stockHoldingIndex === -1) {
                this.chooseSymbol = false;
              } else {
                this.chooseSymbol = true;
              }
            } else {
              this.stockHoldings = [];
              this.inputs.stockHoldingIndex = -1;
              this.chooseSymbol = false;
            }
            this.inputs.symbol = this.storeArray[this.mode].crossFlow.symbol;
            this.inputs.market = this.storeArray[this.mode].crossFlow.market;
            this.setSelectedSymbol(this.inputs.symbol, this.inputs.market);
            setTimeout(() => {
              this.query = this.inputs.symbol.toUpperCase();
            }, 100);
            this.updateQuote(this.selectedSymbol);
          } else {
            if (this.inputs.action == 'BUY') {
              this.inputs.symbol = this.storeArray[this.mode].crossFlow.symbol;
              this.inputs.market = this.storeArray[this.mode].crossFlow.market;
              this.setSelectedSymbol(this.inputs.symbol, this.inputs.market);
              setTimeout(() => {
                this.query = this.inputs.symbol.toUpperCase();
              }, 100);
              this.updateQuote(this.selectedSymbol);
            } else {
              this.quoteFromChildComponent = undefined;
            }
          }

          if (this.storeArray[this.mode].crossFlow.action && this.storeArray[this.mode].crossFlow.accountNumber) {
            this.getSettlementAccount(this.inputs.action);
          }
        } else if (isDefined(this.storeArray[this.mode].savedInfo) && this.storeArray[this.mode].savedInfo != null) {
          this.inputs = JSON.parse(JSON.stringify(this.storeArray[this.mode].savedInfo.inputs));
          // implementation is down

          const actionFromStore = this.storeArray[this.mode].savedInfo.inputs?.action;
          if (actionFromStore !== undefined && actionFromStore !== null && actionFromStore !== '') {
            this.inputs.action = actionFromStore;
          } else {
            this.changeActionMode('BUY');
          }

          if (this.mode == 'quickTrade') {
            if (this.inputs.action === 'BUY' || this.inputs.action === 'SHORT') {
              this.handleOptionalResponse(this.storeArray[this.mode].inFlow.settlementAccountListData, 'sett'); // settlement
              this.handleOptionalResponse(this.storeArray[this.mode].inFlow.quotesData, 'quick'); // qoute
            } else if (this.inputs.action === 'SELL' || this.inputs.action === 'BUYTOCOVER') {
              this.handleOptionalResponse(this.storeArray[this.mode].inFlow.stocksHoldings, 'hold');
              this.handleOptionalResponse(this.storeArray[this.mode].inFlow.settlementAccountListData, 'sett');
              this.handleOptionalResponse(this.storeArray[this.mode].inFlow.quotesData, 'quick');
            }
          } else {
            if (this.inputs.action === 'BUY' || this.inputs.action === 'SHORT') {
              this.handleOptionalResponse(fullData[2], 'sett'); // settlement
              this.handleOptionalResponse(fullData[3], 'quick'); // qoute
            } else if (this.inputs.action === 'SELL' || this.inputs.action === 'BUYTOCOVER') {
              this.handleOptionalResponse(fullData[2], 'hold');
              this.handleOptionalResponse(fullData[3], 'sett');
              this.handleOptionalResponse(fullData[4], 'quick');
            }
          }

          if (this.stockHoldings && this.stockHoldings.length > 0) {

            if (this.inputs.stockHoldingIndex === -1) {
              this.chooseSymbol = false;
            } else {
              this.chooseSymbol = true;
            }
          } else {
            this.stockHoldings = [];
            this.inputs.stockHoldingIndex = -1;
            this.chooseSymbol = false;
          }
          if (this.settlementAccountList && this.settlementAccountList.length === 1) {
            this.inputs.settlementIndex = 0;
            this.updateSelectedPaymentAccount(0);
          }

          if (this.mode == 'quickTrade') {
            const selectedAccountForDisplayFromStore = this.storeArray[this.mode].inFlow.selectedAccount;
            if (selectedAccountForDisplayFromStore !== undefined && selectedAccountForDisplayFromStore !== null) {
              this.selectedAccount = selectedAccountForDisplayFromStore;
              this.AccountListReturn(selectedAccountForDisplayFromStore, true);
            }
            const settlementIndexFromStore = this.storeArray[this.mode].inFlow.settlementIndex;
            if (settlementIndexFromStore !== undefined && settlementIndexFromStore !== null) {
              this.inputs.settlementIndex = settlementIndexFromStore;
            }

            const selectedValueOfSymbol = this.storeArray[this.mode].inFlow?.selectedSymbolForStore;
            if (selectedValueOfSymbol !== undefined && selectedValueOfSymbol !== null) {
              this.updateSymbol(selectedValueOfSymbol);
            }

            const rangeSliderFromStore = this.storeArray[this.mode].inFlow.rangeSlider;
            if (rangeSliderFromStore !== undefined && rangeSliderFromStore !== null) {
              this.rangeSlider = rangeSliderFromStore;
            }


            const quoteFromChildComponentFromStore = this.storeArray[this.mode].inFlow.quoteFromChildComponent;
            if (quoteFromChildComponentFromStore !== undefined && quoteFromChildComponentFromStore !== null) {
              this.quoteFromChildComponent = quoteFromChildComponentFromStore;
            }

            const priceModeFromStore = this.storeArray[this.mode].inFlow.priceMode;
            if (priceModeFromStore === undefined || priceModeFromStore == null || priceModeFromStore == '') {
              if (this.selectedAccount !== undefined && this.selectedAccount == null) {
                this.changePriceMode('marketMode');
              }
            }
          } else {
            const selectedAccountForDisplayFromStore = this.storeArray[this.mode].inFlow.selectedAccount;
            if (selectedAccountForDisplayFromStore !== undefined && selectedAccountForDisplayFromStore !== null) {
              this.selectedAccount = selectedAccountForDisplayFromStore;
              this.AccountListReturn(selectedAccountForDisplayFromStore, true);
            }
            if (isDefined(this.inputs.symbol)) {
              this.setSelectedSymbol((this.inputs.symbol).toUpperCase(), this.inputs.market);
              this.updateSymbol(this.selectedSymbol);
            }
          }

        }

        if (this.inputs.action) {
          this.isPayFrom = (this.inputs.action !== 'SELL' && this.inputs.action !== 'SHORT');
        }

        this.contentService.fetchContent('buyingpowertooltip').subscribe((data) => {
          this.buyingPowerContent = data;
        }, (err) => {

        });
        if (this.quote) {
          this.query = this.quote.Symbol.SymbolName;
          this.isQuote = true;

          if (isDefined(this.quote.Symbol.SymbolName)) {
            this.setSelectedSymbol((this.quote.Symbol.SymbolName).toUpperCase(), this.quote.Symbol.Market);
          } else {
            this.setSelectedSymbol(null, this.quote.Symbol.Market);
          }
          if (this.inputs.priceMode === 'trailingStopMode' && this.inputs.trailingMode == 'dollar') {
            this.changeTrailingdollarPrice();
          } else if (this.inputs.priceMode === 'trailingStopMode' && this.inputs.trailingMode == 'percent') {
            this.changeTrailingPercentagerPrice();
          }
        }

        this.calcMonthDays(this.inputs.dateMonth);

        this.subscriptions.add(this.contentService.fetchContent('dateselector').subscribe((data) => {
          this.dateSelectorParam.content = data;
        }, (err) => {

        }));

        this.subscriptions.add(this.contentService.fetchContent('symbolquote').subscribe((data) => {
          this.quoteContent = data;
        }, (err) => {

        }));

        if (this.storeArray[this.mode].crossFlow && this.storeArray[this.mode].crossFlow.Symbol) {
          this.updateSymbol(this.storeArray[this.mode].crossFlow.Symbol);
        }

        if (this.inputs.priceMode === undefined || this.inputs.priceMode == null || this.inputs.priceMode == '') {
          this.changePriceMode('marketMode');
        }
      }

      this.storeArray[this.mode].crossFlow = undefined;

      this.updateExtendedHours();
      this.setExpiryCustomDisabled();
      setTimeout(() => {
        this.dataLoaded.emit(true);
        this.initRangeEl(this.inputs.quantity);
        const rangeEl = document.getElementById('myInput');
        if (rangeEl) {
          this.updateRangeEl(rangeEl, this.inputs.quantity);
          if (this.inputs.quantity == 0)
            this.inputs.quantity = 0;
        }
        this.setFocus('closeBtn');
      }, 200);
    }
  }

  defineToolTips() {
    this.priceToolTip.openBtnLabel = '';
    this.priceToolTip.cancel = this.globalContent.text.closeTooltip;
    this.priceToolTip.content = '';
    this.priceToolTip.btnHiddenText = this.quickTradeContent.text.orderPriceLabelText;
    this.priceToolTip.title = this.quickTradeContent.text.price;
    
    this.moreInfoToolTip.openBtnLabel = '';
    this.moreInfoToolTip.cancel = this.globalContent.text.closeTooltip;
    this.moreInfoToolTip.content = '';
    this.moreInfoToolTip.btnHiddenText = this.quickTradeContent.text.moreInformationlabelText;
    this.moreInfoToolTip.title = this.quickTradeContent.text.moreInformation;
    
    this.accountToolTip.openBtnLabel = '';
    this.accountToolTip.cancel = this.globalContent.text.closeTooltip;
    this.accountToolTip.content = '';
    this.accountToolTip.btnHiddenText = this.quickTradeContent.list.accountTooltip.labelText;
    this.accountToolTip.title = this.quickTradeContent.text.accountTooltipTitle;
    
    this.buyToCoverToolTip.openBtnLabel = '';
    this.buyToCoverToolTip.cancel = this.globalContent.text.closeTooltip;
    this.buyToCoverToolTip.content = '';
    this.buyToCoverToolTip.btnHiddenText = this.quickTradeContent.text.buyToCoverLabelText;
    this.buyToCoverToolTip.title = this.globalContent.text.buyToCover;
    
    this.shortSellToolTip.openBtnLabel = '';
    this.shortSellToolTip.cancel = this.globalContent.text.closeTooltip;
    this.shortSellToolTip.content = '';
    this.shortSellToolTip.btnHiddenText = this.quickTradeContent.text.shortSellLabelText;
    this.shortSellToolTip.title = this.globalContent.text.shortSell;
    
    this.buyingPowerToolTip.openBtnLabel = '';
    this.buyingPowerToolTip.cancel = this.globalContent.text.closeTooltip;
    this.buyingPowerToolTip.content = '';
    this.buyingPowerToolTip.btnHiddenText = this.quickTradeContent.list.trailingTooltipList.labelText;
    this.buyingPowerToolTip.title = this.quickTradeContent.list.trailingTooltipList.buyingPowerText;
    
    this.triggerDeltaToolTip.openBtnLabel = '';
    this.triggerDeltaToolTip.cancel = this.globalContent.text.closeTooltip;
    this.triggerDeltaToolTip.content = '';
    this.triggerDeltaToolTip.btnHiddenText = this.quickTradeContent.text.triggerDeltaLabelText;
    this.triggerDeltaToolTip.title = this.quickTradeContent.text.triggerDelta;
    
    this.triggerDeltaPercentToolTip.openBtnLabel = '';
    this.triggerDeltaPercentToolTip.cancel = this.globalContent.text.closeTooltip;
    this.triggerDeltaPercentToolTip.content = '';
    this.triggerDeltaPercentToolTip.btnHiddenText = this.quickTradeContent.text.triggerDeltaLabelText;
    this.triggerDeltaPercentToolTip.title = this.quickTradeContent.text.triggerDeltaPercent;
    
    this.limitOffsetToolTip.openBtnLabel = '';
    this.limitOffsetToolTip.cancel = this.globalContent.text.closeTooltip;
    this.limitOffsetToolTip.content = '';
    this.limitOffsetToolTip.btnHiddenText = this.quickTradeContent.text.limitOffsetLabelText;
    this.limitOffsetToolTip.title = this.quickTradeContent.text.limitOffset;

    this.ehToolTip.openBtnLabel = '';
    this.ehToolTip.cancel = this.globalContent.text.closeTooltip;
    this.ehToolTip.content = '';
    this.ehToolTip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
  }

  handleOptionalResponse(data, type) {
    if (data !== null && data != undefined) {
      if (data.isOptionalError) {
        if (type === 'quick') {
          this.quoteError = data.error.error.Exception.ErrorMessage;
        }
        setTimeout(() => {
          // super.handleError(this.data.error);
        }, 1);
      } else {
        if (type === 'quick') {
          this.quote = data.Quotes[0];
        } else if (type === 'sett') {
          this.settlementAccountList = data.SettlementAccounts;
        } else if (type === 'hold') {
          this.stocksHoldings = data;
          this.stockHoldings = data.Holdings;
        }
      }
    }
  }

  isAccountSelectionReadonly(accountList) {
    // Identify whether account balances available in Init Response. if not available dont show on account picker.
    for (const account of this.accountList) {
      if (isDefined(account.AccountBalances)) {
        this.isBalanceAvailable = true;
        break;
      }
    }
    return accountList.length === 1 || accountList.length === 0;
  }

  clearPage() {
    this.errorQuote = false;
    this.inputs.accountIndex = -1;
    this.selectedAccount = undefined;
    this.settleAmountCurrency = '';
    this.selectedSymbolForStore = undefined;
    this.selectedSymbol = {};
    this.quote = undefined;
    this.query = this.inputs.symbol;
    this.inputs.symbol = '';
    this.selectedSymbolForStore = undefined;
    setTimeout(() => {
      this.query = '';
    }, 10);
    this.stockHoldings = [];
    this.inputs.stockHoldingIndex = -1;
    this.chooseSymbol = false;
    this.inputs.market = 'BUY';
    this.inputs.priceMode = 'marketMode';
    this.inputs.limit = undefined;
    this.inputs.stopPrice = undefined;
    this.inputs.stopLimit = undefined;
    this.inputs.expiryMode = 'day';
    this.settlementAccountList = [];
    this.inputs.settlementIndex = -1;
    this.inputs.quantity = undefined;
    this.inputs.quantityValue = undefined;
    this.isQuote = false;
    this.formError = {};
    this.quoteFromChildComponent = undefined;
    this.hasBuyToCover = false;
    this.setActionArray();
    this.changeActionMode('BUY');
    this.changePriceMode('marketMode');
    // session clear
    this.selectedIndexForSell = undefined;
    this.quotesData = undefined;
    this.persistenceService.removeSessionStoreValue(this.mode == 'quickTrade' ? 'landingStoreQTBig' : 'landingStoreQTSmall');
    this.subscriptionService.setCustomError('RESET');

    
    this.inputs.quantity = undefined;
    this.inputs.quantityValue = undefined;
    this.subscriptionService.setError('RESET');
  }
  taggingClear(){
    // Omniture to track clear button
    this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:details:clear');
  }

  setCarryOverAccount(accountNum) {
    for (let i = 0; i < this.accountList.length; i++) {
      if (this.accountList[i].AccountNumber == accountNum) {
        return i;
      }
    }
    return -1;
  }

  AccountListReturn(account, fromSession?, fromCrossFlow?) {
    this.subscriptionService.setCustomError('RESET');
    this.account = account;
    const index = this.accountList.findIndex(acc => acc.AccountNumber == account.AccountNumber);
    if (index != null) {
      if (this.inputs.accountIndex !== -1 && !fromSession && this.inputs.stockHoldingIndex > -1) {
        this.resetAll();
      }
      this.formError.accountNumber = undefined;
      this.inputs.accountIndex = index;
      this.selectedAccount = account;
      this.settleAmountCurrency = account.Currency;
      if (!this.selectedAccount.AccountType) {
        if (this.inputs.action == 'BUY' || this.inputs.action == 'SELL') {
          for (let i = 0; i < this.accountList.length; i++) {
            let acc = this.accountList[i];
            if (acc.AccountNumber == this.selectedAccount.AccountNumber) {
              for (let j = 0; j < acc.Subaccounts.length; j++) {
                let subAcc = acc.Subaccounts[j];
                if (subAcc.Currency == this.settleAmountCurrency && subAcc.AccountType != 'MRS') {
                  this.selectedAccount.AccountType = subAcc.AccountType;
                  break;
                }
              }
            }
          }
        } else {
          for (let i = 0; i < this.accountList.length; i++) {
            let acc = this.accountList[i];
            if (acc.AccountNumber == this.selectedAccount.AccountNumber) {
              for (let j = 0; j < acc.Subaccounts.length; j++) {
                let subAcc = acc.Subaccounts[j];
                if (subAcc.Currency == this.settleAmountCurrency && subAcc.AccountType == 'MRS') {
                  this.selectedAccount.AccountType = subAcc.AccountType;
                  break;
                }
              }
            }
          }
        }
      }
      this.getBuyingPowerForSelectedAccount(index);
      let accountType = this.getAccountType(account);
      const detectMobileIOS = this.getMobileOperatingSystem();
      //position type
      if (accountType == 'Short' || accountType == 'Découvert') {
        this.hasBuyToCover = true;
        this.setActionArray();
        if (detectMobileIOS === 'iOS') {
          this.setFocus('connectedRadio-QuickTradeActionToggle' + this.uniqueName + '-1');
          this.setIOSDropdownFocus('ddButtonquicktrade' + this.uniqueName);
        }
      } else {
        this.hasBuyToCover = false;
        this.setActionArray();
        if (detectMobileIOS === 'iOS') {
          this.setFocus('connectedRadio-QuickTradeActionToggle' + this.uniqueName + '-1');
          this.setIOSDropdownFocus('ddButtonquicktrade' + this.uniqueName);
        }
      }
      if (!this.hasBuyToCover && (this.inputs.action === 'BUYTOCOVER' || this.inputs.action === 'SHORT')) {
        this.inputs.action = 'BUY';
        this.stockHoldings = [];
      }
      if (this.hasBuyToCover) {
        if (this.inputs.action == 'BUYTOCOVER') {
          this.inputs.action = 'BUYTOCOVER';
        } else {
          this.inputs.action = 'SHORT';
        }
      }
      if (this.inputs.action !== '') {
        const restActionMode = this.inputs.action;
        if (this.inputs.action !== 'BUY' && (fromCrossFlow && this.mode == 'quickTrade')) {
          if (fromCrossFlow) {
            this.getstockholdings(restActionMode, fromSession, fromCrossFlow, this.storeArray[this.mode].crossFlow);
          } else {
            this.getstockholdings(restActionMode, fromSession, fromCrossFlow);
          }
        } else {
          this.getSettlementAccount(restActionMode);
        }
      }
      this.setExpiryCustomDisabled();
    }
    this.updateExtendedHours();
  }

  updateSymbol(symbol) {
    this.selectedSymbolForStore = symbol;
    this.query = '';
    setTimeout(() => {
      this.query = (symbol.SymbolName).toUpperCase();
    }, 10);
    this.updateQuote(symbol);
    if (this.inputs.action !== '' && this.inputs.accountIndex !== -1) {
      this.getSettlementAccount(this.inputs.action);
    }
  }

  getAccountType(account) {
    return this.globalContent.list.accountType[account.AccountType] ? this.globalContent.list.accountType[account.AccountType] : '';
  }

  updateQuote(symbol) {
    this.formError.symbol = undefined;
    this.inputs.market = symbol.Market;
    this.formError.marketMode = undefined;
    this.inputs.symbol = symbol.SymbolName;
    this.quote = undefined;
    if (isDefined(symbol.SymbolName)) {
      this.setSelectedSymbol((symbol.SymbolName).toUpperCase(), symbol.Market);
    } else {
      this.setSelectedSymbol( null, symbol.Market);
    }
    this.refreshQuoteDetails(this.selectedSymbol, true);
    this.updateExtendedHours();
  }

  ChangeSymbolName(name) {
    if (name !== this.inputs.symbol) {
      this.inputs.symbol = name;
      this.inputs.market = '';
      this.formError.marketMode = undefined;
      this.selectedSymbol = undefined;
      this.quote = undefined;
      this.formError.symbol = undefined;
      this.formError.stockHoldingIndex = undefined;
      this.quoteError = undefined;
      this.isQuote = false;
      this.quoteFromChildComponent = undefined;
      this.selectedSymbolForStore = undefined;
      this.quotesData = undefined;
      this.inputs.limit = undefined;
      this.inputs.stopPrice = undefined;
      this.inputs.stopLimit = undefined;
      this.inputs.limitOffsetDollar = undefined;
      this.inputs.triggerPercentagePrice = undefined;
      this.inputs.triggerDollarPrice = undefined;
      this.inputs.quantity = undefined;
      this.inputs.quantityValue = undefined;
      var rangeEl: any = document.getElementById('myInput');
      if (rangeEl) {
        rangeEl.style.backgroundImage = this.getLinearGradientCSS(0, '#dddddd', '#dddddd');
      }
      this.rangeSlider = undefined;
      // if symbol is empty recalculate Trigger Delta values.
      if (this.isEmpty(name)) {
        this.refreshEstimatedTriggerValue();
      }
    }
    this.updateExtendedHours();
  }

  clearManualQuote(e) {
    if (e.keyCode === 9)/*Tab*/ {
      // do nothing
    } else {
      this.formError.symbol = undefined;
      this.isQuote = false;
      this.quote = undefined;
      this.selectedSymbol = undefined;
      this.inputs.market = '';
      this.quoteError = undefined;
    }
  }

  changeActionMode(actionMode, fromHtml?: boolean) {
    // clear data when action changed
    if (fromHtml) {
      this.inputs.symbol = '';
      this.selectedSymbolForStore = undefined;
      this.quoteFromChildComponent = undefined;
      this.quotesData = undefined;
      this.query = '';
      this.inputs.stockHoldingIndex = -1;
      this.selectedIndexForSell = undefined;
      this.inputs.quantity = undefined;
      this.inputs.quantityValue = undefined;
      if (actionMode === 'BUYTOCOVER' || actionMode === 'BUY') {
        this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:details:buy');
      } else {
        this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:details:sell');
      }
    }
    this.formError.actionMode = undefined;
    if (isDefined(this.inputs.action) && this.inputs.action !== '' && this.inputs.stockHoldingIndex > -1) {
      this.resetAll();
    }
    this.inputs.limit = undefined;
    this.inputs.stopPrice = undefined;
    this.inputs.stopLimit = undefined;
    this.inputs.limitOffsetDollar = undefined;
    this.inputs.triggerPercentagePrice = undefined;
    this.inputs.triggerDollarPrice = undefined;
    this.estimatedTriggerPrice = undefined;
    this.estimatedLimitPrice = undefined;
    this.inputs.action = actionMode;
    this.isPayFrom = (actionMode === 'BUYTOCOVER' || actionMode === 'BUY');
    this.setExpiryCustomDisabled();

    if (actionMode != '') {
      if (this.inputs.accountIndex > -1) {
        const restActionMode = actionMode;
        if (actionMode === 'SELL' || actionMode === 'BUYTOCOVER') {
          this.getstockholdings(restActionMode);
        } else {
          this.stockHoldings = [];
          this.symbolLoading = false;
          this.getSettlementAccount(restActionMode);
        }
      }
    }
    if (this.inputs.priceMode === 'trailingStopMode' && this.inputs.trailingMode == 'dollar') {
      this.changeTrailingdollarPrice();
    } else if (this.inputs.priceMode === 'trailingStopMode' && this.inputs.trailingMode == 'percent') {
      this.changeTrailingPercentagerPrice();
    }
    this.errorQuote = false;
    this.formError.symbol = undefined;
  }

  changeQuantity(value) {
    this.formError.quantity = undefined;
    let orderPrice = this.calculateAdjustedOrderPrice(false);
    if (value && orderPrice) {
      let val: any = Math.floor(value * orderPrice * 100) / 100;
      this.inputs.quantityValue = val;
    } else {
      this.inputs.quantityValue = null;
    }
  }

  changeQuantityValue(value) {
    this.formError.quantity = undefined;
    let orderPrice = this.calculateAdjustedOrderPrice(false);
    if (isDefined(value) && isDefined(orderPrice)) {
      let result = Math.floor(value / orderPrice);
      if (result > 9999999) {
        result = 9999999;
      }
      this.inputs.quantity = result;
    } else {
      this.inputs.quantity = null;
    }
  }

  changeLimitInput() {
    this.formError.limitInput = undefined;
    this.changeQuantity(this.inputs.quantity);
    this.calculateLimit();
    this.changeQuantity(this.inputs.quantity);
  }

  changeStopPrice() {
    this.formError.stopPrice = undefined;
    this.calculateStop();
    this.changeQuantity(this.inputs.quantity);
  }

  changeStopLimitPrice() {
    this.formError.stopLimitPrice = undefined;
    this.changeQuantity(this.inputs.quantity);
    this.calculateStopLimit();
    this.changeQuantity(this.inputs.quantity);
  }

  changeTrailingdollarPrice() {
    this.formError.triggerDollarPrice = undefined;
    this.estimatedTriggerPrice = this.calculateTriggerPrice(this.inputs.triggerDollarPrice, this.inputs.trailingMode);
    this.changeLimitOffSetPrice();
    this.calculateTrailingDollarPrice();
    this.changeQuantity(this.inputs.quantity);
  }

  changeTrailingPercentagerPrice() {
    this.formError.triggerPercentagePrice = undefined;
    this.estimatedTriggerPrice = this.calculateTriggerPrice(this.inputs.triggerPercentagePrice, this.inputs.trailingMode);
    this.changeLimitOffSetPrice();
    this.calculatetrailingStopPercentModePrice();
    this.changeQuantity(this.inputs.quantity);
  }

  changeLimitOffSetPrice() {
    this.formError.limitOffsetDollar = undefined;
    this.estimatedLimitPrice = this.calculateLimitOffset(this.inputs.limitOffsetDollar, this.inputs.trailingMode);
    this.calculateTrailingLimitOffset();
    this.changeQuantity(this.inputs.quantity);
  }

  isQuantityAvailable() {
    return (this.inputs.action === 'BUYTOCOVER' || this.inputs.action === 'SELL') &&
      this.chooseSymbol && this.inputs.stockHoldingIndex != -1;
  }

  changePriceMode(priceMode) {
    this.inputs.priceMode = priceMode;
    this.setExpiryCustomDisabled();
    this.formError.priceMode = undefined;
    this.formError.limitInput = undefined;
    this.formError.stopPrice = undefined;
    this.formError.stopLimitPrice = undefined;
    this.formError.triggerDollarPrice = undefined;
    this.formError.triggerPercentagePrice = undefined;
    this.formError.limitOffsetDollar = undefined;
    this.inputs.limit = undefined;
    this.inputs.stopPrice = undefined;
    this.inputs.stopLimit = undefined;
    this.inputs.limitOffsetDollar = undefined;
    this.inputs.triggerPercentagePrice = undefined;
    this.inputs.triggerDollarPrice = undefined;
    //this.inputs.quantity = undefined;
    //this.inputs.quantityValue = undefined;
    this.changeQuantity(this.inputs.quantity);
    this.estimatedTriggerPrice = undefined;
    this.estimatedLimitPrice = undefined;
    var rangeEl: any = document.getElementById('myInput');
    if (rangeEl) {
      rangeEl.style.backgroundImage = this.getLinearGradientCSS(0, '#dddddd', '#dddddd');
    }
    this.rangeSlider = undefined;

    if (priceMode === 'marketMode' || priceMode === '') {
      this.changeExpiryMode('day');
    }
    this.updateExtendedHours();
  }

  changeExpiryMode(expiryMode) {
    if (expiryMode === 'customDate' && this.showCustomDate()) {
      this.inputs.expiryMode = expiryMode;
      this.formError.expiryMode = undefined;
      this.formError.selectedDate = undefined;
    }
    if (expiryMode === 'day' && this.dateParam) {
      this.inputs.dateDay = moment(this.dateParam.DefaultGTDate).date();
      this.inputs.dateMonth = moment(this.dateParam.DefaultGTDate).month();
      this.calcMonthDays(this.inputs.dateMonth);
      this.customDateSelected = this.quickTradeContent.text.customDate;
      this.inputs.expiryMode = expiryMode;
      this.formError.expiryMode = undefined;
      this.formError.selectedDate = undefined;
    }
    this.updateExtendedHours();
  }

  calcMonthDays(month) {
    let minDay = 1;
    let maxDay = this.calcMonthLength(month);
    if (month < moment(this.dateParam.MinGTDate).month()) {
    }

    if (moment(this.dateParam.MinGTDate).month() === month) {
      minDay = moment(this.dateParam.MinGTDate).date();
    }
    if (moment(this.dateParam.MaxGTDate).month() === month) {
      maxDay = moment(this.dateParam.MaxGTDate).date();
    }

    if (this.inputs.dateDay < minDay) {
      setTimeout(() => {
        this.inputs.dateDay = minDay;
      }, 100);
    } else if (this.inputs.dateDay > maxDay) {
      setTimeout(() => {
        this.inputs.dateDay = maxDay;
      }, 100);
    }

    const dayList = this.makeDayObj(minDay, maxDay);
    const parsedDayList = [];

    for (const day of dayList) {
      parsedDayList.push(day);
    }

    if (this.inputs.dateDay > maxDay) {
      setTimeout(() => {
        this.inputs.dateDay = maxDay;
      }, 100);
    }

    this.dayList = parsedDayList;
  }

  calcMonthLength(month) {
    switch (month) {
      case 0:
      case 2:
      case 4:
      case 6:
      case 7:
      case 9:
      case 11:
        return 31;
        break;
      case 3:
      case 5:
      case 8:
      case 10:
        return 30;
        break;
      default:
        let year = moment(this.dateParam.MinGTDate).year();
        if (moment(this.dateParam.MinGTDate).month() > 2) {
          year++;
        }
        if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
          return 29;
        } else {
          return 28;
        }
        break;
    }
  }

  makeDayObj(minDay, maxDay) {
    const obj = [];
    for (let i = minDay; i < maxDay + 1; i++) {
      obj.push({ Day: i });
    }
    return obj;
  }

  setFocus(id) {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.focus();
      }
    }, 200);
  }

  setIOSDropdownFocus(id) {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.focus();
      }
    }, 210);
  }

  resetAll() {
    this.inputs.symbol = '';
    this.inputs.stockHoldingIndex = -1;
    this.query = '';
    this.selectedSymbol = undefined;
    this.quote = undefined;
    setTimeout(() => {
      this.query = '';
    }, 100);
    this.inputs.market = '';
    this.isQuote = false;
    this.formError.symbol = undefined;
    this.formError.stockHoldingIndex = undefined;
    this.quoteError = undefined;
    this.formError.actionMode = undefined;
    this.rangeSlider = undefined;
    this.quoteFromChildComponent = undefined;
    this.selectedIndexForSell = undefined;
    this.updateExtendedHours();
    this.subscriptionService.setCustomError('RESET');
    this.subscriptionService.setError('RESET');
  }

  setDateParams() {
    const minDay = moment(this.dateParam.MinGTDate).date();
    const maxDay = moment(this.dateParam.MaxGTDate).date();
    let defDate = this.calculateDate();
    if (moment(this.dateParam.MinGTDate).month() === moment(defDate).month()) {
      if (moment(defDate).date() < minDay) {
        defDate = this.createDate(moment(this.dateParam.MinGTDate).year(), moment(defDate).month(), minDay);
      }
    }
    if (moment(this.dateParam.MaxGTDate).month() === moment(defDate).month()) {
      if (moment(defDate).date() > maxDay) {
        defDate = this.createDate(moment(this.dateParam.MaxGTDate).year(), moment(defDate).month(), maxDay);
      }
    }

    this.dateSelectorParam.dateParam = {
      MinGTDate: this.dateParam.MinGTDate,
      MaxGTDate: this.dateParam.MaxGTDate,
      DefaultGTDate: defDate
    };
  }

  createDate(year, month, day) {
    if (month < 9) {
      if (day < 10) {
        return year.toString() + '0' + (month + 1).toString() + '0' + day.toString();
      } else {
        return year.toString() + '0' + (month + 1).toString() + day.toString();
      }
    } else {
      if (day < 10) {
        return year.toString() + (month + 1).toString() + '0' + day.toString();
      } else {
        return year.toString() + (month + 1).toString() + day.toString();
      }
    }
  }

  getSettlementAccount(restActionMode) {
    const request: any = {
      AccountNumber: this.accountList[this.inputs.accountIndex].AccountNumber,
      Action: restActionMode, Type: 'STOCKS'
    };
    if (this.chooseSymbol && this.stockHoldings.length > 0 && this.inputs.stockHoldingIndex > -1) {
      request.AccountCurrency = this.stockHoldings[this.inputs.stockHoldingIndex].AccountCurrency;
    }
    this.spinnerService.setFocusElement(undefined);
    this.subscriptions.add(this.service.getSettlementAccounts(request).subscribe(
      (data: any) => {
        if (data) {
          this.settlementAccountListData = data;
          this.settlementAccountList = data.SettlementAccounts;
          if (this.settlementAccountList === undefined) {
            this.settlementAccountList = [];
          }
          if (this.settlementAccountList.length === 1) {
            this.inputs.settlementIndex = 0;
            this.updateSelectedPaymentAccount(0);
          }
          if (this.settlementAccountList.length > 1) {
            this.inputs.settlementIndex = this.settlementAccountList.findIndex(setAcc => setAcc.Currency == this.settleAmountCurrency);
          }

        }
      },
      (err) => { })
    );


  }

  getstockholdings(restActionMode, fromSession?, fromCrossFlow?, crossFlow?) {
    const m = this.inputs.action;
    this.stockHoldings = [];
    this.symbolLoading = true;
    const request = { AccountNumber: this.accountList[this.inputs.accountIndex].AccountNumber, Action: restActionMode };
    this.spinnerService.setFocusElement(undefined);
    this.subscriptions.add(this.service.getAccountHoldings(request).subscribe(
      (data: any) => {
        if (data) {
          this.stocksHoldings = data;
          if (this.inputs.action === m) {
            this.symbolLoading = false;
            this.stockHoldings = data.Holdings;
            if (this.stockHoldings === undefined) {
              this.stockHoldings = [];
            }
            this.inputs.stockHoldingIndex = -1;
            if (this.stockHoldings.length > 0) {
              this.resetAll();
              //filter on settlement account currency
              this.stockHoldings = this.stockHoldings.filter(ho => ho.AccountCurrency == this.settleAmountCurrency);

              if (this.stockHoldings.length === 1 && this.inputs.symbol === '') {
                this.inputs.stockHoldingIndex = 0;
                this.updateQuote(this.stockHoldings[0].Symbol);
                this.chooseSymbol = true;
              }
              else if (this.stockHoldings.length === 0) {
                this.chooseSymbol = false;
              }
              else {
                this.chooseSymbol = true;
              }
            } else {
              this.chooseSymbol = false;
            }
            this.getSettlementAccount(restActionMode);
            if (fromSession) {
              if (!fromCrossFlow) {
                const selectedIndexForSellFromStore = this.storeArray[this.mode].inFlow?.selectedIndexForSell;
                if (selectedIndexForSellFromStore !== undefined && selectedIndexForSellFromStore !== null &&
                  selectedIndexForSellFromStore !== -1) {
                  if (this.stockHoldings.length !== 1) {
                    this.inputs.stockHoldingIndex = parseInt(selectedIndexForSellFromStore);
                    this.updateSelectedHolding(selectedIndexForSellFromStore, fromSession);
                  }
                }
              } else {
                let quant = crossFlow.quantity;
                if (isDefined(quant)) {
                  quant = quant.replace(/[^\d]/g, '');
                }
                this.inputs.stockHoldingIndex = _.findIndex(this.stockHoldings, (o: any) => {
                  return (o.Symbol.SymbolName === crossFlow.symbol) &&
                    o.Symbol.Market === crossFlow.market &&
                    o.Quantity.Data === quant;
                });
                if (this.inputs.stockHoldingIndex > -1) {
                  this.inputs.market = this.stockHoldings[this.inputs.stockHoldingIndex].Symbol.Market;
                } else {
                }
                this.chooseSymbol = this.inputs.stockHoldingIndex > -1;
                if (!this.chooseSymbol) {
                  this.inputs.symbol = crossFlow.symbol;
                  this.inputs.market = crossFlow.market;
                  this.setSelectedSymbol(this.inputs.symbol, this.inputs.market);
                  setTimeout(() => {
                    this.query = this.inputs.symbol.toUpperCase();
                  }, 100);
                  this.updateQuote(this.selectedSymbol);
                }
                this.updateSelectedHolding(this.inputs.stockHoldingIndex);
              }
            }
          }
        } else {
          this.symbolLoading = false;
          this.chooseSymbol = false;
          this.getSettlementAccount(restActionMode);
        }
      },
      (err) => {
        this.getSettlementAccount(restActionMode);
        this.symbolLoading = false;
        this.chooseSymbol = false;
      })
    );
  }

  //change selected security
  updateSelectedHolding(index, fromSession?) {
    // cant find security : last option selected from list
    if (index == this.stockHoldings.length) {
      this.toggleHoldingChoose();
      return;
    }
    if (index !== -1 && index !== null && index !== undefined) {
      this.inputs.stockHoldingIndex = index;
      this.selectedIndexForSell = index;
      this.formError.symbol = undefined;
      this.formError.stockHoldingIndex = undefined;
      this.quoteError = undefined;
      // set to undefined when not from session storage
      if (!fromSession) {
        this.inputs.quantity = undefined;
        this.inputs.quantityValue = undefined;
        this.inputs.limit = undefined;
        this.inputs.stopPrice = undefined;
        this.inputs.stopLimit = undefined;
        this.inputs.limitOffsetDollar = undefined;
        this.inputs.triggerPercentagePrice = undefined;
        this.inputs.triggerDollarPrice = undefined;
      }
      var rangeEl: any = document.getElementById('myInput');
      if (rangeEl) {
        rangeEl.style.backgroundImage = this.getLinearGradientCSS(0, '#dddddd', '#dddddd');
      }
      this.rangeSlider = undefined;
      if (index > -1) {
        const symbol = this.stockHoldings[index].Symbol;
        const restActionMode = this.inputs.action;
        this.getSettlementAccount(restActionMode);
        this.updateQuote(symbol);
      } else {
        this.selectedSymbol = undefined;
        this.quote = undefined;
        this.inputs.market = '';
        this.formError.marketMode = undefined;
        this.refreshEstimatedTriggerValue();
      }
    }
    this.updateExtendedHours();
  }

  toggleHoldingChoose() {
    this.chooseSymbol = !this.chooseSymbol;
    this.isQuote = false;
    this.query = '';
    this.inputs.stockHoldingIndex = -1;
    this.formError.stockHoldingIndex = undefined;
    this.quoteError = undefined;
    this.selectedSymbol = undefined;
    this.quote = undefined;
    this.quoteFromChildComponent = undefined;
    this.inputs.symbol = '';
    this.formError.symbol = undefined;
    this.inputs.market = '';
    this.formError.marketMode = undefined;
    this.inputs.quantity = undefined;
    this.inputs.quantityValue = undefined;
    this.inputs.priceMode = 'marketMode';
    this.inputs.limit = undefined;
    this.inputs.stopPrice = undefined;
    this.inputs.stopLimit = undefined;
    this.inputs.expiryMode = 'day';
    this.inputs.dateDay = moment(this.dateParam.DefaultGTDate).date();
    this.inputs.dateMonth = moment(this.dateParam.DefaultGTDate).month();
    this.inputs.settlementIndex = -1;

    if (this.chooseSymbol) {
      if (this.stockHoldings.length === 1) {
        this.inputs.stockHoldingIndex = 0;
        const symbol = this.stockHoldings[0].Symbol;
        this.updateQuote(symbol);
        this.getSettlementAccount(this.inputs.action);
      }
    } else {
      this.getSettlementAccount(this.inputs.action);
    }

    setTimeout(() => {
      if (this.chooseSymbol) {
        document.getElementById('ddButtonstockHoldingDropdown-qt' + this.uniqueName)?.focus();
      } else {
        document.getElementById('stockHoldingInput-qt' + this.uniqueName)?.focus();
      }
    }, 50);
  }

  recieveSymbolError(data) {
    if (isDefined(data)) {
      if (isDefined(data.Exception)) {
        if (isDefined(data.Exception[0])) {
          this.formError.symbol = { message: data.Exception[0].ErrorMessage };
        } else {
          this.formError.symbol = { message: data.Exception.ErrorMessage };
        }
      } else {
        this.formError.symbol = { message: data.ErrorMessage };
      }
    } else if (data === undefined) {
    }
    this.isQuote = false;
    this.quoteFromChildComponent = undefined;
    this.refreshEstimatedTriggerValue();
  }

  getQuote(data) {
    this.quoteFromChildComponent = data;
    this.refreshEstimatedTriggerValue();
    if (this.mode == 'quickTrade') {
      this.subscriptionService.updateRefreshTime({ channel: 'quickTrade', count: 1 });
    } else {
      this.subscriptionService.updateRefreshTime({ channel: 'header', count: 1 });
    }
  }

  refreshQuoteDetails(sym, scrollToQuote: boolean = false) {
    // previously it was getQuoteDetails
    this.spinnerService.setFocusElement(undefined);
    let buttons = document.getElementsByClassName('new-quote-refresh-button-qt');
    let button;
    if (buttons && buttons.length) {
      button = buttons[0];
    }
    if (button) {
      button.className = 'new-quote-refresh-button-qt icon-Reload2';
      requestAnimationFrame(() => {
        button.className = 'new-quote-refresh-button-qt icon-Reload2 refresh-animate';
      });
      // Omiture tag to track refresh button
      this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:details:quote-refresh');
    }
    this.loadingQuote = true;
    // get quote details
    this.subscriptions.add(this.quoteService.getQuote({ Symbols: [sym], OrderType: this.inputs.action }).subscribe((response: any) => {
      this.quotesData = response;
      this.getQuote(response.Quotes[0]);
      this.isQuote = true;
      this.errorQuote = false;
      this.loadingQuote = false;
      this.initRangeEl(this.inputs.quantity);
      if (scrollToQuote) {
        setTimeout(() => {
          if (!this.isDesktop()) {
            let n: any = document.getElementById('quick-trade-scroll-pos-1')
            let i = n.offsetTop;
            while (document.scrollingElement != n && n.offsetParent) {
              n = n.offsetParent;
              i += n.offsetTop;
            }
            document.scrollingElement.scrollTo({ top: i - 32 });
          }
        }, 100);
      }
    }, (err) => {
      this.loadingQuote = false;
      this.errorQuote = true;
      if (err.error.Exception.ErrorCode == 'UIBUSBR0010') {
        this.agreementError = true;
      }
      if (this.mode == 'quickTrade') {
        this.subscriptionService.updateRefreshTime({ channel: 'quickTrade', count: 1 });
      } else {
        this.subscriptionService.updateRefreshTime({ channel: 'header', count: 1 });
      }
    }));
  }

  refreshEstimatedTriggerValue() {
    const priceMode = this.inputs.trailingMode;
    if (priceMode === 'dollar') {
      this.estimatedTriggerPrice = this.calculateTriggerPrice(this.inputs.triggerDollarPrice, priceMode);
      this.estimatedLimitPrice = this.calculateLimitOffset(this.inputs.limitOffsetDollar, priceMode);
    } else if (priceMode === 'percent') {
      this.estimatedTriggerPrice = this.calculateTriggerPrice(this.inputs.triggerPercentagePrice, priceMode);
      this.estimatedLimitPrice = this.calculateLimitOffset(this.inputs.limitOffsetDollar, priceMode);
    }
  }

  updateSelectedPaymentAccount(settlementIndex) {
    this.formError.settlementIndex = undefined;
  }

  resetAllFormErrors() {
    this.formError = {};
  }

  // submit form values
  nextdr(value: any) {
    QuickTradeStockValidator.setValidators(this.quickTradeStockForm,
      {
        chooseSymbol: this.chooseSymbol, estimatedLimitPrice: this.estimatedLimitPrice, estimatedTriggerPrice:
          this.estimatedTriggerPrice
      }, this.appStore.lang.toLowerCase() == 'fr', this.inputs.trailingMode);
    QuickTradeStockValidator.validate(this.quickTradeStockForm);
    const reqresp = {};
    if (this.quickTradeStockForm.valid) {
      this.resetAllFormErrors();
      const restActionMode = this.inputs.action;
      const settlement = {
        SettlementType: this.settlementAccountList[this.inputs.settlementIndex].SettlementType,
        Currency: this.settlementAccountList[this.inputs.settlementIndex].Currency
      };
      let expiryVal = {};
      if (this.inputs.expiryMode === 'customDate') {
        expiryVal = { ExpiryType: 'CUSTOM', ExpiryDate: this.calculateDate() };
      } else {
        if (this.inputs.expiryMode === 'day' && this.inputs.extendedHours) {
          expiryVal = { ExpiryType: 'DAY_PLUS' };
        } else {
          expiryVal = { ExpiryType: 'DAY' };
        }
      }
      let priceval = {};
      if (this.inputs.priceMode === 'limitMode') {
        priceval = { Type: 'LIMIT', LimitAmount: this.inputs.limit };
      } else if (this.inputs.priceMode === 'stopMode') {
        priceval = { Type: 'STOP', Amount: this.inputs.stopPrice, LimitAmount: this.inputs.stopLimit };
      } else if (this.inputs.priceMode === 'trailingStopMode' && this.inputs.trailingMode == 'dollar') {
        if (isNaN(this.inputs.triggerDollarPrice)) {
          priceval = {
            Type: 'TRAILINGSTOPLMTDOLLAR',
            TrailingDelta: this.inputs.triggerDollarPrice.toString().replace(',', '.'),
            LimitOffset: this.inputs.limitOffsetDollar.toString().replace(',', '.'),
            TriggerPrice: isNaN(this.estimatedTriggerPrice) ? undefined : this.estimatedTriggerPrice,
            EstLimitPrice: isNaN(this.estimatedLimitPrice) ? undefined : this.estimatedLimitPrice,
          };
        } else {
          priceval = {
            Type: 'TRAILINGSTOPLMTDOLLAR',
            TrailingDelta: this.inputs.triggerDollarPrice,
            LimitOffset: this.inputs.limitOffsetDollar,
            TriggerPrice: isNaN(this.estimatedTriggerPrice) ? undefined : this.estimatedTriggerPrice,
            EstLimitPrice: isNaN(this.estimatedLimitPrice) ? undefined : this.estimatedLimitPrice,
          };
        }
      } else if (this.inputs.priceMode === 'trailingStopMode' && this.inputs.trailingMode == 'percent') {
        if (isNaN(this.inputs.triggerPercentagePrice)) {
          priceval = {
            Type: 'TRAILINGSTOPLMTPERCENTAGE',
            TrailingDelta: this.inputs.triggerPercentagePrice.toString().replace(',', '.'),
            LimitOffset: this.inputs.limitOffsetDollar.toString().replace(',', '.'),
            TriggerPrice: isNaN(this.estimatedTriggerPrice) ? undefined : this.estimatedTriggerPrice,
            EstLimitPrice: isNaN(this.estimatedLimitPrice) ? undefined : this.estimatedLimitPrice,
          };
        } else {
          priceval = {
            Type: 'TRAILINGSTOPLMTPERCENTAGE',
            TrailingDelta: this.inputs.triggerPercentagePrice,
            LimitOffset: this.inputs.limitOffsetDollar,
            TriggerPrice: isNaN(this.estimatedTriggerPrice) ? undefined : this.estimatedTriggerPrice,
            EstLimitPrice: isNaN(this.estimatedLimitPrice) ? undefined : this.estimatedLimitPrice,
          };
        }
      } else {
        priceval = { Type: 'MKT' };
      }
      const params = {
        Action: restActionMode, AccountNumber: this.accountList[this.inputs.accountIndex].AccountNumber,
        Symbol: this.selectedSymbol,
        Quantity: this.inputs.quantity, Price: priceval,
        Expiry: expiryVal,
        SettlementAccount: settlement
      };
      this.verifyStoreArray[this.mode].params = params;

      this.storeArray[this.mode].savedInfo = {
        inputs: JSON.parse(JSON.stringify(this.inputs)),
        accountNumber: this.accountList[this.inputs.accountIndex].AccountNumber,
        selectedAccount: this.selectedAccount
      };
      if (this.chooseSymbol && this.stockHoldings.length > 0 && this.inputs.stockHoldingIndex > -1) {
        this.storeArray[this.mode].savedInfo.accountCurrency = this.stockHoldings[this.inputs.stockHoldingIndex].AccountCurrency;
        this.storeArray[this.mode].savedInfo.Quantity = this.stockHoldings[this.inputs.stockHoldingIndex].Quantity;
      }
      this.verifyStoreArray[this.mode].inFlow = {};
      if (this.accountList[this.inputs.accountIndex].BuyingPowerValueType == 'COMBINED') {
        if (this.accountList[this.inputs.accountIndex].ConsolidatedBuyingPower) {
          this.verifyStoreArray[this.mode].inFlow.ConsolidatedBuyingPower = this.currencyFormatterPipe.
            transform(this.accountList[this.inputs.accountIndex].ConsolidatedBuyingPower, 'CAD', 'format-default');
        }
        this.verifyStoreArray[this.mode].inFlow.CombinedBuyingPower = true;
        this.verifyStoreArray[this.mode].inFlow.TimeStamp = this.accountList[this.inputs.accountIndex].TimeStamp;
      }
      if (this.mode == "quickTrade") {
        const req: any = this.verifyStoreArray[this.mode].params;
        this.spinnerService.setFocusElement(undefined);
        this.subscriptions.add(this.httpService.post(gatewayConfig.APIServices.stocksVerify.url, req).subscribe((data: any) => {
          this.verifyStoreArray[this.mode].inFlow.Data = data;
          this.verifyStoreArray[this.mode].crossFlow = 'Verify';
          const sessionStore = { params: '', inFlow: '', crossFlow: '' };
          sessionStore.params = this.verifyStoreArray[this.mode].params;
          sessionStore.inFlow = this.verifyStoreArray[this.mode].inFlow;
          sessionStore.crossFlow = this.verifyStoreArray[this.mode].crossFlow;
          this.persistenceService.sessionStorePersist(this.mode == 'quickTrade' ? 'verifyStoreQTBig' : 'verifyStoreQTSmall', JSON.stringify(sessionStore));
          this.subscriptionService.setTradeDrawerPosition(2);
        }, (err) => {
          this.errorHandler.handleError(err);
          //this.subscriptionService.setCustomError(err.error.Exception);
          setTimeout(() => {
            this.subscriptionService.enableButton('validation');
          }, 200);
        })
        );
        this.formSubmitted = true;
      } else {
        this.storeArray[this.mode].savedInfo = {
          inputs: JSON.parse(JSON.stringify(this.inputs)),
          accountNumber: this.accountList[this.inputs.accountIndex].AccountNumber,
          selectedAccount: this.selectedAccount
        };
        if (this.chooseSymbol && this.stockHoldings.length > 0 && this.inputs.stockHoldingIndex > -1) {
          this.storeArray[this.mode].savedInfo.accountCurrency = this.stockHoldings[this.inputs.stockHoldingIndex].AccountCurrency;
          this.storeArray[this.mode].savedInfo.Quantity = this.stockHoldings[this.inputs.stockHoldingIndex].Quantity;
        }
        this.verifyStoreArray[this.mode].inFlow = {};
        if (this.accountList[this.inputs.accountIndex].BuyingPowerValueType == "COMBINED") {
          if (this.accountList[this.inputs.accountIndex].ConsolidatedBuyingPower) {
            this.verifyStoreArray[this.mode].inFlow.ConsolidatedBuyingPower = this.currencyFormatterPipe.transform(this.accountList[this.inputs.accountIndex].ConsolidatedBuyingPower, 'CAD', 'format-default');
          }
          this.verifyStoreArray[this.mode].inFlow.CombinedBuyingPower = true;
          this.verifyStoreArray[this.mode].inFlow.TimeStamp = this.accountList[this.inputs.accountIndex].TimeStamp;
        }
        this.submit.emit();
      }
    } else {
      setTimeout(() => {
        this.subscriptionService.enableButton('validation');
      }, 200);
      this.formError = QuickTradeStockValidator.resolveValidationError(this.quickTradeStockForm, this.quickTradeContent.error);
      QuickTradeStockValidator.emptyValidators(this.quickTradeStockForm, this.inputs.trailingMode);
      if (this.formError.accountNumber) {
        setTimeout(() => {
          this.setFocus('ddButtonquicktrade' + this.uniqueName);
        }, 200);
      }
    }
  }

  logError(err) {
    let tempOptions = JSON.parse(JSON.stringify(err));
    tempOptions["platform"] = "quickTrade";
    this.commonService.logBraze("viewed_trade_stock_details_error", tempOptions);
  }

  handleRunTimeErrors(err) {
    this.formError = {};
    err.forEach((element) => {
      if (element.Category === 'Price.Amount') {
        this.formError.limitInput = { message: element.Errors[0].ErrorMessage };
        this.formError.stopPrice = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'Price.Type') {
        this.formError.priceMode = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'Price.LimitAmount') {
        this.formError.stopLimitPrice = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'Quantity') {
        this.formError.quantity = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'Action') {
        this.formError.actionMode = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'Symbol') {
        this.formError.symbol = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'Expiry') {
        this.formError.selectedDate = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'AccountNumber') {
        this.formError.accountNumber = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'SettlementAccount') {
        this.formError.settlementIndex = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'Price.TrailingDelta' && this.inputs.priceMode === 'trailingStopMode' && this.inputs.trailingMode == 'dollar') {
        this.formError.triggerDollarPrice = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'Price.TrailingDelta' && this.inputs.priceMode === 'trailingStopMode' && this.inputs.trailingMode == 'percent') {
        this.formError.triggerPercentagePrice = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'Price.LimitOffset') {
        this.formError.limitOffsetDollar = { message: element.Errors[0].ErrorMessage };
      }
    });
  }

  handleTrailingStopToggleButton(val: string) {
    this.inputs.trailingMode = val;
    this.changePriceMode('trailingStopMode');
  }

  getBuyingPowerForSelectedAccount(index) {
    this.getBalanceReq(index);
    this.idx = index;
    this.buyingPowerLoading = true;
    if (index != -1) {
      const account = this.accountList[index];
      if (isDefined(account.BuyingPowerValueType)) {
        let payload = {};
        payload = { AccountNumber: account.AccountNumber };
        this.spinnerService.setFocusElement(undefined);
        this.subscriptions.add(this.service.getBuyingPower(payload).subscribe(
          (data: any) => {
            if (data) {
              if (data.BuyingPowerBalances[0].ConsolidatedBuyingPower) {
                account.ConsolidatedBuyingPower = data.BuyingPowerBalances[0].ConsolidatedBuyingPower;
              } else {
                account.BuyingPowerCAD = data.BuyingPowerBalances[0].BuyingPowerCAD;
                account.BuyingPowerUSD = data.BuyingPowerBalances[0].BuyingPowerUSD;
              }
              account.TimeStamp = data.BuyingPowerBalances[0].TimeStamp;
              account.MarginAccount = data.BuyingPowerBalances[0].MarginAccount;
              this.buyingPowerLoading = false;
              if (data.BuyingPowerBalances[0].Commission) {
                this.commission = data.BuyingPowerBalances[0].Commission;
              }
              else {
                this.commission = 0;
              }
            }
            this.initRangeEl(this.inputs.quantity);
          },
          (err) => {
            this.buyingPowerLoading = false;
          })
        );
      }
    }
  }

  getBalanceReq(index) {
    this.idx = index;
    this.balanceLoading = true;
    if (index != -1) {
      const account = this.accountList[index];
      let payload = {};
      payload = { AccountNumber: account.AccountNumber };
      this.spinnerService.setFocusElement(undefined);
      this.subscriptions.add(this.service.getBalanceReq(payload).subscribe(
        (data: any) => {
          this.balanceLoading = false;
          if (data.AccountType) {
            account.isRegistered = data.AccountType == 'REG' ? true : false;
          }
          if (data && data.AccountBalances && data.AccountBalances.length) {
            if (data.AccountBalances[0].Amount) {
              if (this.settleAmountCurrency == 'CAD') {
                account.accountBalanceAmount = data.AccountBalances[0].Amount;
              } else {
                account.accountBalanceAmount = data.AccountBalances[1].Amount;
              }
              this.initRangeEl(this.inputs.quantity);
            }
          }
        },
        (err) => {
          this.balanceLoading = false;
        })
      );
    }
  }

  removeCommas(numericString: string, type?) {
    //used in formatNumber()
    if (!numericString || isNaN(parseFloat(numericString)) || numericString == '0')
      return '0';
    var newString: string = '';
    for (let i = 0; i < numericString.length; i++) {
      if (numericString.substring(i, i + 1) != ',') {
        newString += numericString.substring(i, i + 1);
      }
    }
    return newString;
  }

  removeCommasFR(numericString: string, type?) {
    //used in formatNumber()
    if (!numericString || isNaN(parseFloat(numericString)) || numericString == '0')
      return '0';
    var newString: string = '';
    newString = numericString.replace(/\s+/g, '');
    newString = newString.replace(',', '.');
    return newString;
  }

  isObjectEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  isNotNull(value) {
    if ((value === undefined) || value === null || value === '') {
      return false;
    }
    return true;
  }

  isEmpty(value) {
    if ((value === undefined) || value === null || value === '') {
      return true;
    }
    return false;
  }

  roundNumber(value, precision) {
    if (value != null) {
      const multiplier = Math.pow(10, precision || 0);
      return parseFloat((Math.round(value * multiplier) / multiplier).toFixed(precision));
    } else {
      return value;
    }
  }

  calculateTriggerPrice(inputValue, mode) {
    // calculate estimate Trigger Price and Limit Off Set
    if (this.isNotNull(inputValue)) {
      let quote = this.quoteFromChildComponent;
      if ((quote === undefined) && this.isQuote) {
        quote = this.quote;
      }
      let marketPrice;
      const actionMode = this.inputs.action;
      const symbol = this.selectedSymbol;

      if (!(actionMode === '')) {
        if (symbol != null && !this.isObjectEmpty(symbol)) {
          if (this.isNotNull(quote) && this.isNotNull(quote.LastPrice)) {
            marketPrice = parseFloat(quote.LastPrice.Data);
            if (isDefined(this.quickTradeStockForm)) {
              QuickTradeStockValidator.setTrailingStopValidators(this.quickTradeStockForm,
                this.inputs.trailingMode, this.appStore.lang.toLowerCase() == 'fr');
              if (isDefined(this.quickTradeStockForm.controls.triggerDollarPrice)) {
                this.quickTradeStockForm.controls.triggerDollarPrice.updateValueAndValidity();
              }
              if (isDefined(this.quickTradeStockForm.controls.triggerPercentagePrice)) {
                this.quickTradeStockForm.controls.triggerPercentagePrice.updateValueAndValidity();
              }

              if (!((isDefined(this.quickTradeStockForm.controls.triggerDollarPrice) &&
                this.quickTradeStockForm.controls.triggerDollarPrice.valid && mode === 'dollar') ||
                (isDefined(this.quickTradeStockForm.controls.triggerPercentagePrice) &&
                  this.quickTradeStockForm.controls.triggerPercentagePrice.valid && mode === 'percent'))) {

                this.estimatedTriggerPrice = undefined;
                this.estimatedLimitPrice = undefined;
                return;
              }
            }
            if (actionMode === 'BUY' || actionMode === 'BUYTOCOVER') {
              if (mode === 'dollar') {
                this.estimatedTriggerPrice = (marketPrice + parseFloat(inputValue.toString().replace(',', '.')));
              } else {
                const inputValuePercentage = (this.appStore.lang == 'fr') ? inputValue.toString().replace(',', '.') : inputValue;
                this.estimatedTriggerPrice = (marketPrice + ((marketPrice * inputValuePercentage) / 100));
              }
            } else if (actionMode === 'SELL' || actionMode === 'SHORT') {
              if (mode === 'dollar') {
                this.estimatedTriggerPrice = (marketPrice - parseFloat(inputValue.toString().replace(',', '.')));
              } else {
                const inputValuePercentage = (this.appStore.lang == 'fr') ? inputValue.toString().replace(',', '.') : inputValue;
                this.estimatedTriggerPrice = (marketPrice - ((marketPrice * inputValuePercentage) / 100));
              }
            }
            return this.roundNumber(this.estimatedTriggerPrice, 4);
          }
          return this.quickTradeContent.text.QNA;
        }
        return this.quickTradeContent.text.SAS;
      }
      return this.quickTradeContent.text.SAA;
    }
  }

  calculateLimitOffset(limitOffset, mode) {
    if (this.isNotNull(limitOffset) && this.isNotNull(this.estimatedTriggerPrice)) {
      let quote = this.quoteFromChildComponent;
      // if Quote is undefined and get it from pre Quote, In case of back button or step 2 to step1 ,
      // get the quote info from preQuote object.
      if ((quote === undefined) && this.isQuote) {
        quote = this.quote;
      }
      const actionMode = this.inputs.action;
      const symbol = this.selectedSymbol;
      if (!(actionMode === '')) {
        if (symbol != null && !this.isObjectEmpty(symbol)) {
          if (this.isNotNull(quote) && this.isNotNull(quote.LastPrice)) {
            if ((mode === 'dollar' && this.isNotNull(this.inputs.triggerDollarPrice))
              || (mode === 'percent' && this.isNotNull(this.inputs.triggerPercentagePrice))) {
              const triggerPrice = this.estimatedTriggerPrice;
              // Limit Off Set Validation
              if (isDefined(this.quickTradeStockForm)) {
                QuickTradeStockValidator.setLimitOffSetValidators(this.quickTradeStockForm,
                  this.inputs.trailingMode, this.appStore.lang.toLowerCase() == 'fr');
                this.quickTradeStockForm.controls.limitOffsetDollar.updateValueAndValidity();

                if (!(this.quickTradeStockForm.controls.limitOffsetDollar.valid)) {
                  return;
                }
              }
              if (actionMode === 'BUY' || actionMode === 'BUYTOCOVER') {
                this.estimatedLimitPrice = (triggerPrice + parseFloat(limitOffset.toString().replace(',', '.')));
              } else if (actionMode === 'SELL' || actionMode === 'SHORT') {
                this.estimatedLimitPrice = (triggerPrice - parseFloat(limitOffset.toString().replace(',', '.')));
              }
              return this.roundNumber(this.estimatedLimitPrice, 4);
            }
            return this.quickTradeContent.text.ETD;
          }
          return this.quickTradeContent.text.QNA;
        }
        return this.quickTradeContent.text.SAS;
      }
      return this.quickTradeContent.text.SAA;
    } else if (this.isNotNull(limitOffset)) {
      QuickTradeStockValidator.setLimitOffSetValidators(this.quickTradeStockForm, this.inputs.trailingMode,
        this.appStore.lang.toLowerCase() == 'fr');
      this.quickTradeStockForm.controls.limitOffsetDollar.updateValueAndValidity();

      if (!(this.quickTradeStockForm.controls.limitOffsetDollar.valid)) {
        return;
      }
      // If Trigger Delta missing return error message.
      if ((mode === 'dollar' && this.isEmpty(this.inputs.triggerDollarPrice))
        || (mode === 'percent' && this.isEmpty(this.inputs.triggerPercentagePrice))) {
        return this.quickTradeContent.text.ETD;
      }
    }
  }

  showCustomDate() {
    return this.inputs.action !== 'SHORT' && (
      this.inputs.priceMode === 'stopMode' || this.inputs.priceMode === 'limitMode'
      || this.inputs.priceMode === 'trailingStopMode'
    );
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }

  calculateDate() {
    let year = this.dateParam.MinGTDate.slice(0, 4);
    const month = this.dateParam.MinGTDate.slice(4, 6);
    const day = this.dateParam.MinGTDate.slice(6, 8);
    if (this.inputs.dateMonth < parseInt(month) - 1 ||
      (this.inputs.dateMonth === parseInt(month) - 1 && this.inputs.dateDay < parseInt(day))
    ) {
      year = (parseInt(year) + 1).toString();
    }

    if (this.inputs.dateMonth < 9) {
      if (this.inputs.dateDay < 10) {
        return year + '0' + (this.inputs.dateMonth + 1) + '0' + this.inputs.dateDay;
      } else {
        return year + '0' + (this.inputs.dateMonth + 1) + this.inputs.dateDay;
      }
    } else {
      if (this.inputs.dateDay < 10) {
        return year + (this.inputs.dateMonth + 1) + '0' + this.inputs.dateDay;
      } else {
        return year + (this.inputs.dateMonth + 1) + this.inputs.dateDay;
      }
    }
  }

  test(): undefined {
    return undefined;
  }

  setSelectedDate(value) {
    this.inputs.dateDay = moment(value).date();
    this.inputs.dateMonth = moment(value).month();
    this.calcMonthDays(this.inputs.dateMonth);
    this.setDateParams();
  }

  IRPriceTooltipLink() {
    this.subscriptions.add(this._helpService.setQuestion(this.quickTradeContent.list.trailingTooltipList.learnMoreHelpQuestion)
      .subscribe((data) => {
        // nothing to do - no response - as it saves the question before going to helpPage
      }, (err) => {
        throw err;
      })
    );
    if (!this.appStore.isApp()) {
      this.commonService.openHelpWindow();
    }
  }

  isNum(a) {
    return isNumber(a) || isNumber(+a) || a == undefined;
  }

  openWindow(url) {
    if (url) {
      openWindow(url, this.appStore);
    }
  }

  openHelp() {
    this.subscriptions.add(this._helpService.setQuestion(this.quickTradeContent.text.shortSellQuestion).subscribe((data) => {
    }, (err) => {
      throw err;
    })
    );
    if (!this.appStore.isApp()) {
      this.commonService.openHelpWindow();
    }
  }

  sliderChanged(event) {
    // Omiture tag to track slider
    this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:details:quantity-slider');
  }

  addCommas(text) {
    if (text.length >= 3 && text != this.dash) {
      if (this.appStore.lang.toLowerCase() == 'en') {
        return text.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        return text.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      }
    } else {
      return text;
    }
  }

  valueOrDash(value) {
    if (isDefined(value)) {
      return value;
    }
    return this.dash;
  }

  valueOrDashContent(value) {
    if (isDefined(value)) {
      return value.Content;
    }
    return this.dash;
  }

  valueOrDashLots(value, lotsValue) {
    if (isDefined(value)) {
      if (isDefined(lotsValue)) {
        return (parseFloat(value.Data) * parseFloat(lotsValue.Data)).toString();
      }
      return value.Data;
    }
    return this.dash;
  }

  valueOrDashData(value) {
    if (isDefined(value)) {
      return value.Data;
    }
    return this.dash;
  }

  valueOrDashDataLang(value) {
    if (isDefined(value)) {
      if (this.appStore.lang.toLowerCase() == 'fr') {
        return value.Data.replace(/\./g, ",");
      }
      return value.Data;
    }
    return this.dash;
  }
  valueOrZero(value) {
    if (isDefined(value) && value != '') {
      return value.toString();
    }
    return '0';
  }
  valueOrFixed(value) {
    if (isDefined(value) && value != '') {
      return parseFloat(value).toFixed(2);
    }
    return '0.00';
  }

  calculateLimit() {
    if (this.quoteFromChildComponent && this.quoteFromChildComponent.LastPrice &&
      this.quoteFromChildComponent.LastPrice.Data && this.inputs.limit) {
      this.limitprice = this.quoteFromChildComponent.LastPrice.Data * this.inputs.limit;
    }
  }

  calculateStop() {
    if (this.quoteFromChildComponent && this.quoteFromChildComponent.LastPrice &&
      this.quoteFromChildComponent.LastPrice.Data && this.inputs.stopPrice) {
      this.stopPriceValue = this.quoteFromChildComponent.LastPrice.Data * this.inputs.stopPrice;
    }
  }

  calculateStopLimit() {
    if (this.quoteFromChildComponent && this.quoteFromChildComponent.LastPrice &&
      this.quoteFromChildComponent.LastPrice.Data && this.inputs.stopLimit) {
      this.stopLimitPrice = this.quoteFromChildComponent.LastPrice.Data * this.inputs.stopLimit;
    }
  }

  calculateTrailingDollarPrice() {
    if (this.quoteFromChildComponent && this.quoteFromChildComponent.LastPrice &&
      this.quoteFromChildComponent.LastPrice.Data && this.inputs.triggerDollarPrice) {
      this.trailingDollarPrice = this.quoteFromChildComponent.LastPrice.Data * this.inputs.triggerDollarPrice;
    }
  }

  calculateTrailingLimitOffset() {
    if (this.quoteFromChildComponent && this.quoteFromChildComponent.LastPrice &&
      this.quoteFromChildComponent.LastPrice.Data && this.inputs.limitOffsetDollar) {
      this.trailingLimitOffset = this.quoteFromChildComponent.LastPrice.Data * this.inputs.limitOffsetDollar;
    }
  }

  calculatetrailingStopPercentModePrice() {
    if (this.quoteFromChildComponent && this.quoteFromChildComponent.LastPrice &&
      this.quoteFromChildComponent.LastPrice.Data && this.inputs.triggerPercentagePrice) {
      this.trailingStopPercentMod = (this.inputs.triggerPercentagePrice / this.quoteFromChildComponent.LastPrice.Data) * 100;
    }
  }

  tooltipClicked(title) {
    if (title == this.quickTradeContent.text.moreInformationTitle) {
      this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:details:more-info-icon-tool-tip');
    } else {
      this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:details:info-icon-tool-tip');
    }
  }

  // calculate maximum quantity shares
  maxQuantitySlider() {
    if (!this.selectedAccount) {
      return null;
    }
    // short sell NA
    if (this.inputs.action === 'SHORT') {
      return null;
    }
    // BUY TO COVER //SELL
    if (this.inputs.action === 'BUYTOCOVER' || this.inputs.action === 'SELL') {
      if ((this.inputs.stockHoldingIndex === -1 && !(this.stockHoldings.length > 0)) ||
        this.stockHoldings[this.inputs.stockHoldingIndex] === undefined) {
        return null;
      }
      var rangeEl = document.getElementById('myInput');
      if (rangeEl) {
        this.updateRangeEl(rangeEl, this.inputs.quantity);
      }
      return parseFloat(this.stockHoldings[this.inputs.stockHoldingIndex].Quantity.Data);
    }

    // BUY   //Maximum Quantity = (Buying Power – Commission) / (Order Price * Exchange Rate)
    if (this.inputs.action === 'BUY' && (this.accountList[this.inputs.accountIndex].isRegistered
      || this.selectedAccount.AccountType == 'CSH')) {

      if (!this.selectedSymbolForStore) {
        return null;
      }
      let buyingPower;
      // Buying Power
      // Cash account
      if (this.selectedAccount.AccountType === 'CSH') {
        buyingPower = this.accountList[this.inputs.accountIndex].ConsolidatedBuyingPower;
      } else if (this.accountList[this.inputs.accountIndex].isRegistered) {
        // Registered Account
        if (this.selectedAccount.Currency === 'CAD') {
          buyingPower = this.accountList[this.inputs.accountIndex].BuyingPowerCAD;
        } else {
          buyingPower = this.accountList[this.inputs.accountIndex].BuyingPowerUSD;
        }
      }
      if (!buyingPower) {
        return null;
      } else {
        if (this.appStore.lang == Lang.FR) {
          buyingPower = this.removeCommasFR(buyingPower);
        } else {
          buyingPower = this.removeCommas(buyingPower);
        }
      }

      let orderPrice = this.calculateAdjustedOrderPrice(true);

      if (!isDefined(orderPrice)) {
        return null;
      }

      // Commission
      const Commission = this.commission;
      const maxShares = Math.floor((parseFloat(buyingPower) - (Commission)) / orderPrice);
      var rangeEl = document.getElementById('myInput');
      var isFinite = Number.isFinite(maxShares);
      var rangeEl = document.getElementById('myInput');
      if (rangeEl && isFinite) {
        this.updateRangeEl(rangeEl, this.inputs.quantity);
      }
      return isFinite ? maxShares : null;
    }

    return null;
  }

  calculateAdjustedOrderPrice(maxQuantity: boolean = true) {
    var isMarketOpen = this.checkMarketHours();
    let orderPrice;
    // Order Price (2%)
    let price;
    if (this.inputs.priceMode === 'marketMode' || this.inputs.priceMode === 'trailingStopMode') {
      if (!this.quotesData) { return null; }
      if (isMarketOpen) {
        if (this.quoteFromChildComponent && this.quoteFromChildComponent.AskPrice) {
          price = parseFloat(this.quoteFromChildComponent.AskPrice.Data);
        } else {
          return null;
        }
      } else if (this.quoteFromChildComponent && this.quoteFromChildComponent.LastPrice) {
        price = parseFloat(this.quoteFromChildComponent.LastPrice.Data);
      } else {
        return null;
      }
    }
    if (this.inputs.priceMode === 'marketMode') {
      // Market Order
      if (!this.quoteFromChildComponent) { return null; }
      orderPrice = price + (maxQuantity ? price * this.quickTradeContent.list.trailingTooltipList.p1_market_price : 0);
    } else if (this.inputs.priceMode === 'limitMode') {
      // Limit Order
      if (!this.inputs.limit) { return null; }
      orderPrice = parseFloat(this.inputs.limit.toString().replace(",", "."));
    } else if (this.inputs.priceMode === 'stopMode') {
      // Stop Order
      if (!this.inputs.stopLimit) { return null; }
      orderPrice = parseFloat(this.inputs.stopLimit.toString().replace(",", "."));
    } else if (this.inputs.priceMode === 'trailingStopMode' && this.inputs.trailingMode == 'dollar') {
      // Trailing stop $     Price = current market price + Trigger Delta + limit Offset
      if (!this.quoteFromChildComponent) { return null; }
      if (!this.inputs.triggerDollarPrice) { return null; }
      if (!this.inputs.limitOffsetDollar) { return null; }
      // current market price = market price + Buffer
      price = price + (maxQuantity ? price * this.quickTradeContent.list.trailingTooltipList.p1_market_price : 0);
      orderPrice = price + ((this.inputs.triggerDollarPrice === undefined) ? 0 : parseFloat(this.inputs.triggerDollarPrice.toString().replace(",", ".")))
        + ((this.inputs.limitOffsetDollar === undefined) ? 0 : parseFloat(this.inputs.limitOffsetDollar.toString().replace(",", ".")));
    } else if (this.inputs.priceMode === 'trailingStopMode' && this.inputs.trailingMode == 'percent') {
      // Trailing stop %     Price = current market price + current market price *Trigger Delta + limit Offset
      if (!this.quoteFromChildComponent) { return null; }
      if (!this.inputs.triggerPercentagePrice) { return null; }
      if (!this.inputs.limitOffsetDollar) { return null; }
      // current market price = market price + Buffer
      price = price + (maxQuantity ? price * this.quickTradeContent.list.trailingTooltipList.p1_market_price : 0);
      orderPrice = price + (price * ((this.inputs.triggerPercentagePrice === undefined) ? 0
        : parseFloat(this.inputs.triggerPercentagePrice.toString().replace(",", "."))) / 100) +
        ((this.inputs.limitOffsetDollar === undefined) ? 0 : parseFloat(this.inputs.limitOffsetDollar.toString().replace(",", ".")));
    }
    // Exchange Rate (2%)

    let exchangeRate;

    if (this.exchangeRate && maxQuantity) {
      // Non-Registered account
      if (!this.accountList[this.inputs.accountIndex].isRegistered) {
        if (this.quoteFromChildComponent.Symbol.Currency === 'US') {
          exchangeRate = parseFloat(this.exchangeRate) + (parseFloat(this.exchangeRate)
            * this.quickTradeContent.list.trailingTooltipList.p2_exch_rate);
        }
        else {
          exchangeRate = 1;
        }
      }
      // Registered account
      else {
        if ((this.quoteFromChildComponent.Symbol.Currency === 'US') &&
          this.selectedAccount.Currency === 'CAD') {
          exchangeRate = parseFloat(this.exchangeRate) + (parseFloat(this.exchangeRate)
            * this.quickTradeContent.list.trailingTooltipList.p2_exch_rate);
        } else if (this.quoteFromChildComponent.Symbol.Market === 'CA' && this.selectedAccount.Currency === 'USD') {
          exchangeRate = (1 / parseFloat(this.exchangeRate));
          exchangeRate = exchangeRate + (exchangeRate * this.quickTradeContent.list.trailingTooltipList.p2_exch_rate);
        } else {
          exchangeRate = 1;
        }
      }
    } else if (maxQuantity) {
      return null;
    } else {
      exchangeRate = 1
    }

    return orderPrice * exchangeRate;
  }

  // slider increment button
  addQuantity(event: number) {
    //check quantity undefined, check less than max quantity
    if (this.inputs.quantity === undefined && this.maxQuantitySlider() != 0) {
      if (this.isSliderAvailable()) {
        this.inputs.quantity = 0;
      } else {
        this.inputs.quantity = undefined;
      }
    }
    if (this.inputs.quantity < (this.maxQuantitySlider() - 1)) {
      this.inputs.quantity++;
      this.rangeSlider++;
      const rangeEl = document.getElementById('myInput');
      if (rangeEl) {
        this.updateRangeEl(rangeEl, this.inputs.quantity);
      }
    }
  }

  // slider decrement button
  subtractQuantity(event: number) {
    if (this.inputs.quantity >= 1) {
      this.inputs.quantity--;
      this.rangeSlider--;
      const rangeEl = document.getElementById('myInput');
      if (rangeEl) {
        this.updateRangeEl(rangeEl, this.inputs.quantity);
      }
    }
  }

  sendOmnitureInteractionData(value?: string) {
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagInteraction(value, true, true, false, true));
    }
  }

  omnitureTagging() {
    if ((window as any).TrackingEnabled) {
      const config: OmniturePageDetail = this.getOmnitureConfig();
      of(this.trackingService.tagPageActionManually(config));
    }
  }

  getOmnitureConfig() {
    let config: OmniturePageDetail;
    config = {
      name: 'details',
      url: '/txn/quick-trade-stocks/landing',
      data: {
        name: 'details',
        hierarchy: 'quick-trade,trading,trade-stocks',
        formInfo: {
          name: 'obr-trade-stocks',
          stepName: 'details',
        },
        events: { pageView: true, formSubmit: true, formStep: true, tradeSubmit: true },
      },
    };
    return config;
  }

  isOlderEdgeOrIE() {
    return (
      window.navigator.userAgent.indexOf('MSIE ') > -1 ||
      !!navigator.userAgent.match(/Trident.*rv\:11\./) ||
      window.navigator.userAgent.indexOf('Edge') > -1
    );
  }

  valueTotalRatio(value, min, max) {
    return ((value - min) / (max - min)).toFixed(2);
  }

  getLinearGradientCSS(ratio, leftColor, rightColor) {
    return [
      '-webkit-gradient(',
      'linear, ',
      'left top, ',
      'right top, ',
      'color-stop(' + ratio + ', ' + leftColor + '), ',
      'color-stop(' + ratio + ', ' + rightColor + ')',
      ')'
    ].join('');
  }

  updateRangeEl(rangeEl, fromQuantity?, fromChangeQuantity?) {
    if (rangeEl.max == '' || !this.inputs.quantity) {
      rangeEl.value = 0;
      if (fromChangeQuantity && this.isSliderAvailable()) {
        this.inputs.quantity = undefined;
      }
    }
    var ratio = this.valueTotalRatio(fromQuantity ?? rangeEl.value, rangeEl.min, rangeEl.max);
    rangeEl.style.backgroundImage = this.getLinearGradientCSS(ratio, '#2779b0', '#dddddd');
    if (rangeEl.max != '' && this.inputs.quantity !== undefined) {
      this.inputs.quantity = fromQuantity ?? rangeEl.value;
    }
    if (fromQuantity) {
      rangeEl.value = fromQuantity;
    }
  }

  onSliderBlur(e) {
    setTimeout(() => {
      this.loadingSlider = false;
    }, 2000);
  }

  initRangeEl(fromQuantity?) {
    var rangeEl: any = document.getElementById('myInput');
    if (!rangeEl) {
      return;
    }
    /**
     * IE/Older Edge FIX
     * On IE/Older Edge the height of the <input type="range" />
     * is the whole element as oposed to Chrome/Moz
     * where the height is applied to the track.
     *
     */
    if (this.isOlderEdgeOrIE()) {
      rangeEl.style.height = '20px';
      // IE 11/10 fires change instead of input
    } else {
      this.updateRangeEl(rangeEl, fromQuantity);
      rangeEl.addEventListener('input', (e) => {
        this.updateRangeEl(e.target);
        this.loadingSlider = true;
        let rangeV: any = document.getElementById('rangeV');
        if (rangeV) {
          let newValue = Number((rangeEl.value - rangeEl.min) * 100 / (rangeEl.max - rangeEl.min));
          let newPosition = 12 - (newValue * 0.2);
          rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`;
        }
      });
    }
  }

  isSliderAvailable() {
    if (this.agreementError) {
      // for exchange agreements not accepted user
      return ((this.accountList[this.inputs.accountIndex]?.accountBalanceAmount) && (this.maxQuantitySlider() > 0));
    } else {
      return ((this.accountList[this.inputs.accountIndex]?.accountBalanceAmount) &&
        (this.quoteFromChildComponent?.Symbol?.SymbolLongName || this.inputs.stockHoldingIndex != -1) && (this.maxQuantitySlider() > 0));
    }
  }

  // check if it is market hours in US/CA respectively
  checkMarketHours() {
    let currentDate = new DatePipe('en-Us').transform(this.currentDateTime(), 'yyyy-MM-dd');
    let currentTime = new DatePipe('en-Us').transform(this.currentDateTime(), 'HH:mm:ss');
    var quoteCountry = this.quoteFromChildComponent?.Symbol?.Market;
    var isMarketHours = false;

    if (gatewayConfig.Market) {
      gatewayConfig.Market.forEach(market => {
        if (market.Country === quoteCountry) {
          var holidays = market.Holidays;
          if (holidays.Days.includes(currentDate)) {
            isMarketHours = false;
          }
          else {
            var shortDays = market.ShortDays;
            if (shortDays.Days.includes(currentDate)) {
              if ((currentTime >= shortDays.OverallOpen) && (currentTime <= shortDays.OverallClose)) {
                isMarketHours = true;
              }
              else {
                isMarketHours = false;
              }
            }
            else {
              if ((currentTime >= market.Default.OverallOpen) && (currentTime <= market.Default.OverallClose)) {
                isMarketHours = true;
              }
              else {
                isMarketHours = false;
              }
            }
          }
        }
      });
    }
    return isMarketHours;
  }

  // get current ET date and time
  currentDateTime() {
    var d = new Date();
    return d.toLocaleString('en-US', { timeZone: 'America/Toronto' });
  }

  //format lastTradeDate in quote details
  getAsOfDateTime(value) {
    if (!isDefined(value)) {
      return this.dash;
    }
    if (Lang.FR === this.appStore.lang) {
      var datetime = value.split(' ');
      var time = datetime[3].split(':');
      datetime[3] = time[0] + ' h ' + time[1] + ' min ' + time[2] + ' sec';
      return datetime.join(' ');
    }
    else {
      return value;
    }
  }

  getSummaryActionContent(orderType) {
    if (orderType == 'BUY' || orderType == '') {
      return this.quickTradeContent.text.summaryBuy;
    } else if (orderType == 'SELL') {
      return this.quickTradeContent.text.summarySell;
    } else if (orderType == 'BUYTOCOVER') {
      return this.quickTradeContent.text.summaryBuyToCover;
    } else if (orderType == 'SHORT') {
      return this.quickTradeContent.text.summaryShortSell;
    }
  }

  handleQuoteExpansion(value) {
    if (value && this.isQuoteExpanded == 0) {
      this.isQuoteExpanded = 1;
      setTimeout(() => {
        this.isQuoteExpanded = 2;
      }, 200)
      this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:details:quotes-details-expanded');
    } else if (!value && this.isQuoteExpanded == 2) {
      this.isQuoteExpanded = 3;
      setTimeout(() => {
        this.isQuoteExpanded = 0;
      }, 200)
      this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:details:quotes-details-collapsed');
    }
    if (this.isQuoteExpanded) {
    }
    else {
    }
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
    return 'unknown';
  }

  setActionArray() {
    if (!this.hasBuyToCover) {
      this.actionArray = [
        {
          label: this.globalContent.text.buy,
          color: "#FFFFFF",
          backgroundColor: "#1F8561",
          value: "BUY"
        },
        {
          label: this.globalContent.text.sell,
          color: "#FFFFFF",
          backgroundColor: "#CC4B18",
          value: "SELL"
        }
      ]
    } else {
      this.actionArray = [
        {
          label: this.globalContent.text.shortSell,
          color: "#FFFFFF",
          backgroundColor: "#CC4B18",
          value: "SHORT"
        },
        {
          label: this.globalContent.text.buyToCover,
          color: "#FFFFFF",
          backgroundColor: "#1F8561",
          value: "BUYTOCOVER"
        }
      ]
    }
  }

  setPriceArray() {
    this.priceArray = [
      {
        label: this.quickTradeContent.text.marketButton,
        value: "marketMode"
      },
      {
        label: this.quickTradeContent.text.limitButton,
        value: "limitMode"
      },
      {
        label: this.quickTradeContent.text.stopButton,
        value: "stopMode"
      },
      {
        label: this.quickTradeContent.text.trailingStopLimit,
        value: "trailingStopMode"
      }
    ];
  }

  setExpiryArray() {
    this.expiryArray = [
      {
        label: this.quickTradeContent.text.dayButton,
        value: "day"
      },
      {
        label: this.quickTradeContent.text.customDate,
        value: "customDate"
      }
    ];
    this.disabledExpiryArray = [
      false,
      true
    ]
  }

  setExpiryCustomDisabled() {
    this.disabledExpiryArray[1] = !this.showCustomDate();
    if (this.disabledExpiryArray[1]) {
      this.changeExpiryMode('day');
    }
  }

  updateExtendedHours(): void {
    if (this.inputs?.market === 'US' && this.inputs?.priceMode === 'limitMode' && this.inputs?.expiryMode === 'day') {
      this.showExtendedHours = true;
    } else {
      this.inputs.extendedHours = false;
      this.showExtendedHours = false;
    }
  }

  extendedHoursUpdate() {
    if (this.inputs.extendedHours) {
      this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:details:day-extended-hours:unchecked');
    } else {
      this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:details:day-extended-hours:check');
    }
  }

  selectLimit(value) {
    this.changePriceMode("limitMode");
    if (value && value.Data) {
      let val: any = (Math.round(parseFloat(value.Data) * 100) / 100).toFixed(2);
      this.inputs.limit = val;
      this.changeQuantity(this.inputs.quantity);
    }
    this.setFocus("limit-input" + this.uniqueName);
  }

  refreshAll() {
    if (this.quoteFromChildComponent || this.errorQuote) {
      if (this.mode == 'quickTrade') {
        this.subscriptionService.startRefreshTime({ channel: 'quickTrade', count: 1 });
      } else {
        this.subscriptionService.startRefreshTime({ channel: 'header', count: 1 });
      }
      this.refreshQuoteDetails(this.selectedSymbol);
    }
  }

  removeDollarSign(val) {
    return val.replace(' $', '').replace("$", '');
  }

  handleNewContentCards(cards) {
    cards = this.commonService.getSmallCards(cards);
    this.carouselContent = {
      "leftHdnText": this.quickTradeContent.text.carouselLeftHdnText,
      "rightHdnText": this.quickTradeContent.text.carouselRightHdnText,
    }
    let impressionList = [];
    for(let i = 0; i < cards.length; i++) {
      this.carouselContent["circleHdnText" + (i + 1)] = cards[i].title;
      let isNew = true;
      for(let j = 0; j < this.contentCards.length; j++) {
        if (cards[i].id == this.contentCards[j].id) {
          isNew = false;
        }
      }
      if(isNew) {
        impressionList.push(cards[i]);
      }
    }
    if (impressionList.length > 0) {
      this.commonService.logContentCardImpressions(impressionList);
    }

    this.contentCards = cards;
  }

  contentCardClick(url: string, card) {
    if (isDefined(url)) {
      this.commonService.logContentCardClick(card);
      if (url.startsWith("#") || url.startsWith("/")) {
        this.router.navigate([url.replace(/^#/,"")]);
      } else {
        this.openWindow(url);
      }
    }
  }

  focusBacktoCard(id) {
    let el = document.getElementById(id);
    if (isDefined(el)) {
      el.focus();
    }
  }

  focusIndividualCarouselCard(i) {
    this.smallCarousel.selectedItem(i);
  }
  
  setSelectedSymbol(name, market) {
    if (name) {
      this.selectedSymbol = { SymbolName: name, Market: market, SymbolType: 'EQUITY' };
      if (name.endsWith('.U')) {
        this.selectedSymbol.Currency = "US";
      } else {
        this.selectedSymbol.Currency = market;
      }
    } else {
      this.selectedSymbol = { Market: market, SymbolType: 'EQUITY', Currency: market };
    }
  }
}
