<ng-container *ngIf="pagename != 'IESmallHeader'; else IESmallHeader">
  <section class="predictive-search searchArea search-lob-{{appStore.lob}}" id="predictive_search_searchArea"
    [ngClass]="{'header-search-option': pagename == 'Option-centre', 'header-search': pagename == 'Header', 'soft': styling == 'quickTrade', 'optionCentral-search': pagename == 'OptionCentral'}">
    <input [attr.id]="'symbolInput' + uniqueId" class="predictive-input" [ngClass]="{'soft': styling == 'quickTrade'}"
      name="symbolInput" [(ngModel)]="query" type="text" placeholder="{{placeholder}}" maxlength="40"
      (ngModelChange)="change(query)" autocomplete="off" autocorrect="off" (focus)="scrollToTop()" limit-char limit="40"
      [inputErrorDirective]="formError" [attr.aria-labelledby]="labelledbyAttr"
      [attr.aria-describedby]="describedbyAttr" role="combobox"
      [attr.aria-expanded]="(symbols.length > 0 || (noResults && extraResults > 0))"
      [attr.aria-owns]="(symbols.length > 0 || (noResults && extraResults > 0))?'predictiveSearchResults' + uniqueId: ''"
      [attr.aria-activedescendant]="((symbols.length > 0 || (noResults && extraResults > 0)) && activeItem || 
      (symbols.length > 0 || (noResults && extraResults > 0)) && activeItem === 0)?'predictiveSearchValue_' + activeItem + uniqueId:''" [disabled]="disabled">
    <span [attr.aria-hidden]="true" class="icon-Search color04" *ngIf="pagename == 'Header' || pagename == 'Option-centre' || pagename == 'OptionCentral'"></span>
    <button *ngIf="query != undefined && query != '' && pagename == 'OptionCentral'" type="button"
    class="predictiveSearchClearButton icon-Close-Circle" [attr.aria-label]="globalcontent.text.predictiveClear"
    (click)="clearQuery()"></button>

    <div aria-live="assertive">
      <div class="other-results A16 color03" *ngIf="noResults">
        <div *ngIf="pagename != 'TradeOptions'" class="no-results-text division" [innerHtml]="contents.error.MSGGLB076.message"></div>
        <div *ngIf="pagename == 'TradeOptions'" class="ellipsisOverflow" [ngClass]="listClasses(i)" (click)="selectCantfindOption()" (mouseenter)="onMouseEnter(i)" >
          <span class="icon-Search color04"><span class="sr-only"> {{globalcontent.text.typeSymbolSR}}</span> </span>
          <span class="cant-find-name">{{globalcontent.text.typeSymbol}}</span>
            </div>
        <div [attr.id]="'predictiveSearchResults' + uniqueId" role="listbox"
          [attr.aria-label]="contents.text.predictiveResults" *ngIf="!hasError" class="symbols">
          <div *ngIf="extraResults > 0" [attr.aria-hidden]="true" class="extra-result-label A12b color03">
            {{globalcontent.text.predictiveExtraResultLabel}}
          </div>
          <div role="option" *ngIf="extraResults > 0" (click)="selectExtraLink(0)" class="ellipsisOverflow symbol-item"
            [ngClass]="listClasses(symbols.length)" (mouseenter)="onMouseEnter(symbols.length)"
            [attr.id]="'predictiveSearchValue_0' + uniqueId" id="predictive_search_predQuoteList"
            title="{{globalcontent.text.predictiveExtraResultLabel}} {{globalcontent.text.predictiveQuoteList}}">
            <span class="icon-List color04 list-icons" [attr.aria-hidden]="true"></span>
            <span class="align-middle A12 color02">{{globalcontent.text.predictiveQuoteList}}</span>
          </div>
          <div role="option" *ngIf="extraResults > 1" (click)="selectExtraLink(1)" class="ellipsisOverflow symbol-item"
            [ngClass]="listClasses(symbols.length + 1)" (mouseenter)="onMouseEnter(symbols.length + 1)"
            [attr.id]="'predictiveSearchValue_1' + uniqueId" id="predictive_search_predWatchlist"
            title="{{globalcontent.text.predictiveExtraResultLabel}} {{globalcontent.text.predictiveWatchList}}">
            <span class="icon-Quick-Look color04 list-icons" [attr.aria-hidden]="true"></span>
            <span class="align-middle A12 color02">{{globalcontent.text.predictiveWatchList}}</span>
          </div>
        </div>
      </div>
      <div class="sr-only" *ngIf="resultText">
        {{resultText}}
      </div>
    </div>

    <div class="result-wrapper">
      <div class="results" *ngIf="symbols.length > 0">
        <div [attr.id]="'predictiveSearchResults' + uniqueId" role="listbox"
          [attr.aria-label]="contents.text.predictiveResults" *ngIf="!hasError" class="symbols">
          <div role="option" *ngFor="let symbol of symbols; let i = index" (click)="selectSymbol(symbol)"
            class="ellipsisOverflow symbol-item division" title="{{symbol.Content}} {{symbol.SymbolLongName}}"
            [ngClass]="listClasses(i)" [attr.id]="'predictiveSearchValue_' + i + uniqueId"
            (mouseenter)="onMouseEnter(i)" id="predictive_search_listClasses-{{i}}">
            <ng-container *ngIf="pagename !== 'Header'">
              <img class="flag" [src]="setFlag(symbol.Market)" aria-hidden="true" alt="" />
              <span class="symbol-name">{{globalcontent.getSymbolContent(symbol.SymbolName, symbol.Market)}}-</span>
            </ng-container>
            <span class="symbol-longname">{{symbol.SymbolLongName}}</span>
            <span class="A12b color11 optionLabel"
            *ngIf="symbol.OptionEnabled && (pagename == 'Option-centre' || pagename == 'TradeMLS')">{{globalcontent.text.predictiveSearchOption}}</span>
            <div id="predictive_search_selectFlag-{{i}}" *ngIf="pagename === 'Header'">
              <img class="flag" [src]="setFlag(symbol.Market)" aria-hidden="true" alt="" />
              <span class="symbol-name">{{globalcontent.getSymbolContent(symbol.SymbolName, symbol.Market)}}</span>
            </div>
          </div>
          <div *ngIf="pagename == 'TradeOptions'" role="option" class="ellipsisOverflow symbol-item division borderTop cant-find-item" [ngClass]="listClasses(symbols.length)" (click)="selectCantfindOption()" (mouseenter)="onMouseEnter(symbols.length)" id="predictive_search_listClasses-{{symbols.length}}" [attr.id]="'predictiveSearchValue_' + symbols.length + uniqueId" title="{{globalcontent.text.typeSymbol }}">
            <span class="icon-Search color04"><span class="sr-only"> {{globalcontent.text.typeSymbolSR}}</span></span>
            <span class="paddingLeft24 cant-find-name">{{globalcontent.text.typeSymbol}}</span>
              </div>
          <div *ngIf="extraResults > 0" [attr.aria-hidden]="true" class="extra-result-label A12b color03">
            {{globalcontent.text.predictiveExtraResultLabel}}
          </div>
          <div role="option" *ngIf="extraResults > 0" (click)="selectExtraLink(0)" class="ellipsisOverflow symbol-item"
            [ngClass]="listClasses(symbols.length)" (mouseenter)="onMouseEnter(symbols.length)"
            [attr.id]="'predictiveSearchValue_' + symbols.length + uniqueId" id="predictive_search_quoteList"
            title="{{globalcontent.text.predictiveExtraResultLabel}} {{globalcontent.text.predictiveQuoteList}}">
            <span class="icon-List color04 list-icons" [attr.aria-hidden]="true"></span>
            <span class="align-middle A12 color02">{{globalcontent.text.predictiveQuoteList}}</span>
          </div>
          <div role="option" *ngIf="extraResults > 1" (click)="selectExtraLink(1)" class="ellipsisOverflow symbol-item"
            [ngClass]="listClasses(symbols.length + 1)" (mouseenter)="onMouseEnter(symbols.length + 1)"
            [attr.id]="'predictiveSearchValue_' + (symbols.length + 1) + uniqueId" id="predictive_search_Watchlist"
            title="{{globalcontent.text.predictiveExtraResultLabel}} {{globalcontent.text.predictiveWatchList}}">
            <span class="icon-Quick-Look color04 list-icons" [attr.aria-hidden]="true"></span>
            <span class="align-middle A12 color02">{{globalcontent.text.predictiveWatchList}}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
<ng-template #IESmallHeader>
  <button type="button" (click)="expand()" class="predictiveSearchExpansionButton icon-Search"
    [ngClass]="{'white-style': styling != 'normal', 'color01': PEEnabled == true}" [attr.aria-label]="globalcontent.text.predictiveOpen"></button>
  <div #HeaderCover class="predictive-search-header-cover" [ngClass]="{
    'contracted': expansionState == EXPAN.CONTRACTED,
    'expanding': expansionState == EXPAN.EXPANDING,
    'expanded': expansionState == EXPAN.EXPANDED,
    'contracting': expansionState == EXPAN.CONTRACTING
  }">
  </div>
  <div [attr.id]="'IESmallHeaderSearch'+uniqueId" class="IESmallHeaderSearch predictive-search" [ngClass]="{
      'white-style': styling != 'normal',
      'contracted': expansionState == EXPAN.CONTRACTED,
      'expanding': expansionState == EXPAN.EXPANDING,
      'expanded': expansionState == EXPAN.EXPANDED,
      'contracting': expansionState == EXPAN.CONTRACTING
    }">
    <div class="predictive-input-container">
      <div class="predictive-input-wrapper">
        <!-- <i class="predictiveSearchIcon icon-Search"></i> -->
        <input [attr.id]="'symbolInput' + uniqueId" class="predictive-input"
          [ngClass]="{'soft': styling == 'quickTrade'}" name="symbolInput" [(ngModel)]="query" type="text"
          placeholder="{{placeholder}}" maxlength="40" (ngModelChange)="change(query)" autocomplete="off"
          autocorrect="off" (focus)="scrollToTop()" limit-char limit="40" [inputErrorDirective]="formError"
          [attr.aria-labelledby]="labelledbyAttr" [attr.aria-describedby]="describedbyAttr" role="combobox"
          [attr.aria-expanded]="(symbols.length > 0 || (noResults && extraResults > 0))"
          [attr.aria-owns]="(symbols.length > 0 || (noResults && extraResults > 0))?'predictiveSearchResults' + uniqueId: ''"
          [attr.aria-activedescendant]="((symbols.length > 0 || (noResults && extraResults > 0)) && activeItem || 
          (symbols.length > 0 || (noResults && extraResults > 0)) && activeItem === 0)?'predictiveSearchValue_' + activeItem + uniqueId:''">
        <button *ngIf="query != undefined && query != ''" type="button"
          class="predictiveSearchClearButton icon-Close-Circle" [attr.aria-label]="globalcontent.text.predictiveClear"
          (click)="clearQuery()"></button>
      </div>

      <button type="button" (click)="contract()" class="predictiveSearchContractionButton"
        [attr.aria-label]="globalcontent.text.predictiveDoneSR">
        {{globalcontent.text.predictiveDone}}
      </button>

      <div aria-live="assertive">
        <div class="no-results-text" *ngIf="noResults" [innerHtml]="contents.error.MSGGLB076.message">
        </div>
        <div class="sr-only" *ngIf="resultText">{{resultText}}</div>
      </div>
      <div class="results" *ngIf="symbols.length > 0">
        <div [attr.id]="'predictiveSearchResults' + uniqueId" role="listbox"
          [attr.aria-label]="contents.text.predictiveResults" *ngIf="!hasError" class="symbols">
          <div role="option" *ngFor="let symbol of symbols; let i = index" (click)="selectSymbol(symbol)"
            class="ellipsisOverflow symbol-item division" title="{{symbol.Content}} {{symbol.SymbolLongName}}"
            [ngClass]="listClasses(i)" [attr.id]="'predictiveSearchValue_' + i + uniqueId"
            (mouseenter)="onMouseEnter(i)">
            <img class="flag" [src]="setFlag(symbol.Market)" aria-hidden="true" alt="" />
            <span class="symbol-name">{{globalcontent.getSymbolContent(symbol.SymbolName, symbol.Market)}}-</span>
            <span class="symbol-longname">{{symbol.SymbolLongName}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>