import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';
import { HttpHeaders } from '@angular/common/http';
import { reportRequest } from '../data/account-info-page-data';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class AccountInfoService implements PageService {
  globalLobTriggerred: boolean = false;

  constructor(private httpService: HttpService) { }

  prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return input;
  }

  myAccountsPageReq(req, url) {
    return this.httpService.post(url, req);
  }

  getFxData() {
    return this.httpService.post(gatewayConfig.APIServices.cashtransferGetFx.url, {}, { params: { skiploading: 'true' } });
  }

  invokeASGAjaxCall1(tokenUrl: string, clientToken: string) {
    console.log("service invokeASGAjaxCall1");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Basic " + clientToken
      }),
      params: { skiploading: 'true' }
    };
    let data = '{"grant_type":"client_credentials"}';
    return this.httpService.postToExternalURL(tokenUrl, data, httpOptions);
  }

  invokeASGAjaxCall2(dataUrl: string, accessToken: string, clientData: string) {
    console.log("service invokeASGAjaxCall2");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + accessToken
      }),
      params: { skiploading: 'true' }
    };
    let data = '{"data":"' + clientData + '"}';
    return this.httpService.postToExternalURL(dataUrl, data, httpOptions);
  }

  getLobTotals(){
    let url = gatewayConfig.APIServices.lobTotals.url;
    let req = {};
    return this.httpService.post(url, req);
  }
  
  getReport(_req) {
    const req: reportRequest = _req;
    return this.httpService.post(gatewayConfig.APIServices.perfomanceReportingOverall.url, req, { params: { skiploading: 'true' } });
  }
  
  skipTcp() {
    return this.httpService.post(gatewayConfig.APIServices.skipTcp.url, {});
  }

  declineTcp(req) {
    return this.httpService.post(gatewayConfig.APIServices.updateTcp.url, req);
  }

  checkTcp() {
    return this.httpService.post(gatewayConfig.APIServices.checkTcp.url, {}, {params: {skiploading: 'true'}});
  }
}
