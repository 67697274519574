import { Component, Input, Output, SimpleChanges, OnInit, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { ModalService } from 'src/app/shared/components/modal/services/modal.service';
import { OtvcSetupValidator } from '../OtvcSetupValidator';
import { AppStore } from '../../../models/app-store';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import _ from 'lodash';
import { isDefined } from 'src/app/shared/services/utils.service';
import { Router } from '@angular/router';
import { Lang } from '../../../../../app/shared/models/lob.enum';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { CommonService } from 'src/app/shared/services/common.service';


@Component({
  selector: 'app-otvc-setup-form',
  templateUrl: './otvc-setup-form.component.html',
  styleUrls: ['./otvc-setup-form.component.scss']
})
export class OtvcSetupFormComponent implements OnInit, OnChanges {
  model: any;
  isModal = false;
  selectedcountry: undefined;
  countries: any;
  defaultCountry: string;
  countryCode: string;
  EnDesc: string;
  DialCode: string;
  phoneTypeEnum: any = {
    MOBILE_PHONE: 'M',
    HOME_PHONE: 'H',
    BUSINESS_PHONE: 'B'
  };
  hidePage = false;
  currentCountry: any;
  countrylistparam: any;
  pagecontentparam: any;
  countryobjectparam: any;
  states: any;
  messageList: any;
  currentModule: string = "otvcsetup";
  lob: string;
  refreshError: boolean = true;
  noContactError: string;
  hasNoContact: boolean;

  otvcSignonTooltip: any = {};
  otvcTooltip: any = {};


  @Input() originalModel: any;
  @Input() error: any = {};
  //   otvcSetupForm=new FormGroup({
  //   mobilePhoneCountry: new FormControl(''),
  //   mobilePhone: new FormControl(''),
  //   homePhoneCountry: new FormControl(''),
  //   homePhone: new FormControl(''),
  //   businessPhoneCountry: new FormControl(''),
  //   businessPhone: new FormControl(''),
  //   email: new FormControl(''),
  //   alwaysOtvc: new FormControl('')
  // })

  @ViewChild('otvcSetupForm') otvcSetupForm: any;

  @Input() header: string;
  @Input() refreshAppError: boolean;

  @Input()
  get inputsObj() {
    return this.inputs;
  }

  set inputsObj(val) {
    this.inputs = val;
  }

  public inputs: any;


  @Output()
  returnError = new EventEmitter();

  @Output()
  returnAppError = new EventEmitter();

  @Output()
  verifyOtvcRequested = new EventEmitter();

  @Output()
  // SignOffRequested = new EventEmitter();
  onSignOffRequested = new EventEmitter();

  @Output()
  EmailInfoRequested = new EventEmitter();

  @Output()
  // SkipOtvcRequested = new EventEmitter();
  onSkipOtvcRequested = new EventEmitter();

  @Output()
  openModal = new EventEmitter();
  ismain: any;
  public uniqueId;


  constructor(
    public modalservice: ModalService,
    private formBuilder: FormBuilder,
    private subscriptionService: ObservableSubscriptionService,
    public appStore: AppStore,
    private router: Router,
    private globalContent: GlobalContentStore,
    private commonService: CommonService) {
  }

  ngOnInit() {

    this.otvcTooltip.openBtnLabel = '';
    this.otvcTooltip.cancel = this.globalContent.text.closeTooltip;
    this.otvcTooltip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
    this.otvcTooltip.content = '';

    this.otvcSignonTooltip.openBtnLabel = '';
    this.otvcSignonTooltip.cancel = this.globalContent.text.closeTooltip;
    this.otvcSignonTooltip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
    this.otvcSignonTooltip.content = '';

    this.currentModule = "otvcsetup";
    console.log("inputs here: " + this.inputs.homePhoneCountry);
    this.uniqueId = this.appStore.uniqueId;
    console.log("email error nginit" + this.error.email);
    this.lob = this.appStore.state.user.lob.toUpperCase();
    // fetch countries list
    // this.otvcSetupForm = OtvcSetupValidator.createForm(this.otvcSetupForm, this.formBuilder);

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.currentModule === 'otvcsetup') {
      this.uniqueId = this.appStore.uniqueId;
      if (isDefined(changes.originalModel)) {
        this.model = JSON.parse(JSON.stringify(this.originalModel));
      }
      if (isDefined(changes.refreshAppError)) {
        console.log('refresh app erro is' + this.refreshAppError);
      }
      console.log('OTVC_initialize method ----- ' + this.inputs);
      console.log(this.inputs.mobilePhoneCountry);
      console.log('inputs change' + this.inputs.homePhoneCountry);
      console.log(this.inputs.mobilePhoneCountry.CountryCode);
      console.log('email error ngchange' + this.error.email);
    }
  }

  isEqual(obj1, obj2) {
    return _.isEqual(obj1, obj2);
  }

  toViewFormat(modelPhone, phoneType) {
    modelPhone = modelPhone.replace(/[^0-9]/g, '');
    if (this.checkPreviousValue() && (modelPhone !== '')) {
      this.validateSubmit();
    } else {
      this.model.isSubmit = false;
    }
    let result = modelPhone;
    let countryInfoObject = null;
    if (phoneType === this.phoneTypeEnum.MOBILE_PHONE) {
      // countryInfoObject = this.inputs.mobilePhoneCountry;
      countryInfoObject = this.model.countryList[this.inputs.mobilePhoneCountry];
      this.error.mobilePhone = undefined;
    } else if (phoneType === this.phoneTypeEnum.HOME_PHONE) {
      // countryInfoObject = this.inputs.homePhoneCountry;
      countryInfoObject = this.model.countryList[this.inputs.homePhoneCountry];
      this.error.homePhone = undefined;
    } else if (phoneType === this.phoneTypeEnum.BUSINESS_PHONE) {
      // countryInfoObject = this.inputs.businessPhoneCountry;
      countryInfoObject = this.model.countryList[this.inputs.businessPhoneCountry];
      this.error.businessPhone = undefined;
    }

    if (modelPhone.length > 12) {
      result = modelPhone.substr(0, 12);
    }

    if (modelPhone && (countryInfoObject.CountryCode === '146')) {
      // e.g. 416581
      if (modelPhone.length > 10) {
        result = modelPhone.substr(0, 10);
      }
      if (modelPhone.length > 3 && modelPhone.length <= 6) {
        const area = modelPhone.substr(0, 3);
        const num1 = modelPhone.substr(3);
        const compiled = _.template('(<%= area %>) <%= num1 %>');
        result = compiled({ area: area, num1: num1 });
      } else if (modelPhone.length > 6) {
        // e.g. 14165817080
        const area = modelPhone.substr(0, 3);
        const num1 = modelPhone.substr(3, 3);
        const num2 = modelPhone.substr(6, 4);
        const compiledPhone = _.template('(<%= area %>) <%= num1 %>-<%= num2 %>');
        // return `(${area}) ${num1}-${num2}`;
        result = compiledPhone({ area: area, num1: num1, num2: num2 });
      }
    }
    return result;
  }


  isNull(obj) {
    return (obj === '') ? undefined : obj;
  }

  isNullOrEmpty(value) {
    if (typeof value === 'undefined') {
      return true;
    }

    if (typeof value === 'object') {
      return !value;
    }

    if (typeof value === 'string' && value !== '') {
      return false;
    }

    return true;
  }

  preventChar(event) {
    const pattern = /[0-9]/;
    const input = String.fromCharCode(event.charCode);
    if (!pattern.test(input)) {
      event.preventDefault();
    }
  }

  openEmailInfo(id) {

    this.modalservice.open(id);
    this.hidePage = true;
    this.messageList = [this.model.pageContent.Contents.text.toolTip];
  }

  openMobileInfo(id) {

    this.modalservice.open(id);
    this.hidePage = true;
    this.messageList = [this.model.pageContent.Contents.text.T2, this.model.pageContent.Contents.text.T4];
  }

  openHomeInfo(id) {
    this.modalservice.open(id);
    this.hidePage = true;
    this.messageList = [this.model.pageContent.Contents.text.T2, this.model.pageContent.Contents.text.T3];

  }

  openWorkInfo(id) {
    this.modalservice.open(id);
    this.hidePage = true;
    this.messageList = [this.model.pageContent.Contents.text.T1, this.model.pageContent.Contents.text.T2, this.model.pageContent.Contents.text.T3];

  }

  validateEmail(email) {
    if (this.checkPreviousValue()) {
      let passWhitelist = false;
      // let WHITELIST = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
      let WHITELIST = /^[a-zA-Z0-9_.'-]+@[a-zA-Z0-9_.'-]+?\.[a-zA-Z]{2,6}$/;
      if (!this.isNullOrEmpty(email) && email != '') {
        passWhitelist = !(email.match(WHITELIST) === null);
        this.model.isSubmit = passWhitelist;
        return passWhitelist;
      } else {
        this.model.isSubmit = (!this.isNullOrEmpty(this.inputs.homePhone) || !this.isNullOrEmpty(this.inputs.mobilePhone) || !this.isNullOrEmpty(this.inputs.businessPhone));
        return this.model.isSubmit;
      }
    } else {
      this.model.isSubmit = false;
      return this.model.isSubmit;
    }
  }


  validateSubmit() {
    let temp = false;
    // if (!this.isNullOrEmpty(this.inputs.email) && this.inputs.email !== '') {
    //   temp = this.validateEmail(this.inputs.email);
    // } else {
    temp = ((!this.isNullOrEmpty(this.inputs.homePhone)
      || !this.isNullOrEmpty(this.inputs.mobilePhone)
      || !this.isNullOrEmpty(this.inputs.businessPhone))

    );
    // }
    return temp;
  }

  checkContactEntered() {
    let temp = false;
    temp = ((!this.isNullOrEmpty(this.inputs.email)
      || !this.isNullOrEmpty(this.inputs.homePhone)
      || !this.isNullOrEmpty(this.inputs.mobilePhone)
      || !this.isNullOrEmpty(this.inputs.businessPhone))
    );
    return temp;
  }

  resetEmailError() {
    this.error.email = undefined;
    // this.otvcSetupForm.controls['email'].valid = true;
  }


  resetFormError() {
    let controlName = this.otvcSetupForm; // If no parameter passed, whole Form will be reseted.
    if (arguments.length > 0) { // If any parameter passed, that particular controlName will be reseted.
      controlName = arguments[0];
      // this.model.formError[arguments[0]] = OtvcSetupValidator.resetErrorMessage(controlName);
      this.error[arguments[0]] = OtvcSetupValidator.resetErrorMessage(controlName);
    } else {
      // this.model.formError = OtvcSetupValidator.resetErrorMessage(controlName);
      this.error = OtvcSetupValidator.resetErrorMessage(controlName);
      this.returnError.emit(this.error);
    }
  }

  checkPreviousValue() {
    if ((this.isNull(this.inputs.homePhone) !== this.model.temp.homePhone)
      || (this.isNull(this.inputs.mobilePhone) !== this.model.temp.mobilePhone)
      || (this.isNull(this.inputs.businessPhone) !== this.model.temp.businessPhone)
      || (this.isNull(this.inputs.email) !== this.model.temp.email)
      || (this.inputs.alwaysOtvc !== this.model.temp.alwaysOtvc)) {
      return true;
    } else {
      return false;
    }
  }
  learnmore() {
    const url = unescape(this.model.pageContent.text.learnMoreLink);
    // if (this.model.isWrapper) {
    if (this.appStore.isApp()) {
      window.open(url, '_system', 'location=yes');
    } else {
      window.open(url, '_self');
    }
  }

  signOff() {
    // this.SignOffRequested.emit();
    this.onSignOffRequested.emit();
  }

  goToUserPreferences() {
    this.router.navigate(['/txn/userPreferences']);
  }

  changeCountry(type) {
    if (type === 'M') {
      this.inputs.mobilePhone = '';
    } else if (type === 'H') {
      this.inputs.homePhone = '';
    } else if (type === 'B') {
      this.inputs.businessPhone = '';
    }

  }

  next(form: any) {
    console.log(this.inputs);
    this.subscriptionService.setError('RESET');
    OtvcSetupValidator.setValidators(this.otvcSetupForm, this.formBuilder, this.model.countryList[this.inputs.mobilePhoneCountry]
      , this.model.countryList[this.inputs.homePhoneCountry]
      , this.model.countryList[this.inputs.businessPhoneCountry]);
    OtvcSetupValidator.setValue(this.otvcSetupForm);
    if (this.otvcSetupForm.valid) {
      this.refreshAppError = true;
      this.hasNoContact = true;
      // this is just to check if any field empty.if we dont enter phone and email and select yes no only then teh forms become valid
      // hence we will check again if all field(phone and email) empty then show error
      if (!this.checkContactEntered()) {
        this.resetError();
        setTimeout(() => {
          this.subscriptionService.setError({ ErrorMessage: this.model.pageContent.error["noContactDetail"].message });
          // this.noContactError = this.model.pageContent.error["noContactDetail"].message;
        }, 100);
      } else {
        this.subscriptionService.setError('RESET');
        this.hasNoContact = false;
        // prepare OtvcDetail
        const otvcContacts = [];
        if (!this.isNullOrEmpty(this.inputs.mobilePhone)) {
          // remove country dial code
          const mobilePhoneNumber = this.inputs.mobilePhone;
          // mobilePhoneNumber = mobilePhoneNumber.substr(mobilePhoneNumber.indexOf(")") + 1);

          let mobilePhone = { Method: 'MOBILE_PHONE', Value: mobilePhoneNumber.replace(/[^0-9]/g, '') };
          // mobilePhone = Object.assign(mobilePhone, this.inputs.mobilePhoneCountry);
          mobilePhone = Object.assign(mobilePhone, this.model.countryList[this.inputs.mobilePhoneCountry]);
          otvcContacts.push(mobilePhone);
        }

        if (!this.isNullOrEmpty(this.inputs.homePhone)) {
          // remove country dial code
          const homePhoneNumber = this.inputs.homePhone;
          // homePhoneNumber = homePhoneNumber.substr(homePhoneNumber.indexOf(")") + 1);

          let homePhone = { Method: 'HOME_PHONE', Value: homePhoneNumber.replace(/[^0-9]/g, '') };
          // homePhone = Object.assign(homePhone, this.inputs.homePhoneCountry);
          homePhone = Object.assign(homePhone, this.model.countryList[this.inputs.homePhoneCountry]);
          otvcContacts.push(homePhone);
        }

        if (!this.isNullOrEmpty(this.inputs.businessPhone)) {
          // remove country dial code
          const businessPhoneNumber = this.inputs.businessPhone;
          // businessPhoneNumber = businessPhoneNumber.substr(businessPhoneNumber.indexOf(")") + 1);

          let businessPhone = { Method: 'BUSINESS_PHONE', Value: businessPhoneNumber.replace(/[^0-9]/g, '') };
          // businessPhone = Object.assign(businessPhone, this.inputs.businessPhoneCountry);
          businessPhone = Object.assign(businessPhone, this.model.countryList[this.inputs.businessPhoneCountry]);
          otvcContacts.push(businessPhone);
        }

        if (!this.isNullOrEmpty(this.inputs.email)) {
          otvcContacts.push({ Method: 'EMAIL', Value: this.inputs.email });
        }
        const request: any = {
          // update
          // OtvcContact: otvcContacts
          "OtvcContact": otvcContacts
        };
        if (this.inputs.alwaysOtvc) {
          request.AlwaysOtvc = this.inputs.alwaysOtvc;
        }

        const data = { request: request, model: this.model };
        // this.resetFormError();
        //This is just to reset any blacklisted error displayed on field level.Requirement is to display blacklisted phone error 
        // just once on page load.Hence we are reseting those error(if any) before moving to next page. Validator does not pick
        // blacklisted contact hence form becomes valid.Hnece we have to reset those error after valid.s
        this.resetError();
        this.verifyOtvcRequested.emit(data);
      }
    } else {
      // if validation failed because of alwaysOtvc,then we will display app-error wont display field level error if entire form is blank.
      if (!this.checkContactEntered()) {
        this.refreshAppError = true;
        this.hasNoContact = true;
        this.resetError();
        this.subscriptionService.setError({ ErrorMessage: this.model.pageContent.error["noContactDetail"].message });
        // this.noContactError = this.model.pageContent.error["noContactDetail"].message;
        // we dont have to display page level and field level error together.
        if (this.error.alwaysOtvc !== '' && this.error.alwaysOtvc !== undefined) {
          this.error.alwaysOtvc = undefined;
        }
        // this.resetFormError();
      } else {
        // this block is for validation failed
        this.refreshAppError = false;
        this.hasNoContact = false;
        // this.model.formError = OtvcSetupValidator.resolveValidationError(this.otvcSetupForm, this.model.pageContent.error);
        this.error = OtvcSetupValidator.resolveValidationError(this.otvcSetupForm, this.model.pageContent.error);
        this.returnError.emit(this.error);
        // this.returnAppError.emit(this.refreshAppError);

        this.model.focus = 'error';
        console.log('Form Error --- ' + JSON.stringify(this.model.formError));
        if (this.model.hasError) {
          // super.clearError();
          this.subscriptionService.setError('RESET');
        }
      }
    }
  }

  resetError() {
    this.error.mobilePhone = undefined;
    this.error.homePhone = undefined;
    this.error.businessPhone = undefined;
    this.error.email = undefined;
    this.error.alwaysOtvc = undefined;
  }

  onAlwaysChange() {
    this.error.alwaysOtvc = undefined;
    this.isSubmitfn();
    //it check if any change from previous.if no change then it goes to else and disable the submit.
    if (this.checkPreviousValue()) {
      this.validateSubmit();
    } else {
      this.model.isSubmit = false;
    }
  }

  skipOtvc() {
    this.onSkipOtvcRequested.emit();
  }

  isSubmitfn() {
    return ((this.inputs.mobilePhone !== this.originalModel.temp.mobilePhone) ||
      (this.inputs.homePhone !== this.originalModel.temp.homePhone) ||
      (this.inputs.businessPhone !== this.originalModel.temp.businessPhone) ||
      (this.inputs.email !== this.originalModel.temp.email) ||
      (this.inputs.alwaysOtvc !== this.originalModel.temp.alwaysOtvc) || this.model.nonMigratedUser) && this.validateSubmit();

  }

  isSubmitfnPresignon() {
    /*  return (this.inputs.mobilePhone != this.originalModel.pageState.mobilePhone ||
         this.inputs.homePhone != this.originalModel.pageState.homePhone ||
         this.inputs.businessPhone != this.originalModel.pageState.businessPhone ||
         this.inputs.email != this.originalModel.pageState.email) && this.validateSubmit(); */
    return this.validateSubmit();
  }

  closeModal(id: string) {
    this.modalservice.close(id, '');
    setTimeout(() => {
      // this.setFocus('sort-type');
      this.hidePage = false;
    }, 500);
  }

  learnMore() {
    let learnMoreUrl;
    if (this.appStore.lang === Lang.FR) {
      learnMoreUrl = 'https://www.cibc.com/fr/privacy-security/two-step-verification-b.html';
    } else {
      learnMoreUrl = 'https://www.cibc.com/en/privacy-security/two-step-verification-b.html';
    }
    if (this.appStore.isApp()) {
      window.open(learnMoreUrl, '_system', 'location=yes');
    } else {
      window.open(learnMoreUrl, '_blank');
    }
  }


  CountryListReturn(countryObject) {

    if (countryObject) {
      this.countryCode = countryObject.CountryCode;
      // this.EnDesc = countryObject.EnDesc;
      this.DialCode = countryObject.DialCode;

    }
  }

  contactUsLinkClicked() {
    if (this.appStore.isApp()) {
      let contactUrl: string;
      contactUrl = this.globalContent.list.aemList.linkHref2;
      window.open(contactUrl, '_system', 'location=yes');
    } else {
      this.commonService.aemRedirect('contact');
    }
  }

  contactUsLinkClicked_PostSignon() {
    this.router.navigate(['/txn/contactus']);
  }

  getCountryName(obj) {
    const val = obj.Desc.en;
    return val;

  }

  isDesktop() {
    return this.commonService.isDesktop();
  }

}
