import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ContentChild, ElementRef, AfterContentInit } from '@angular/core';
import { ModalService } from '../modal/services/modal.service';
import { isDefined } from 'src/app/shared/services/utils.service';
import { AppStore } from 'src/app/shared/models/app-store';
import { TrackingService } from "src/app/shared/services/tracking.service";
import { of } from 'rxjs';
import { Subject } from 'rxjs';
declare let require: any;
const gatewayConfig = require('src/app/config/gateway-config.json');

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input() public id;
  @Input() public tooltipContent: any;
  @Input() public isMenuPopup;
  @Input() public assetName;
  @Input() public cssclass;
  @Input() public iemobile:boolean = false;
  @Input() public mode = 'popup';
  @Input() public modeModifier = '';
  @Input() public openIconName;
  @Input() public clickOmniture;
  @Input() public largeHitBox: boolean = false;
  @Input() public hoverStates: boolean = false;
  @Input() public staticRight: boolean = false;
  @Input() public hasIconAndBtnLabel: boolean = false;
  @Input() public modalPos = -125;
  @Input() closing: Subject<any>;
  @Input() public hasLink:boolean = false;

  //iOS Fix , focus stucked on icon.
  @Input() public targetEle;

  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickTooltip: EventEmitter<any> = new EventEmitter<any>();
  @ContentChild('tooltiplink') link: ElementRef;

  public showTopTooptip = false;
  public isToopTipOpen = false;
  menuLinks = [];
  isKeyPressed = false;
  previousSelectedItem = null;
  public tooltipLeft = false;
  public tooltipClass;
  constructor(public modalservice: ModalService, public appStore: AppStore, private trackingService: TrackingService) { }
  ngOnInit() {
    this.setIndividualClass();
    if (this.closing) {
      this.closing.subscribe((e) => {
        this.handleCloseTooltip(e);
      });
    }
  }

  ngAfterContentInit(){
    if (this.link) {
      this.link.nativeElement.addEventListener("blur", (e) =>{
        this.defocusTooltip(e);
      })
    }
  }

  ngOnDestroy() {
    if (this.closing) {
      this.closing.unsubscribe();
    }
  }


  keyCodes = () => {
    return {
      enter: 13,
      space: 32,
      left: 37,
      up: 38,
      right: 39,
      down: 40,
      esc: 27,
      tab: 9
    };
  }

  setIndividualClass() {
    this.tooltipClass = this.id;
    if (this.id && this.id.indexOf('__') > -1) {
      this.tooltipClass = this.id.substring(0, this.id.indexOf('__'));
    }
  }

  setFocusForMenu() {
    this.menuLinks = [];
    const element: any = document.getElementById(`content-${this.id}`);

    let menuItem = element.firstElementChild;
    while (menuItem) {
      const menuLink = menuItem.firstElementChild;

      if (menuLink && menuLink.tagName === 'A') {
        this.menuLinks.push(menuLink);
        menuLink.addEventListener('keydown', (e: Event) => { this.keydownTooltip(e); });
        menuLink.addEventListener('blur', (e: Event) => { this.defocusTooltip(e); });
      }
      menuItem = menuItem.nextElementSibling;
    }
    window.requestAnimationFrame(() => {
      this.setFocusToFirstItem();
    });
  }

  handleOpenAndPosition = (e) => {
    const targetButton = e.target;
    const btnDistance = targetButton.getBoundingClientRect();
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    const tooltipContainersHtmlColl = document.getElementsByClassName('tooltipParent');

    this.clickTooltip.emit();
    e.stopPropagation();

    if (this.mode.startsWith('modal') && e.type !== 'mousedown') {
      this.modalservice.open('ToolTip' + this.id, 'tooltipModalClose' + this.id);
    } else if (!this.mode.startsWith('modal')) {
      for (const element of tooltipContainersHtmlColl as any) {
        element.style.display = 'none'; // Hide other tooltips already opened
      }
      const tooltipBox: any = document.getElementById(`tooltipContainer_${this.id}`);
      const tooltipParent: any = document.getElementById(`tooltipParent_${this.id}`);
      setTimeout(() => {
        if (isDefined(tooltipBox) && isDefined(tooltipParent)) {
          if (tooltipParent.style.display != 'block') {
            this.sendOmnitureInteractionData();
          }
          tooltipParent.style.display = 'block';
          this.isToopTipOpen = true;

          // Bottom-Top dynamic position
          if (this.tooltipClass !== 'account-options') {
            const tooltipHeight = tooltipBox.offsetHeight;
            if (targetButton) {
              let tooltipBtnTop = btnDistance.top;
              if (screenWidth < 900) {
                tooltipBtnTop = btnDistance.top - 120;
              }
              // not sufficient space at bottom-show at top
              if ((screenHeight - btnDistance.bottom < tooltipHeight) && (tooltipBtnTop > tooltipHeight)) {

                this.showTopTooptip = true;
              } else {
                this.showTopTooptip = false;
              }
            }
            if (this.tooltipClass !== 'action-menu' && this.tooltipClass !== 'acc-action-menu' && this.tooltipClass !== 'price-info' && this.tooltipClass !== 'buyingPower-info' && this.tooltipClass !== 'add-acc-option' && !this.staticRight) {
              // Left-Right dynamic positioning
              tooltipBox.style.left = this.modalPos + 'px';
              const tooltipDistance = tooltipBox.getBoundingClientRect();
              const offsetLeft = tooltipBox.offsetLeft;
              let whiteSpaceleft;
              if (screenWidth > 600 && screenWidth < 900) {
                whiteSpaceleft = (screenWidth - 600) / 2;
              } else if (screenWidth > 1280) {
                whiteSpaceleft = (screenWidth - 1280) / 2;
              }
              let parentIsModal = tooltipParent;
              while (parentIsModal.nodeName != "BODY" && parentIsModal.nodeName != "APP-MODAL") {
                parentIsModal = parentIsModal.parentElement;
              }
              if (parentIsModal.nodeName == "APP-MODAL") {
                let scrollParent = tooltipParent;
                while (scrollParent.nodeName != "APP-MODAL" && getComputedStyle(scrollParent).overflowY != "auto" && getComputedStyle(scrollParent).overflowY != "scroll") {
                  scrollParent = scrollParent.parentElement;
                }

                if (scrollParent.nodeName != "APP-MODAL") {
                  whiteSpaceleft = (screenWidth - scrollParent.offsetWidth) / 2;

                  if (whiteSpaceleft > tooltipDistance.left) {
                    tooltipBox.style.left = -btnDistance.left + whiteSpaceleft + 15 + 'px';
                  } else if (tooltipDistance.right > screenWidth - whiteSpaceleft) {
                    tooltipBox.style.left = tooltipBox.offsetLeft - (tooltipDistance.right - screenWidth) - whiteSpaceleft - 36 + 'px';
                  } else {
                    tooltipBox.style.left = offsetLeft + 'px';
                  }
                } else {
                  // this case because of white space padding on both sides of screen otherwise following cases will suffice
                  if (((screenWidth > 600 && screenWidth < 900) || screenWidth > 1280) && (whiteSpaceleft > tooltipDistance.left)) {
                    tooltipBox.style.left = -btnDistance.left + whiteSpaceleft + 15 + 'px';
                  } else if (tooltipDistance.right > screenWidth) { // positioning the tooltip if not much room available towards right of screen
                    tooltipBox.style.left = tooltipBox.offsetLeft - (tooltipDistance.right - screenWidth) - 16 + 'px';
                    this.tooltipLeft = false;
                  } else if (tooltipDistance.left < 0) {  // dynamically positioning the tooltip if not much room available towards left of screen
                    tooltipBox.style.left = offsetLeft - tooltipDistance.left + 15 + 'px';
                    this.tooltipLeft = true;
                  } else {
                    tooltipBox.style.left = offsetLeft + 'px';
                    this.tooltipLeft = false;
                  }
                }
              } else {
                // this case because of white space padding on both sides of screen otherwise following cases will suffice
                if (((screenWidth > 600 && screenWidth < 900) || screenWidth > 1280) && (whiteSpaceleft > tooltipDistance.left)) {
                  tooltipBox.style.left = -btnDistance.left + whiteSpaceleft - (targetButton.offsetWidth / 2) + 15 + 'px';
                } else if (tooltipDistance.right > screenWidth) { // positioning the tooltip if not much room available towards right of screen
                  tooltipBox.style.left = tooltipBox.offsetLeft - (tooltipDistance.right - screenWidth) - 16 + 'px';
                  this.tooltipLeft = false;
                } else if (tooltipDistance.left < 0) {  // dynamically positioning the tooltip if not much room available towards left of screen
                  tooltipBox.style.left = offsetLeft - tooltipDistance.left + 15 + 'px';
                  this.tooltipLeft = true;
                } else {
                  tooltipBox.style.left = offsetLeft + 'px';
                  this.tooltipLeft = false;
                }
              }
            }
            if (this.tooltipClass === 'add-acc-option') {
              // Centering arrow
              const openBtn = document.getElementById('openButton_'+ this.id);
              const icon = document.getElementById('icon_'+ this.id);
              const arrow = document.getElementById('arrow_'+ this.tooltipClass);
              const tooltipContainer = document.getElementById('tooltipContainer_' + this.id);
              if (openBtn && arrow && tooltipContainer) {
                let arrowPos = openBtn.clientWidth - icon.clientWidth;
                arrow.style.left = arrowPos + 'px';
                tooltipContainer.style.left =  openBtn.clientWidth - tooltipContainer.clientWidth+ 'px';
              }
            }
          }
          if (this.isMenuPopup) { // for popups having menulinks : e.g. Account-Holdings (three dot) Trade Action Menu
            this.setFocusForMenu();
          } else {
            window.requestAnimationFrame(() => {
              const closeButton: any = document.getElementById(`closeButton_${this.id}`);
              closeButton.focus();
            });
          }
        }
      }, 200);
    }
  }


  handleCloseTooltip = (e) => {
    const tooltipBox: any = document.getElementById(`tooltipParent_${this.id}`);
    if (isDefined(tooltipBox)) {
      tooltipBox.style.display = 'none';
    }

    this.isToopTipOpen = false;
    // added setTimeout fix for tootlip reopening on hitting space in firefox on close button.
    window.requestAnimationFrame(() => {
      setTimeout(() => {
        let openBtn = document.getElementById(`openButton_${this.id}`);
        const userAgent = navigator.userAgent || navigator.vendor;
        if ((/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream)) {
          openBtn = document.getElementById(this.targetEle);
        }
        if (isDefined(openBtn)) {
          openBtn.focus();
        }
      }, 100);

    });
    e.stopPropagation();
  }

  handleClosemodal = (e) => {
    let focusElement1 = this.targetEle;
    if (this.appStore.platform.toLowerCase() !== 'ios') {
      focusElement1 = `openButton_${this.id}`;
    }
    this.closeModal.emit(true);
    this.modalservice.close('ToolTip' + this.id, focusElement1);
  }

  defocusTooltip = (e) => {
    const tooltipBoxElement: any = document.getElementById(`tooltipContainer_${this.id}`);
    window.requestAnimationFrame(() => {
      if (!this.isKeyPressed && !tooltipBoxElement.contains(document.activeElement)) {
        this.handleCloseTooltip(e);
      }
      e.preventDefault();
      e.stopPropagation();
      this.isKeyPressed = false;
    });
  }

  handleKeyDownClose = (e) => {
    const keys = this.keyCodes();
    if (e.keyCode === keys.enter || e.keyCode === keys.space || e.keyCode === keys.esc) {
      this.handleCloseTooltip(e);
      e.preventDefault();
      e.stopPropagation();
      return true;
    }
    const tooltipBox: any = document.getElementById(`tooltipParent_${this.id}`);
    if (tooltipBox.style.display === 'block') {
      if (e.shiftKey) {
        if (e.keyCode === keys.tab) {
          if (isDefined(this.previousSelectedItem)) {
            this.previousSelectedItem.focus();
          }
          e.preventDefault();
          e.stopPropagation();
        } else {
          return true;
        }
      }
    }
  }

  handleKeyDownOpen = (e) => {
    const keys = this.keyCodes();
    const tooltipBox: any = document.getElementById(`tooltipParent_${this.id}`);
    if (tooltipBox.style.display === 'block') {
      if (e.shiftKey) {
        return true;
      }
      if (e.keyCode === keys.tab) {
        if (isDefined(this.previousSelectedItem)) {
          this.previousSelectedItem.focus();
        }
      }
      e.preventDefault();
      e.stopPropagation();
    }
  }

  handleOnFocusTooltipContent = (e) => {

  }//nothing

  keydownTooltip = (e) => {
    this.isKeyPressed = true;
    if (e.altKey) {
      return true;
    }

    const keys = this.keyCodes();
    switch (e.keyCode) {

      case keys.space:
      case keys.enter: {
        if (e.ctrlkey || e.shiftKey) {
          return true;
        }
        // TO DO: select the link
        e.preventDefault();
        e.stopPropagation();
        this.handleCloseTooltip(e);
        return true;
      }
      case keys.esc: {
        this.handleCloseTooltip(e);
        return true;
      }

      case keys.left:
      case keys.up: {
        if (e.shiftKey) {
          return true;
        }
        this.setFocusToPreviousItem(e.target);

        e.preventDefault();
        e.stopPropagation();
        return false;
      }

      case keys.right:
      case keys.down: {

        if (e.shiftKey) {
          return true;
        }

        this.setFocusToNextItem(e.target);
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    }
    return true;
  }

  setFocusToFirstItem = () => {
    if (this.menuLinks && this.menuLinks.length > 0) {
      const firstItem = this.menuLinks[0];
      this.previousSelectedItem = firstItem;
      firstItem.focus();
    }
  }

  setFocusToLastItem = () => {
    if (this.menuLinks && this.menuLinks.length > 0) {
      const lastIndex = this.menuLinks.length - 1;
      const lastItem = this.menuLinks[lastIndex];
      this.previousSelectedItem = lastItem;
      lastItem.focus();
    }
  }

  setFocusToNextItem = (currentItem) => {
    if (this.menuLinks && this.menuLinks.length > 0 && currentItem) {
      const currentIndex = this.menuLinks.indexOf(currentItem);
      if (currentIndex === this.menuLinks.length - 1) {
        this.setFocusToFirstItem();
        return true;
      }

      const nextItem = this.menuLinks[currentIndex + 1];
      this.previousSelectedItem = nextItem;
      nextItem.focus();
    }
  }

  setFocusToPreviousItem = (currentItem) => {
    if (this.menuLinks && this.menuLinks.length > 0 && currentItem) {
      const currentIndex = this.menuLinks.indexOf(currentItem);
      if (currentIndex === 0) {
        if (this.menuLinks.length === 1) {
          return true;
        }
        this.setFocusToLastItem();
        return true;
      }

      const previousItem = this.menuLinks[currentIndex - 1];
      this.previousSelectedItem = previousItem;
      previousItem.focus();
    }
  }

  sendOmnitureInteractionData() {
    if ((window as any).TrackingEnabled && this.clickOmniture) {
      let data = this.trackingService.getFormattedData(this.clickOmniture);
      of(this.trackingService.tagInteraction(data, false, true));
    }
  }
}
