import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, Inject, ViewChild } from '@angular/core';
import { PredictiveSearchService } from './services/predictive-search.service';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { AppStore } from '../../models/app-store';
import { isDefined } from 'src/app/shared/services/utils.service';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ExpansionStates } from '../../models/general.enum';
import { CommonService } from '../../services/common.service';
import { DOCUMENT } from '@angular/common';
import { ObservableSubscriptionService } from '../../services/observable-subscription.service';
import { Lob } from '../../models/lob.enum';
import { TrackingService } from '../../services/tracking.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Component({
  selector: 'app-predictive-search',
  templateUrl: './predictive-search.component.html',
  styleUrls: ['./predictive-search.component.scss']
})
export class PredictiveSearchComponent implements OnInit, OnDestroy, OnChanges {

  @Input() placeholder;
  @Input() labelledbyAttr = 'symbol-label';
  @Input() describedbyAttr = 'symbol-instructional-label symbol-error';
  @Input() scrollAttr;
  @Input() query;
  @Input() contents;
  @Input() pagename;
  @Input() formError;
  @Input() limit = 10;
  @Input() disabled;
  @Input() styling = 'normal';
  @Input() closeSearch: Subject<any>;

  @Output() selectedSymbol: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedExtra: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeQuery: EventEmitter<any> = new EventEmitter<any>();
  @Output() recieveExpansionState: EventEmitter<any> = new EventEmitter<any>();
  @Output() cantfindOption: EventEmitter<any> = new EventEmitter<any>();
  @Output() scrollUpForApp: EventEmitter<any> = new EventEmitter<any>();
  @Output() scrollbackForApp: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('HeaderCover') HeaderCover: any;

  symbols: any = [];
  noResults = false;
  hasError = false;
  loading = false;
  focused = false;
  accessibilityLoadingMessage: any;
  errObject: any;
  error: any;
  url: string;
  value = false;
  resultText: string = undefined;
  activeItem: number = undefined;
  uniqueId: string;
  onFocusOutside: any;
  eventLis: any;
  element: any;
  searchSubscription: Subscription;
  subscriptions: Subscription[] = [];
  subscription = new Subscription();
  oldQuery: string;
  expansionState: string = ExpansionStates.CONTRACTED;
  extraResults: number = 0;
  focusableElments: any;
  lob: string;
  PEEnabled: boolean = false;

  get EXPAN() { return ExpansionStates; }

  constructor(
    private service: PredictiveSearchService,
    public globalcontent: GlobalContentStore,
    public appStore: AppStore,
    public commonService: CommonService,
    private subscriptionService: ObservableSubscriptionService,
    private trackingService: TrackingService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }


  ngOnInit() {
    if (this.closeSearch) {
      this.closeSearch.subscribe((e) => {
        this.contract();
      });
    }
    this.uniqueId = this.appStore.uniqueId;
    setTimeout(() => {
      const element = document.getElementById('symbolInput' + this.uniqueId);
      this.initSubscriptions();
      if (element) {
        element.addEventListener('keydown', (e) => {
          if (e.keyCode === 27) /*escape*/ {
            if (this.symbols.length > 0) {
              this.symbols = [];
              this.activeItem = undefined;
              this.resultText = undefined;
              if (this.pagename == 'Header' || this.pagename == 'IESmallHeader') {
                this.noResults = false;
              }
              e.preventDefault();
            }
          } else if (e.keyCode === 38) /*up*/ {
            if ((this.activeItem === undefined) && (this.symbols.length > 0 || ((this.extraResults > 0 || this.pagename == 'TradeOptions')  && this.noResults))) {
              this.activeItem = this.symbols.length + this.extraResults - 1;
              e.preventDefault();
            } else if (this.symbols.length > 0 || ((this.extraResults > 0 || this.pagename == 'TradeOptions') && this.noResults)) {
              if (this.activeItem === 0) {
                if(this.pagename == 'TradeOptions') {
                  this.activeItem = this.symbols.length;
                } else {
                this.activeItem = this.symbols.length + this.extraResults - 1;
                }
              } else {
                this.activeItem--;
              }
              e.preventDefault();
            }
          } else if (e.keyCode === 40) /*down*/ {
            if ((this.activeItem === undefined) && (this.symbols.length > 0 || ((this.extraResults > 0 || this.pagename == 'TradeOptions') && this.noResults))) {
              this.activeItem = 0;
              e.preventDefault();
            } else if (this.symbols.length > 0 || ((this.extraResults > 0 || this.pagename == 'TradeOptions') && this.noResults)) {
              if(this.pagename == 'TradeOptions') {
                if(this.activeItem === this.symbols.length) {
                  this.activeItem = 0;
                } else {
                  this.activeItem++;
                }
              } else if (this.activeItem === this.symbols.length + this.extraResults - 1) {
                this.activeItem = 0;
              } else {
                this.activeItem++;
              }
              e.preventDefault();
            }
          } else if (e.keyCode === 13) /*enter*/ {
            if (isDefined(this.activeItem)) {
              if (this.activeItem < this.symbols.length) {
                this.selectSymbol(this.symbols[this.activeItem]);
              } else if(this.pagename == 'TradeOptions') {
                this.selectCantfindOption()
              } else {
                this.selectExtraLink(this.activeItem - this.symbols.length);
              }
              e.preventDefault();
            }
          } else if (e.keyCode === 9) /*tab*/ {
            this.symbols = [];
            this.activeItem = undefined;
            this.resultText = undefined;
            if (this.pagename == 'Header' || this.pagename == 'IESmallHeader') {
              this.noResults = false;
            }
            if (this.searchSubscription) {
              this.searchSubscription.unsubscribe();
            }
          }
        });
      }
    }, 100);

    const self = this;
    this.onFocusOutside = (event) => {
      let element = event.target;
      let outside = true;
      while (element != null && outside) {
        if (element.classList.contains('predictive-search')) {
          outside = false;
        }
        element = element.parentElement;
      }
      if (outside) {
        self.onBlur();
      }
    };
    document.addEventListener('mousedown', this.onFocusOutside);

    if (this.pagename == 'Header') {
      this.extraResults = 2;
    }

    if (this.pagename == 'IESmallHeader') {
      this.subscription.add(this.subscriptionService.stringLob.subscribe(
        (data) => {
          if (data) {
            this.lob = (data.toLowerCase() === 'cfpi') ? 'iis' : data.toLowerCase();
            if (this.lob === Lob.IE) {
              this.PEEnabled = this.appStore.state.user.premiumUser;
            }
            else {
              this.PEEnabled = false;
            }
          }
        }
      ));
    }
  }

  ngOnDestroy() {
    document.removeEventListener('mousedown', this.onFocusOutside);
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.subscriptions) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetResults();
    if (isDefined(changes.query)) {
      this.oldQuery = undefined;
    }
  }

  /*shift , ctrl, alt, tab, enter, escape, up, down, left, right*/
  onKeyUp(e, query) {
    if (e.keyCode === 17 || e.keyCode === 18 ||
      e.keyCode === 9 || e.keyCode === 13 || e.keyCode === 27 ||
      e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40) {
      // Do Nothing
    } else {
      this.search(query);
    }
  }

  search(query) {
    const request = {
      QueryString: query.trim(),
      SearchCriteria: {
        Target: 'both',
        OperationType: 'startsWith'
      },
      Limit: this.limit
    };
    this.url = gatewayConfig.APIServices.quoteSymbolSearch.url;
    this.hasError = false;
    this.activeItem = undefined;
    if (this.query.trim().length > 0) {
      if (this.oldQuery !== request.QueryString) {
        if (this.searchSubscription) {
          this.searchSubscription.unsubscribe();
        }
        let self = this;
        this.searchSubscription = this.service.search(request, this.url).subscribe(
          (data: any) => {
            self.symbols = data.Symbols;
            self.oldQuery = request.QueryString;
            if ((self.symbols === undefined)) {
              self.symbols = [];
              self.activeItem = undefined;
              self.resultText = undefined;
            } else {
              self.resultText = undefined;
              setTimeout(() => {
                self.resultText = self.symbols.length + self.globalcontent.text.predictiveResultFound;
              }, 1);
            }
            if (self.query.trim().length === 0 || !self.focused) {
              self.noResults = false;
              self.symbols = [];
              self.activeItem = undefined;
              self.resultText = undefined;
              self.accessibilityLoadingMessage = '';
            } else if (data.Symbols === undefined) {
              self.noResults = true;
              self.accessibilityLoadingMessage = self.contents.error.MSGGLB076.message;
              self.symbols = [];
              self.activeItem = undefined;
              self.resultText = undefined;
            } else if (data) {
              self.noResults = false;
                if(this.pagename == 'TradeOptions' || this.pagename == 'Option-centre' || this.pagename == 'OptionCentral') {
                  self.symbols = self.symbols.filter((symbol) => (symbol.OptionEnabled));

                  if(self.symbols.length < 1) {
                    self.noResults = true;
                  }
                }
              self.loading = true;
              self.accessibilityLoadingMessage =
                self.createResultsMessage(
                  data.Symbols.length,
                  self.contents.text.resultsReturn
                );
              setTimeout(() => {
                self.accessibilityLoadingMessage = '';
                self.loading = false;
              }, 1000);
            }
            self.searchSubscription = undefined;
            self.sendOmniture(request.QueryString, data.Symbols);
          },
          err => {
            self.searchSubscription = undefined;
            self.oldQuery = request.QueryString;
            if (err) {
              self.error = err;
              console.log(err);
            }
          });
      }
    } else {
      this.noResults = false;
      this.symbols = [];
      this.activeItem = undefined;
      this.resultText = undefined;
      this.oldQuery = undefined;
    }
  }

  retrySearch() {
    this.search(this.query);
  }

  private initSubscriptions() {
    const symbolInputElement = document.getElementById('symbolInput' + this.uniqueId);
    if (symbolInputElement) {
      this.subscriptions.push(
        fromEvent(symbolInputElement, 'keyup').pipe(debounceTime(400))
          .subscribe(data =>
            this.onKeyUp((data as KeyboardEvent), (data.target as any).value))
      );
      this.subscriptions.push(fromEvent(symbolInputElement, 'paste').pipe(debounceTime(400))
        .subscribe(data =>
          this.search((data.target as any).value))
      );
    }
  }

  handleError(err) {
    if (err.error instanceof Error) {
      // this.errObject = this.errorHandler.handleAppError(err);
      return this.errObject;
    } else {
      // this.errObject = this.errorHandler.handleAppError(err);
      if (this.errObject.reason === 'FORM_VALIDATION_ERROR') {
        return this.errObject;
      } else {
        // this.errorEventHandler.setError(this.errObject);
        if (this.errObject.reason === 'PAGE_ERROR') {
        }
        return this.errObject;
      }
    }
  }

// Only for GA
  sendOmniture(queryString, data) {
    if (isDefined(this.pagename)) {
        let omniturePageName;
        if (this.pagename == 'TradeStocks') {
            omniturePageName = "Trade - Stock Search Input";
        } else if (this.pagename == 'TradeOptions') {
            omniturePageName = "Trade - Options Search Input";
        } else if ( this.pagename == 'Header' || this.pagename == 'IESmallHeader') {
          omniturePageName = "Header - Global search"
        } else if (this.pagename == 'Option-centre') {
          omniturePageName = "Option-centre"
        } else if (this.pagename === 'TradeMLS') {
          omniturePageName = "Trade - Multi leg Options Search Input"
        }
        else {
            omniturePageName = "Pre-Sign On - Get a Quote";
        }
        if ((window as any).TrackingEnabled) {
          const siteSearch = {
            results: data[0] ? data.length.toString() : '0',
            term: queryString,
            currentPage: "1"
          };
          this.trackingService.tagHelpInteraction(omniturePageName, siteSearch, 'G');
        }
    }
  }

  createResultsMessage(numResults, messageTemplate) {
    return messageTemplate.replace('{numResults}', '' + numResults);
  }

  selectSymbol(symbol) {
    console.log(symbol.SymbolName);
    setTimeout(() => {
      this.symbols = [];
      this.activeItem = undefined;
      this.resultText = undefined;
    }, 100);
    this.selectedSymbol.emit(symbol);
    // alert(document.getElementById('symbolInput' + this.uniqueId))
    setTimeout(() => {
      document.getElementById('symbolInput' + this.uniqueId).blur();
      this.scrollbackForApp.emit();
    }, 100);
    
    if (this.expansionState == ExpansionStates.EXPANDED) {
      this.contract();
    } else {
      this.setFocus('symbolInput' + this.uniqueId);
    }
  }

  selectExtraLink(i) {
    if (this.pagename == 'Header') {
      setTimeout(() => {
        this.noResults = false;
        this.symbols = [];
        this.activeItem = undefined;
        this.resultText = undefined;
      }, 100);
      if (i == 0) {
        this.selectedExtra.emit({ url: "/txn/bridge/quotesResearch", crossFlow: { action: 'qlStocks' } });
      } else if (i == 1) {
        this.selectedExtra.emit({ url: "/txn/quotesResearch/watchLists" });
      }
    }
  }

  selectCantfindOption(){
    this.cantfindOption.emit(true);
      // this.contract();
  }
  scrollToTop() {
    this.onFocus();
    if((this.pagename == 'TradeOptions' || this.pagename == 'Option-centre')  && this.appStore.isApp()) {
      this.scrollUpForApp.emit();
      setTimeout(() => {
        let el:any = document.getElementById(this.scrollAttr);
        if(el) {
          window.scrollTo({
            top: el.parentNode.offsetTop + 150,
            behavior: "smooth",
          });
        }
      }, 100);
    }
  }
  change(q) {
    this.changeQuery.emit(q);
  }
  onBlur() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    this.focused = false;
    if (this.noResults) {
      this.noResults = false;
    }
    this.symbols = [];
    this.activeItem = undefined;
    this.resultText = undefined;
  }

  setFocus(targetElementId) {
    if (targetElementId) {
      const targetElement = document.getElementById(targetElementId);
      window.requestAnimationFrame(() => {
        targetElement.focus();
      });
    }
  }

  resetResults() {
    if (!this.value) {
      setTimeout(() => {
        this.symbols = [];
        this.activeItem = undefined;
        this.resultText = undefined;
      }, 100);
    }
  }

  onFocus() {
    this.focused = true;
  }
  setFlag(market) {
    if (market === 'US') {
      return 'assets/images/icons/icon-us-flag.svg';
    }
    if (market === 'CA') {
      return 'assets/images/icons/icon-can-flag.svg';
    }
  }

  onMouseEnter(i) {
    this.activeItem = i;
  }

  listClasses(i) {
    let classes = '';
    if (i < this.symbols.length && this.symbols[i].OptionEnabled && (this.pagename === 'TradeOptions' || this.pagename === 'TradeMLS')) {
      classes += ' optioned';
    }
    if (i === this.activeItem) {
      classes += ' activatedItem';
    }

    return classes;
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }


  expand() {
    this.getCoverHeight();

    this.expansionState = this.EXPAN.EXPANDING;
    this.recieveExpansionState.emit(this.expansionState);
    this.trapFocus();
    setTimeout(() => {
      this.expansionState = this.EXPAN.EXPANDED;
      this.recieveExpansionState.emit(this.expansionState);
      let el = this.document.getElementById('symbolInput' + this.uniqueId);
      if (el) {
        el.focus();
      }
    }, 195);
  }

  contract() {
    this.getCoverHeight();

    this.query = undefined;
    this.oldQuery = undefined;
    this.resetResults();
    this.expansionState = this.EXPAN.CONTRACTING;
    this.recieveExpansionState.emit(this.expansionState);
    this.undoTrapFocus();
    setTimeout(() => {
      this.expansionState = this.EXPAN.CONTRACTED;
      this.recieveExpansionState.emit(this.expansionState);
    }, 195);
  }

  trapFocus() {
    this.focusableElments = {}
    this.focusableElments['IESmallHeaderSearch' + this.uniqueId] = { focusableElements: [] };
    this.commonService.setTabIndex('IESmallHeaderSearch' + this.uniqueId, '', this.focusableElments, '', 'app-predictive-search', '');
    this.document.body.style.overflow = 'hidden';
  }

  undoTrapFocus() {
    const id = 'IESmallHeaderSearch' + this.uniqueId;
    // untrap focus for hamburger
    this.commonService.undoTrapFocus(this.focusableElments[id].focusableElements, id);
    this.document.body.style.overflow = 'auto';
  }

  clearQuery() {
    this.query = undefined;
    this.resetResults();
  }

  getCoverHeight() {
    this.HeaderCover.nativeElement.style.height = Math.round(document.getElementById('page-header').getBoundingClientRect().height) + 'px';
  }
}
