<div class="tooltip tooltip_{{id}}">
  <button class="tooltipIcon tooltipIcon_{{id}} {{openIconName}}" (click)="handleOpenAndPosition($event)" type="button"
    id="openButton_{{id}}" attr.aria-label="{{content?.text?.dateButtonAlt}}" [attr.aria-haspopup]="true"
    (keydown)="handleKeyDownOpen($event)">
  </button>

  <div id="tooltipParent_{{id}}" style="display: none" class="tooltipParent">
    <div class="tooltipContainer" [class]="tooltipClass" id="tooltipContainer_{{id}}"
      (keydown.esc)="handleCloseTooltip($event)" tabindex="-1">

      <div class='container' id="container" *ngIf="isDatePicker">
        <app-date-picker #datePicker [minDate]='min' [maxDate]='max' [TransactionMinDate]='transactionMin'
          [TransactionMaxDate]='transactionMax' [startDate]='startDate' [content]='content'
          (dateSelected)="dateSelected($event)" (hideErrorMessageEvent)="hideErrorMessage($event)"></app-date-picker>
      </div>

      <div class="cancelDiv">
        <div class="errorBlock">
          <app-field-level-error [error]="invalidDate" [Id]="'invalid-date-error'+id">
          </app-field-level-error>
        </div>
        <div class="cancelBtnDiv">
          <button class='button primary cancelBtn' type="button" role="button" (click)="handleCloseTooltip($event)"
            [id]="'closeButton'+id" attr.aria-label="{{content?.text?.closeButton}}"
            (keydown)="handleKeyDownClose($event)"
            (keydown.esc)="handleCloseTooltip($event)">{{content?.text?.closeButton}}</button>
        </div>
      </div>
    </div>
  </div>
</div>