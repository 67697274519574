import { RouteGroup, RouteConfig, OmniturePageDetail } from '../shared/models/route-config';
import { PreTxnConfig } from './pre-txn-router-mapping';
import { AccountsConfig } from './accounts-router-mapping';
import { TradeStocksConfig } from './trade-stocks-router-mapping';
import { OrderStatusConfig } from './order-status-router-mapping';
import { CashtransferConfig } from './cashtransfer-router-mapping';
import { TransactionHistoryConfig } from './transaction-history-router-mapping';
import { MutualFundsConfig } from './mutual-funds-router-mapping';
import { TradeOptionsConfig } from './trade-options-router-mapping';
import { OtvcConfig } from './otvc-router-mapping';
import { sso_config } from './sso-router-mapping';
import { QuotesResearchConfig } from './quotes-research-router-mapping';
import { QuotesResearchBridgeConfig } from './quotes-research-bridge-router-mapping';
import { QuotesConfig } from './quotes-watchlist-router-mapping';
import { PRE_TXN_URL } from 'src/app/config/pre-txn-router-mapping';
import { userPreferences_config } from './userPreferences-router-mapping';
import { HelpConfig } from './help-router-mapping';
import { EDocumentsConfig } from './edocuments-router-mapping';
import { NewsAndNotesConfig } from './news-and-notes-router-mapping';
import { AccountDetailsConfig } from './account-details-router-mapping';
import { ContactUsConfig } from './contact-us-router-mapping';
import { FixedIncomeConfig } from './fixed-income-router-mapping';
import { IposAndNewIssuesConfig } from './ipos-and-newissues-router-mapping';
import { RegularInvestmentPlanConfig } from './regular-investment-plan-router-mapping';
import { AboutMyInvestmentCounsellorConfig } from './about-my-investment-counsellor-router-mapping';
import { quotesAndWatchListsMarketsConfig } from './quotes-and-watchlists';
import { MarketsMobiConfig } from './markets-router-mapping';
import { CreditPolicyConfig } from './credit-policy-router-mapping';
import { FeesCommissionConfig } from './fees-commission-router-mapping';
import { SiteMapConfig } from './site-map-router-mapping';
import { AgreementsAndDisclosuresConfig } from './agreements-and-disclosures-router-mapping';
import { PerformanceReportingConfig } from './performance-reporting-router-mapping';
import { StructNotesConfig } from './struct-notes-router-mapping';
import { TradeMLSConfig } from './trade-MLS-router-mapping';
import { ExchangeCurrencyConfig } from './exchange-currency-router-mapping';

export const ROUTE_CONFIG: RouteGroup = {};

export function omniturePageConfig(url: string): OmniturePageDetail {
  let cfg: RouteConfig = routeConfig(url);
  if (cfg.trackingData)
    return { name: cfg.name, url: url, data: cfg.trackingData };
  else
    return;
}


export function routeConfig(url: string): RouteConfig {
  let reqURL: string;
  if (url.startsWith(PRE_TXN_URL.HOME)) {
    reqURL = PRE_TXN_URL.HOME;
  } else if (url.startsWith(PRE_TXN_URL.AEM)) {
    reqURL = PRE_TXN_URL.AEM;
  } else if (url.startsWith(PRE_TXN_URL.WGONLINEREGISTERVERIFICATION)) {
    reqURL = PRE_TXN_URL.WGONLINEREGISTERVERIFICATION;
  } else {
    reqURL = url;
  }
  let output = reqURL;
  if (output) {
    const outputList = output.split('/');
    output = '';
    for (let i = 0; i < outputList.length; i++) {
      if (outputList[i] !== '' && outputList[i] !== 'ie' && outputList[i] !== 'iis' && outputList[i] !== 'cfpi'
        && outputList[i] !== 'wg' && outputList[i] !== 'cpic' && outputList[i] !== 'gen'
        && outputList[i] !== 'fr' && outputList[i] !== 'en') {
        output += '/' + outputList[i];
      }
    }
  }
  return ROUTE_CONFIG[output];
}

export function addModuleMapping(routerMapping: RouteGroup) {
  Object.assign(ROUTE_CONFIG, ROUTE_CONFIG, routerMapping);
}


/* ADD entry here for new modules */

addModuleMapping(PreTxnConfig);
addModuleMapping(AccountsConfig);
addModuleMapping(TradeStocksConfig);
addModuleMapping(TradeOptionsConfig);
addModuleMapping(TradeMLSConfig);
addModuleMapping(OrderStatusConfig);
addModuleMapping(CashtransferConfig);
addModuleMapping(TransactionHistoryConfig);
addModuleMapping(MutualFundsConfig);
addModuleMapping(OtvcConfig);
addModuleMapping(userPreferences_config);
addModuleMapping(sso_config);
addModuleMapping(QuotesResearchConfig);
addModuleMapping(QuotesResearchBridgeConfig);
addModuleMapping(QuotesConfig);
addModuleMapping(HelpConfig);
addModuleMapping(EDocumentsConfig);
addModuleMapping(NewsAndNotesConfig);
addModuleMapping(AccountDetailsConfig);
addModuleMapping(ContactUsConfig);
addModuleMapping(FixedIncomeConfig);
addModuleMapping(IposAndNewIssuesConfig);
addModuleMapping(RegularInvestmentPlanConfig);
addModuleMapping(AboutMyInvestmentCounsellorConfig);
addModuleMapping(quotesAndWatchListsMarketsConfig);
addModuleMapping(MarketsMobiConfig);
addModuleMapping(CreditPolicyConfig);
addModuleMapping(FeesCommissionConfig);
addModuleMapping(SiteMapConfig);
addModuleMapping(AgreementsAndDisclosuresConfig);
addModuleMapping(PerformanceReportingConfig);
addModuleMapping(StructNotesConfig);
addModuleMapping(ExchangeCurrencyConfig);
