import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppStore } from '../../models/app-store';
import { Lob } from '../../models/lob.enum';
import { GlobalContentStore } from '../../store/global-content-store';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");


@Component({
  selector: 'app-transfer-card',
  templateUrl: './transfer-card.component.html',
  styleUrls: ['./transfer-card.component.scss']
})
export class TransferCardComponent implements OnInit {

  @Output() selectedCard = new EventEmitter();
  @Input() cardContent: any;
  @Input() account: any;
  @Input() id: any;
  @Input() public index = -1;
  uniqueId: string;

  constructor(
    public appStore: AppStore,
    public globalContent: GlobalContentStore
  ) { }

  ngOnInit() {
    this.uniqueId = this.appStore.uniqueId;
  }

  next() {
    this.selectedCard.emit(this.index);
  }
}
