import {
  Component,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  ViewEncapsulation,
  AfterViewInit,
  Inject
} from '@angular/core';
import { Moment } from 'moment';
import moment from 'moment';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DatePickerHeader } from './date-picker-header/date-picker-header.component';
import { DatePickerAdapter } from './date-picker.adapter';
import { AppStore } from '../../../shared/models/app-store';
import { Lang } from '../../../../app/shared/models/lob.enum';
import { DOCUMENT } from '@angular/common';
import { DatePickerHeaderTransaction } from './date-picker-header-transaction/date-picker-header-transaction.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface dateArgs {
  date: Moment,
  valid: boolean
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: DatePickerAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
})
export class DatePickerComponent implements OnChanges, AfterViewInit {
  @Input() content: any;

  /** The minimum selectable date. */
  @Input()
  get minDate(): Date { return this._minDate; }
  set minDate(value: Date) { this._minDate = value; }
  private _minDate: Date;

  @Input()
  get maxDate(): Date { return this._maxDate; }
  set maxDate(value: Date) { this._maxDate = value; }
  private _maxDate: Date;

  /** The minimum selectable dates for transaction history. */
  @Input()
  get TransactionMinDate(): Date { return this._TransactionMinDate; }
  set TransactionMinDate(value: Date) { this._TransactionMinDate = value; }
  private _TransactionMinDate: Date;

  @Input()
  get TransactionMaxDate(): Date { return this._TransactionMaxDate; }
  set TransactionMaxDate(value: Date) { this._TransactionMaxDate = value; }
  private _TransactionMaxDate: Date;

  @Input()
  get startDate(): Date { return this._startDate; }
  set startDate(value: Date) { this._startDate = value; }
  private _startDate: Date;

  @Output()
  dateSelected = new EventEmitter<dateArgs>();

  @Output()
  hideErrorMessageEvent = new EventEmitter<boolean>();

  @Output()
  selectedDate = moment();

  @ViewChild('calendar', { static: true })
  calendar: MatCalendar<Moment>;

  datePickerHeader: any = DatePickerHeader;
  dateClass: MatCalendarCellCssClasses;
  lang: string;

  calMinDate: any;
  calMaxDate: any;
  ariaDateValueEls: any;
  ariaDateEnabledValueEls: any;

  userAgent = navigator.userAgent || navigator.vendor;

  constructor(
    private appStore: AppStore,
    private _adapter: DateAdapter<any>,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngAfterViewInit() {
    this.calendar.focusActiveCell();
  }

  ngDoCheck() {
    if (this.TransactionMaxDate && this.TransactionMinDate) {
      this.datePickerHeader = DatePickerHeaderTransaction;
    } else {
      this.datePickerHeader = DatePickerHeader;
    }
    this.ariaDateValueEls = this.document.querySelectorAll(".calendar-body-disabled");
    let index = 0;
    [].forEach.call(this.ariaDateValueEls, (e) => {
      index++;
      let value = e.getAttribute('aria-label');
      if (value.indexOf(this.content.text.unselectedDate) < 0) {
        e.setAttribute('aria-label', value + " " + this.content.text.unselectedDate);
        const content = value + " " + this.content.text.unselectedDate;
        this.setAccessibilityContent(e, content);
      }
    });

    this.ariaDateEnabledValueEls = this.document.querySelectorAll(".custom-date-class");
    let indexEnabled = 0;
    [].forEach.call(this.ariaDateEnabledValueEls, (e) => {
      indexEnabled++;
      let value = e.getAttribute('aria-label');
      this.setAccessibilityContent(e, value);
    });
  }

  setAccessibilityContent(ele, content) {
    const node = ele.childNodes[0];

    if ((window.navigator.userAgent.indexOf('Safari') != -1 &&
      window.navigator.userAgent.indexOf('Chrome') == -1) ||
      ((<any>window).webkit && (<any>window).webkit.messageHandlers)) {
      if (node.nodeName == 'DIV') {
        const childNode = node.childNodes[0];
        if (childNode.nodeName === '#text') {
          var ariaPara = document.createElement("span");
          var ariaParaText = document.createTextNode(content);
          ariaPara.setAttribute("class", "sr-only");
          ariaPara.appendChild(ariaParaText);
          node.appendChild(ariaPara);

          var textPara = document.createElement("span");
          var textParaText = document.createTextNode(node.childNodes[0].nodeValue)
          textPara.setAttribute("aria-hidden", "true");
          textPara.appendChild(textParaText);
          node.appendChild(textPara);
          node.removeChild(node.childNodes[0]);
        }
      }
    }

    if (/android/i.test(this.userAgent)) {
      ele.setAttribute("aria-disabled", false);
    }
  }

  ngOnChanges() {
    this.lang = this.appStore.state.user.lang;
    this.lang == Lang.FR ? this._adapter.setLocale('fr') : this._adapter.setLocale('en');

    this.calendar.activeDate = this.selectedDate = moment(this.startDate);
    this.calMinDate = this._adapter.createDate(this._minDate.getFullYear(), this._minDate.getMonth(), 1);
    this.calMaxDate = this._adapter.createDate(this._maxDate.getFullYear(), this._maxDate.getMonth(), moment([this._maxDate.getFullYear(), this._maxDate.getMonth()]).daysInMonth());
    if (!this.TransactionMaxDate && !this.TransactionMinDate) {
      this.dateClass = (d: Date): MatCalendarCellCssClasses => {
        const date = new Date(d);
        return (
          date >= this.minDate &&
          date <= this.maxDate &&
          date.getDay() != 0 &&
          date.getDay() != 6
        ) ?
          'custom-date-class' : 'calendar-body-disabled';
      };
    } else {
      this.dateClass = (d: Date): MatCalendarCellCssClasses => {
        const calD: any = new Date(d);
        const date = Date.parse(calD);
        const mindate: any = new Date(this.TransactionMinDate);
        const maxdate: any = new Date(this.TransactionMaxDate);
        return (
          date >= Date.parse(mindate) &&
          date <= Date.parse(maxdate)
        ) ?
          'custom-date-class' : 'calendar-body-disabled';
      };
    }
  }

  hideErrorMessage(event) {
    if (event.keyCode !== 13) {
      this.hideErrorMessageEvent.emit(true);
    }
  }

  dateChanged() {
    this.calendar.activeDate = this.selectedDate;
    const date = (this.calendar.activeDate).toDate();
    if (!this.TransactionMinDate && !this.TransactionMaxDate) {
      if (date.getDay() != 0 && date.getDay() != 6 && date >= this.minDate && date <= this.maxDate) {
        this.dateSelected.emit({ date: this.selectedDate, valid: true });
      } else {
        this.dateSelected.emit({ date: this.selectedDate, valid: false });
      }
    } else {
      const calD: any = new Date(date);
      const calDate = Date.parse(calD);
      const mindate: any = new Date(this.TransactionMinDate);
      const maxdate: any = new Date(this.TransactionMaxDate);
      if (calDate >= Date.parse(mindate) && calDate <= Date.parse(maxdate)) {
        this.dateSelected.emit({ date: this.selectedDate, valid: true });
      } else {
        this.dateSelected.emit({ date: this.selectedDate, valid: false });
      }
    }
  }
}