import { Lang } from "./lob.enum";

export interface IOmnitureData {
  site?: IOmnitureSiteData;
  page?: IOmniturePageData;
  user?: IOmnitureUserData;
  events?: IOmnitureEventData;
  transaction?: IOmnitureTransactionID;
  trade?: IOmnitureTradeItems;
  interaction?: any;
  form?: IOmnitureFormData;
  errors?: Array<IOmnitureErrorData> | any;
  download?: IOmnitureDownloadData;
  advertising?: Array<IOmnitureAdsData>;
  siteSearch?: IOmnitureSiteSearch;
}

export interface IOmnitureSiteSearch {
  results?: string;
  term?: string;
  currentPage?: string;
  filters?: string[];
  categories?: string;
}

export interface IOmnitureEventData {
  appointmentSet?: boolean;
  login?: boolean;
  error?: boolean;
  download?: boolean;
  exit?: boolean;
  adImpression?: boolean;
  adClick?: boolean;
  applicationPersonalDetails?: boolean;
  applicationProductRecommendations?: boolean;
  applicationSummary?: boolean;
  applicationFulfillment?: boolean;
  applicationTermsConditions?: boolean;
  applicationConfirmation?: boolean;
  appComplete?: boolean;
  applicationApproved?: boolean;
  applicationDeclined?: boolean;
  applicationReferred?: boolean;
  applicationFailed?: boolean;
  tradeSubmit?: boolean;
  transactionComplete?: boolean;
  systemCancelled?: boolean;
  productPageView?: boolean;
  pageView?: boolean;
  formView?: boolean;
  siteInteraction?: boolean;
  formSubmit?: boolean;
  formQualify?: boolean;
  formPrefill?: boolean;
  formStep?: boolean;
  siteSearch?: boolean;
  onlineAccount?: boolean;
}

export interface IOmnitureSiteData {
  brand: string;
  name: string;
  type: string;
  environment: string;
  appVersion: string;
  lastBuildDate?: string;
}

export interface IOmniturePageData {
  name: string;
  nameGA?: string;
  url: string;
  referrer: string;
  hierarchy: string | Array<string>;
  language: Lang.EN | Lang.FR;
  accessibility: boolean;
}

export interface IOmnitureUserData {
  authState: "authenticated" | "non-authenticated";
  authType?: string | "user/pass";
  type: string;
  ID?: string;
}

export interface IOmnitureFormData {
  name: string;
  stepName: string;
  uniqueID?: string;
  prefillMethod?: string;
  appointmentType?: string;
  appointmentCategory?: string;
  appointmentSubCategory?: string;
  appointmentTime?: string;
  formStep?: boolean;
}

export interface IOmnitureTransactionData {
  transaction: IOmnitureTransactionID;
  trade?: IOmnitureTradeItems;
}

export interface IOmnitureInteractionData {
  name: string;
}

export interface IOmnitureTransactionID {
  ID: string;
  items?: IOmnitureItems[];
}

export interface IOmnitureItems {
  amount?: string;
  units?: string;
  serviceFee?: string;
  from?: IOmnitureHoldingItems;
  to?: IOmnitureHoldingItems;
  isExternal?: string;
}

export interface IOmnitureHoldingItems {
  holding: string;
}

export interface IOmnitureProductsData {
  id: string;
  marketingSiteSection: string;
  positioning: string;
  grouping: string;
  parentProduct: string;
  fulfillment: string;
  adjudication: string;
  joint: boolean;
  recommended: boolean;
  paperless: boolean;
}

export interface IOmnitureErrorData {
  type?: string;
  subtype?: string;
  field?: string;
  code?: string;
}

export interface IOmnitureTradeItems {
  action: string;
  type: string;
  symbol: string;
  market: string;
  exchange: string;
  quote: string;
  quantity: string;
  dividend: string;
  priceType: string;
  expiry: string;
  account: string;
  serviceFee: string;
  value: string;
  currency: string;
  foreignExchange: string;
}

export interface IOmnitureTransactionFieldNames {
  action?: string;
  type?: string;
  symbol?: string;
  market?: string;
  exchange?: string;
  quote?: string;
  quantity?: string;
  dividend?: string;
  priceType?: string;
  expiry?: string;
  account?: string;
  serviceFee?: string;
  value?: string;
  currency?: string;
  foreignExchange?: string;
  amount?: string;
  units?: string;
  from?: string;
  to?: string;
  isExternal?: string;
  currencyPayFrom?: string;
  currencyPayTo?: string;
  fromAccountType?: string;
  toAccountType?: string;
  institutionalNameFrom?: string;
  institutionalNameTo?: string;
  frequency?: string;
}

export interface IOmnitureDownloadData {
  filename: string;
  filetype: string;
}

export interface IOmnitureAdsData {
  trackingCode: string;
  location: string;
  type: string;
}

export interface IOmnitureMlsFieldNames {
  account?:string;
  currency?:string;
  expiry?:any;
  optionStrategy?:string;
  leg1Action?:string;
  leg1Type?:string;
  leg1Qty?:string;
  leg1Symbol?:any;
  leg2Action?:string;
  leg2Type?:string;
  leg2Qty?:string;
  leg2Symbol?:any;
  priceType?:string;
  limitAmount?:string;
  tradePosition?:string;
   
}

export interface IOmnitureMlsTransactionData {
  transaction: IOmnitureTransactionID;
  trade?: IOmnitureMlsFieldNames;
}
