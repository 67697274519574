<section id="dropdownlist{{id}}" class="{{fontNormal}} color02 accountSelector">
  <button id="ddButton{{id}}" type="button" class="selector-button {{style}}" aria-haspopup="listbox"
    (click)="setDropDownState(!open)"
    [ngClass]="{'cpic-button': lob ==='cpic', 'regularDropdown-selector-button': regularDropdown, 'disabled': disabled, 'open': open, 'has-field-error': error, 'selector-button-fx': mode === 'currency', 'selector-button-mls': mode === 'holdings-mls'}"
    [inputErrorDirective]="formError">
    <span class="choose-one-opt-1" *ngIf="chooseOne">{{paramPageContent.text.chooseOne}}</span>
    <span class="choose-one-opt-2" *ngIf="!chooseOne">
      <ng-container [ngTemplateOutlet]="optionTemplate || defaultItem"
        [ngTemplateOutletContext]="{option: accountDisplayName, displayName: true, mode: mode}">
      </ng-container>
    </span>
    <i class="icon-Chevron-Down icon-pos color02" [ngClass]="mode == 'currency' ? 'icon-pos-fx' : ''"></i>
  </button>
  <div id="results{{id}}" class="results {{style}}" *ngIf="open"
    [ngClass]="{'cpic-button': lob ==='cpic', 'cpicResults': (lob ==='wg' || lob === 'cpic'), 'dropdown-full-width': dropdownFullWidth}" [inputErrorDirective]="error">
    <ul role="listbox" aria-label="dropdownlist">
      <li class="group" id="ASDD{{option.Id}}{{id}}" *ngFor="let option of singleAccountList; let i= index;"
        (click)="optionSelected(option, i)" role="option" tabindex="0" (keyup)="onKeyUp($event, option)"
        (keydown)="onKeyDown($event)" (blur)="onBlur($event)" (mouseenter)="onMouseEnter($event)"
        [attr.disabled]="option.disabled" [ngClass]="{'disabled': option.disabled, 'isFooter': option.isFooter}">
        <div [ngClass]="{'account': !displayName, 'footer': option.isFooter}">
          <ng-container [ngTemplateOutlet]="optionTemplate || defaultItem"
            [ngTemplateOutletContext]="{option: option, mode: mode}">
          </ng-container>
        </div>
      </li>
    </ul>
  </div>
</section>
<ng-template let-option="option" let-displayName="displayName" let-mode="mode" #defaultItem>
  <img class="flag" *ngIf="option.icon && mode != 'holdings' && mode != 'holdings-mls'" aria-hidden="true"
    src="assets/images/icons/{{option.icon}}.svg" [alt]="option.iconAlt">
  <img class="flag" [src]="setFlag(option.icon)" aria-hidden="true" [alt]="option.iconAlt"
    *ngIf="option.icon && !option.isFooter && (mode == 'holdings' || mode == 'holdings-mls')">
  <span class="color02 option-icon icon-Search" *ngIf="option.icon && option.isFooter && mode == 'holdings'">
  <span class="sr-only"> {{ this.pageContent.text.typeSymbolSR }}</span></span>
  <span [ngClass]="(option.isFooter && mode == 'holdings') || (mode == 'currency') ? fontNormal : (mode == 'holdings-mls'? (option.disabled ? 'holdings-mls-color02' : 'holdings-mls'): fontBold)" [innerHTML]="option.name"></span>
  <span *ngIf="option.suffix && mode != 'holdings-mls'">-{{option.suffix}}</span>
</ng-template>