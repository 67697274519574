<!-- hamburger menu -->
<div *ngIf=" !isDesktop() && navigationObject && openMenu && enableNavigation">
  <app-hamburger-menu [navigationObject]=navigationObject [moreContent]="content" (close)="closeMenu()">
  </app-hamburger-menu>
</div>

<!--Desktop and Mobile page-header only for myaccounts -->
<div class="{{targetLob}}StickyNav {{showNav ? '' : 'hideNav'}}">
  <div class="header-grey-cover" *ngIf="greyedOut != EXPAN.CONTRACTED"
    [ngClass]="greyedOut == EXPAN.CONTRACTING ? 'fading-out' : ''" (click)="closeSymbolSearch()"></div>
  <div id="page-header" class="pageHeader"
    [ngClass]="{'fixedHeader' : isQuoteResearch(), 'printHeaderDesktop' : isDesktop(), 'PEBanner' : PEEnable , 'pageHeader': !PEEnable, 'header-white': targetLob == 'ie', 'raised': greyedOut, 'refreshBackground' : !this.isDesktop() && checkLob('IE') && showGlobalRefresh, 'iePageHeader': (!PEEnable && appStore.lob == 'ie') }">
    <app-header (menuClicked)="openMenuClicked()" [breakPoint]="isDesktop()" [enableLogoClick]="enableNavigation"
      [cibcLogoWithDesc]="showTwolevelsNavigation" [showChevron]="showChevron" [unreadNews]="unreadNews"
      (greyOut)="greyOut($event)" [globalRefresh]="!isDesktop() && checkLob('IE') ? showGlobalRefresh : null"
      [closeSearch]="closeSearch">
      <ng-container *ngIf="enableNavigation">
        <ng-container *ngIf="!showTwolevelsNavigation && !showMorelinkPrimary">
          <div *ngTemplateOutlet="subLevelItemDropdown"></div>
        </ng-container>
        <ng-container *ngIf="!showTwolevelsNavigation && showMorelinkPrimary">
          <div class="static-header" [ngClass] = "PEEnable ? 'white-text':''" [attr.aria-label]="moreLinkDisplayName">
            {{moreLinkDisplayName ? moreLinkDisplayName : ''}}
          </div>
        </ng-container>
      </ng-container>
    </app-header>
  </div>
  <div class="primaryNavSpacer" [ngClass]="{'refreshSpacer' : showGlobalRefresh && !isDesktop() && checkLob('IE')}">
  </div>

  <ng-container *ngIf="showTwolevelsNavigation && appStore.lob != 'ie'">
    <div class="third-level-nav-below">
      <div *ngTemplateOutlet="subLevelItemDropdown">
      </div>
    </div>
    <div class="thirdLevelNavSpacer"></div>
  </ng-container>
  <ng-container *ngIf="showTwolevelsNavigation && appStore.lob == 'ie'">
    <div class="third-level-nav-horizontal-scroll">
      <ng-container *ngFor="let subLevelMenuItem of subLevelMenu;">
        <button type="button"
          *ngIf="!subLevelMenuItem.externalLink && !(!showOptionCentre && subLevelMenuItem.childrenUrls[0] === '/txn/quotesResearch/optionCentre')"
          [attr.disabled]="subLevelMenuItem.childrenUrls[0] === selectedUrl ? true : null"
          (click)="navigateTo(subLevelMenuItem.childrenUrls[0])">
          {{subLevelMenuItem.displayName}}
        </button>
      </ng-container>
    </div>
  </ng-container>
</div>



<ng-template #subLevelItemDropdown>
  <div id="third-level-nav-dropdown" class="select-item third-level-nav-{{appStore.lob}}"
    *ngIf="!isDesktop() && subLevelMenu?.length > 1"
    [ngClass]="{'thirdLevelNavigation': showTwolevelsNavigation, 'thirdLevelNavigationHeader': !showTwolevelsNavigation}">
    <div *ngIf="appStore.lob == 'ie'">
      <div (click)="setDropDownState(!thirdLevelNavDropDownOpen)">
        <button id="ddButton" attr.aria-label="{{content.text.selectNavigationAlt}}" aria-haspopup="listbox"><span [ngClass]="[PEEnable ? 'color-white':'' ]">{{thirdLevelNavDisplayValue}}</span></button>
        <span class="select-icon thirdLevelNavDropDownIconPosition icon-Arrow-Down" [ngClass]="PEEnable ? 'iconWhite':''" aria-hidden="true">
        </span>
      </div>
      <div *ngIf="thirdLevelNavDropDownOpen" class="dropDownContainer">
        <ul role="listbox">
        <ng-container *ngFor="let subLevelMenuItem of subLevelMenu;index as i;">
          <li class="group" role="option" tabindex="0" (click)="optionSelected(subLevelMenuItem,i)" id="ASSD{{i}}" (keyup)="onKeyUp($event,subLevelMenuItem)"
          (blur)="onBlur($event)" (mouseenter)="onMouseEnter($event)"
            *ngIf="!subLevelMenuItem.externalLink && !(!showOptionCentre && subLevelMenuItem.childrenUrls[0] === '/txn/quotesResearch/optionCentre')">
            <span>{{subLevelMenuItem.displayName}}</span></li>
        </ng-container>
        </ul>
      </div>
    </div>
    <div *ngIf="appStore.lob != 'ie'">
      <div style="display:flex">
        <select attr.aria-label="{{content.text.selectNavigationAlt}}" (change)="navigateTo($event.target.value)">
          <ng-container *ngFor="let subLevelMenuItem of subLevelMenu;">
            <option value="{{subLevelMenuItem.childrenUrls[0]}}"
              [selected]="subLevelMenuItem.childrenUrls[0] === selectedUrl"
              *ngIf="!subLevelMenuItem.externalLink && !(!showOptionCentre && subLevelMenuItem.childrenUrls[0] === '/txn/quotesResearch/optionCentre')">
              {{subLevelMenuItem.displayName}}</option>
          </ng-container>
        </select>
        <span class="select-icon icon-Arrow-Down" style="margin-top:10px" aria-hidden="true">
        </span>
      </div>
    </div>
  </div>
</ng-template>

<!-- mobile bottom sticky nav -->
<ng-container *ngIf="this.appStore.lob != 'ie'; else ieSmallNav">
  <div *ngIf="enableNavigation && !isDesktop()" class="pageFooterWrapper" id="page-footer-wrapper"
    [attr.aria-hidden]="(isScreenReaderON || isKeyboardOpen)? true : false"
    [hidden]="(isScreenReaderON || isKeyboardOpen)" [ngClass]="notched ? 'pageFooterWrapperIos' : ''">
    <div *ngIf="content" class="pageFooterContainer">
      <div class="pd-mobileFooter"
        [ngClass]="(menucontent.isActive ? 'defaultBorderColor' : 'mobileFooterContent') + ' footer-' + (!noMoreLobs.includes(targetLob)? (navigationObject.length + 1) : navigationObject.length)"
        *ngFor="let menucontent of navigationObject" attr.aria-label="{{menucontent.category}}">
        <a *ngIf="menucontent.secondaryLinks[0].externalLink" class="color10 A10"
          [ngClass]="{'mobileFooter': !menucontent.isActive}" (click)="openWindow(menucontent.secondaryLinks[0])">
          <div class='{{menucontent.bottomNavIcon ? menucontent.bottomNavIcon : menucontent.icon}} iconFont'
            aria-hidden="true"
            [ngClass]="{'activeFontColor': menucontent.isActive, 'defaultFontColor': !menucontent.isActive}"></div>
          <span>{{menucontent.bottomNavCategory}}</span>
        </a>
        <a *ngIf="!menucontent.secondaryLinks[0].externalLink" class="color10 A10"
          [ngClass]="{'mobileFooter': !menucontent.isActive}"
          [routerLink]="[menucontent.secondaryLinks[0].childrenUrls[0]]">
          <div class='{{menucontent.bottomNavIcon ? menucontent.bottomNavIcon : menucontent.icon}} iconFont'
            aria-hidden="true"
            [ngClass]="{'activeFontColor': menucontent.isActive, 'defaultFontColor': !menucontent.isActive}"></div>
          <span>{{menucontent.bottomNavCategory}}</span>
        </a>
      </div>
      <div class="stickyNavDropdown stickyNavMoreList" [ngClass]="'footer-' + (navigationObject.length + 1)"
        *ngIf="!noMoreLobs.includes(targetLob)">
        <a class="A10 color02 mobileFooter" id="moreLink" href="javascript:void(0);" aria-haspopup="true"
          aria-expanded="false" (click)="stickyNavToggleDropdown($event)" (blur)="stickyNavFocusOut($event)">
          <div class="icon-More iconFont moreIcon" aria-hidden="true"></div>
          <div>{{content.text.more}}</div>
        </a>
        <div class="stickyNavDropdownContent" id="sticky-nav-dropdown-content">
          <div class="stickyNavDropdownLinks" id="sticky-nav-dropdown-links">
            <ng-container *ngFor="let moreListItem of content.list.moreLinks[0].secondaryLinks">
              <a class="A14 color02 stickyNavMoreLink" (blur)="stickyNavFocusOut($event)"
                *ngIf="!moreListItem.external && moreListItem.url !== '/txn/userPreferences'"
                (click)="closeStickyNavMoreMenu()" routerLink="{{moreListItem.url}}">{{moreListItem.displayName}}
                <span *ngIf="moreListItem.icon" class="icon-class {{moreListItem.icon}}" aria-hidden="true"></span>
              </a>
              <a class="A14 color02 stickyNavMoreLink" (blur)="stickyNavFocusOut($event)"
                *ngIf="!moreListItem.external && moreListItem.url === '/txn/userPreferences'"
                (click)="navUserPref();closeStickyNavMoreMenu()" href="javascript:void(0);">{{moreListItem.displayName}}
                <span *ngIf="moreListItem.icon" class="icon-class {{moreListItem.icon}}" aria-hidden="true"></span>
              </a>
              <a class="A14 color02 stickyNavMoreLink" (blur)="stickyNavFocusOut($event)" href="javascript:void(0);"
                *ngIf="moreListItem.external"
                (click)="openWindow(moreListItem);closeStickyNavMoreMenu()">{{moreListItem.displayName}}
                <span *ngIf="moreListItem.icon" class="icon-class {{moreListItem.icon}}" aria-hidden="true"></span>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #ieSmallNav>
  <div *ngIf="enableNavigation && !isDesktop()" id="page-footer-wrapper"
    [attr.aria-hidden]="(isScreenReaderON || isKeyboardOpen)? true : false"
    [hidden]="(isScreenReaderON || isKeyboardOpen)">
    <div *ngIf="bottomSubNav > -1 && bottomSubNavOpen" (click)="closeBottomSubNav()" class="bottomSubNav-screen-cover">
    </div>
    <app-bottom-sub-nav *ngIf="bottomSubNav > -1" id="bottomSubNav-container" [ngClass]="bottomSubNavOpen ? 'open' : ''"
      [attr.aria-hidden]="bottomSubNavOpen ? true : null">
      <div class="bottomSubNav-header">
        <i [ngClass]="'icon-' + bottomNavObject[bottomSubNav].icon" class="bottomSubNav-header-icon"></i>
        <div class="bottomSubNav-header-label">{{bottomNavObject[bottomSubNav].subNavLabel}}</div>
        <button type="button" (click)="closeBottomSubNav(); focusBottomNav();"
          class="bottomSubNav-header-close icon-Close"></button>
      </div>
      <div class="bottomSubNav-menu" (keydown)="bottomSubNavKeyDown($event)" role="menu">
        <a *ngFor="let subNavButton of bottomNavObject[bottomSubNav].subNav; let i = index"
          [id]="'bottomSubNav-link-' + i" [routerLink]="subNavButton.target" class="bottomSubNav-link"
          (click)="closeBottomSubNav();sendBottomSubNavOmni(subNavButton.omni)"
          attr.aria-setsize="{{bottomNavObject[bottomSubNav].subNav.length}}" attr.aria-posinset="{{bottomSubNav + 1}}"
          role="menuitem">
          <div class="bottomSubNav-link-label">{{subNavButton.label}}
            <span class='new-content-red-box'
            *ngIf="subNavButton.showNewIcon && subNavButton.showNewIcon === 'true' ">{{content.text.new}}</span>
          </div>
          <div class="bottomSubNav-link-desc">{{subNavButton.desc}}</div>
          <span class="sr-only">{{subNavButton.sr}}</span>
        </a>
      </div>
    </app-bottom-sub-nav>
    <div class="pageFooterWrapper-ie" [ngClass]="notched ? 'notched' : ''">
      <div class="ieSmallBottomNav">
        <ng-container *ngFor="let navButton of bottomNavObject; let i = index">
          <a *ngIf="navButton.childUrls.length > 0 && !navButton.subNav" [routerLink]="navButton.childUrls[0].urls[0]"
            class="ieSmallBottomNav-link" [ngClass]="navButton.isActive ? 'active' : ''" (click)="closeBottomSubNav()"
            [id]="'bottomNav-' + i" attr.aria-current="{{navButton.isActive ? true : null}}">
            <div *ngIf="navButton.isActive" class="ieSmallBottomNav-top"></div>
            <div class="ieSmallBottomNav-image" [ngClass]="'icon-' + navButton.icon"></div>
            <div class="ieSmallBottomNav-label">
              {{navButton.label}}
            </div>
          </a>
          <button *ngIf="navButton.subNav" type="button" (click)="toggleBottomSubNav(i)" class="ieSmallBottomNav-button"
            [ngClass]="navButton.isActive ? 'active' : ''" [id]="'bottomNav-' + i"
            attr.aria-haspopup="{{true}}" attr.aria-current="{{navButton.isActive ? true : null}}"
            attr.aria-expanded="{{bottomSubNav == i && bottomSubNavOpen}}">
            <div *ngIf="navButton.isActive" class="ieSmallBottomNav-top"></div>
            <div class="ieSmallBottomNav-image" [ngClass]="'icon-' + navButton.icon"></div>
            <div class="ieSmallBottomNav-label">
              {{navButton.label}}
            </div>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<!-- Desktop Top Navigation -->
<div *ngIf="isDesktop() && enableNavigation"
  [ngClass]="currentUrl == '/txn/accounts/myaccounts' && targetLob == 'ie' ? '' : 'marginBottom10'">
  <nav *ngIf="navigationObject" id="nav-desktop-menu" class="firstLevelNavWrapper navigation"
    (keydown)="navKeys($event)" tabindex="-1" aria-describedby="nav-menu-instructions">
    <span class="sr-only" id="nav-menu-instructions">{{content.text.navDescription}}</span>
    <ul class="menuOptions" role="menubar" [ngClass]="{'sidebarIE': checkLob('IE')}">
      <li *ngFor="let menuContent of navigationObject; let i = index" class="menuListItem pd-15 firstLevelNavHover"
        [ngClass]="{'active-link': menuContent.isActive}" routerLinkActive (mouseover)="mouseOver(i)"
        (mouseout)="mouseOut(i)" (focusin)="focusIn(i)" (focusout)="focusOut(i)" role="none">
        <a *ngIf="!menuContent.secondaryLinks[0].externalLink && menuContent.secondaryLinks[0].childrenUrls[0] !== '/txn/userPreferences'"
          class="W14m color02" [routerLink]="[menuContent.secondaryLinks[0].childrenUrls[0]]"
          attr.aria-label="{{menuContent.category}}"
          (click)="closeMoreMenu(i);sortSubAccountsList(menuContent.category);mainNavInteraction(menuContent.omniName)"
          [id]="'navigation-link-' + i" (focus)="focusIndividual(-1);focusThirdNav(-1)"
          attr.aria-haspopup="{{menuContent.secondaryLinks.length > 1 && checkLob('IE') && !menuContent.isActive ? true : null}}"
          attr.aria-current="{{menuContent.isActive ? true : null}}"
          attr.aria-expanded="{{menuContent.secondaryLinks.length > 1 && checkLob('IE') && !menuContent.isActive ? currentDropdownMenu == i && !closeDropdownMenu : null}}"
          role="menuitem" attr.aria-setsize="{{targetLob == 'ie' ? navigationObject.length + 1 : null}}"
          attr.aria-posinset="{{targetLob == 'ie' ? i+1 : null}}">
          <span>{{menuContent.category}}</span>
          <span class='new-content-red-box'
            *ngIf="menuContent.showNewIconBig && menuContent.showNewIconBig === 'true'">{{content.text.new}}</span>
        </a>
        <a *ngIf="!menuContent.secondaryLinks[0].externalLink && menuContent.secondaryLinks[0].childrenUrls[0] === '/txn/userPreferences'"
          class="W14m color02" href="javascript:void(0)" attr.aria-label="{{menuContent.category}}"
          (click)="navUserPref();closeMoreMenu(i);sortSubAccountsList(menuContent.category);mainNavInteraction(menuContent.omniName)"
          [id]="'navigation-link-' + i" (focus)="focusIndividual(-1)"
          attr.aria-haspopup="{{menuContent.secondaryLinks.length > 1 && checkLob('IE') && !menuContent.isActive ? true : null}}"
          attr.aria-current="{{menuContent.isActive ? true : null}}"
          attr.aria-expanded="{{menuContent.secondaryLinks.length > 1 && checkLob('IE') && !menuContent.isActive ? currentDropdownMenu == i && !closeDropdownMenu : null}}"
          role="menuitem" attr.aria-setsize="{{targetLob == 'ie' ? navigationObject.length + 1 : null}}"
          attr.aria-posinset="{{targetLob == 'ie' ? i+1 : null}}">
          {{menuContent.category}}</a>
        <a *ngIf="menuContent.secondaryLinks[0].externalLink" class="W14m color02" href="javascript:void(0)"
          attr.aria-label="{{menuContent.category}}"
          (click)="openWindow(menuContent.secondaryLinks[0]);closeMoreMenu(i);mainNavInteraction(menuContent.omniName)"
          [id]="'navigation-link-' + i" (focus)="focusIndividual(-1)"
          attr.aria-haspopup="{{menuContent.secondaryLinks.length > 1 && checkLob('IE') && !menuContent.isActive ? true : null}}"
          attr.aria-current="{{menuContent.isActive ? true : null}}"
          attr.aria-expanded="{{menuContent.secondaryLinks.length > 1 && checkLob('IE') && !menuContent.isActive ? currentDropdownMenu == i && !closeDropdownMenu : null}}"
          role="menuitem" attr.aria-setsize="{{targetLob == 'ie' ? navigationObject.length + 1 : null}}"
          attr.aria-posinset="{{targetLob == 'ie' ? i+1 : null}}">
          <span>{{menuContent.category}}</span>
          <span class='new-content-red-box'
            *ngIf="menuContent.showNewIconBig && menuContent.showNewIconBig === 'true'">{{content.text.new}}</span></a>
        <a *ngIf="menuContent.secondaryLinks.length > 1 && checkLob('IE') && !menuContent.isActive"
          href="javascript:void(0)" (click)="goToHoverMenu(i)"
          class="menuListItemHoverSkip">{{content.text.openSubMenu}} {{menuContent.category}}.</a>
        <div
          *ngIf="menuContent.secondaryLinks.length > 1 && checkLob('IE') && (!menuContent.isActive || menuContent.displayDropdownInActive) && currentDropdownMenu == i && !closeDropdownMenu"
          class="dropdownContainer open">
          <div class="dropdownArrow"></div>
          <div class="dropdownContent" [id]="'dropdown-content-' + this.categoryDashFilter(menuContent.category)">
            <ul class="color02 dropdownLinks" role="menu">
              <li *ngFor="let menuListItem of menuContent.secondaryLinks; let j = index" role="none">
                <a *ngIf="menuListItem.externalLink"
                  (click)="openWindow(menuListItem);closeMoreMenu(i);hoverNavInteraction(menuContent.omniName, menuListItem.omniName)"
                  href="javascript:void(0);" [id]="'navigation-link-' + i + '-' + (j)" (focus)="focusIndividual(j)"
                  role="menuitem" attr.aria-setsize="{{menuContent.secondaryLinks.length}}"
                  attr.aria-posinset="{{j+1}}">
                  {{menuListItem.displayName}}
                  <span *ngIf="menuListItem.icon" class="icon-class {{menuListItem.icon}}"></span>
                </a>
                <a *ngIf="!menuListItem.externalLink && menuListItem.childrenUrls[0] !== '/txn/userPreferences' && menuListItem.childrenUrls[0] !== '/txn/quotesResearch/optionCentre' && !menuListItem.thirdLevelNavigation"
                  (click)="closeMoreMenu(i);sortSubAccountsList(menuContent.category);hoverNavInteraction(menuContent.omniName, menuListItem.omniName)"
                  routerLink="{{menuListItem.childrenUrls[0]}}" [id]="'navigation-link-' + i + '-' + (j)"
                  (focus)="focusIndividual(j);focusThirdNav(-1)" role="menuitem"
                  attr.aria-setsize="{{menuContent.secondaryLinks.length}}" attr.aria-posinset="{{j+1}}">
                  {{menuListItem.displayName}}
                  <span *ngIf="menuListItem.icon" class="icon-class {{menuListItem.icon}}"></span>
                </a>
                <a *ngIf="!menuListItem.externalLink && menuListItem.childrenUrls[0] === '/txn/userPreferences'"
                  (click)="navUserPref();closeMoreMenu(i);sortSubAccountsList(menuContent.category);hoverNavInteraction(menuContent.omniName, menuListItem.omniName)"
                  href="javascript:void(0);" [id]="'navigation-link-' + i + '-' + (j)" (focus)="focusIndividual(j)"
                  role="menuitem" attr.aria-setsize="{{menuContent.secondaryLinks.length}}"
                  attr.aria-posinset="{{j+1}}">
                  {{menuListItem.displayName}}
                  <span *ngIf="menuListItem.icon" class="icon-class {{menuListItem.icon}}"></span>
                </a>
                <a *ngIf="!menuListItem.externalLink && menuListItem.thirdLevelNavigation" routerLink="{{menuListItem.childrenUrls[0]}}"
                  [id]="'navigation-link-' + i + '-' + (j)" (focus)="focusIndividual(j)" role="menuitem"
                  attr.aria-setsize="{{menuContent.secondaryLinks.length}}" attr.aria-posinset="{{j+1}}">
                  {{menuListItem.displayName}}
                  <span *ngIf="menuListItem.icon" class="icon-class {{menuListItem.icon}}"></span>
                </a>
                <div
                  *ngIf="!menuListItem.externalLink && menuListItem.thirdLevelNavigation">
                  <div
                    *ngFor="let thirdLevelNavItem of menuContent.secondaryLinks[j].thirdLevelNavigation; let k = index">
                    <a class="paddingLeft30" [routerLink]="[thirdLevelNavItem.url]"
                      [id]="'navigation-link-' + i + '-' + (j) + '-' + (k)" (focus)="focusThirdNav(k)"
                      (click)="closeMoreMenu(i);sortSubAccountsList(menuContent.category);">
                      {{thirdLevelNavItem.displayName}} <span class='new-content-red-box'
                      *ngIf="thirdLevelNavItem.showNewIconBig && thirdLevelNavItem.showNewIconBig === 'true' ">{{content.text.new}}</span>
                    </a>
                    
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </li>

      <li class="dropdown menuListItem pd-15" *ngIf="!noMoreLobs.includes(targetLob)" role="none">
        <div class="moreListItem" (mouseover)="mouseOver(navigationObject.length)"
          (mouseout)="mouseOut(navigationObject.length)" (focusin)="focusIn(navigationObject.length)"
          (focusout)="focusOut(navigationObject.length)">
          <a id="moreLink" class="W14m color02" href="javascript:void(0);" attr.aria-haspopup="true"
            attr.aria-expanded="{{currentDropdownMenu == navigationObject.length && !closeDropdownMenu}}"
            (click)="!checkLob('IE') ? goToHoverMenu(navigationObject.length) : null"
            [id]="'navigation-link-' + navigationObject.length" (focus)="focusIndividual(-1)" role="menuitem"
            attr.aria-setsize="{{targetLob === 'ie' ? navigationObject.length + 1 : null}}"
            attr.aria-posinset="{{targetLob === 'ie' ? navigationObject.length + 1 : null}}">{{content.text.more}}
          </a>
          <a id="nav_checkLob_IE_id" *ngIf="checkLob('IE')" href="javascript:void(0)" (click)="goToHoverMenu(navigationObject.length)"
            class="menuListItemHoverSkip">{{content.text.openSubMenu}} {{content.text.more}}.</a>
          <span class="icon-Arrow-Down color03" aria-hidden="true"></span>
          <div class="dropdownContainer open"
            *ngIf="currentDropdownMenu == navigationObject.length && !closeDropdownMenu"
            [ngClass]="checkLob('IE') ? '' : 'more-normal'">
            <div class="dropdownArrow"></div>
            <div class="dropdownContent" id="dropdown-content-More">
              <ul class="color02 dropdownLinks" role="menu">
                <li *ngFor="let menuListItem of content.list.moreLinks[0].secondaryLinks; let j = index" role="none">
                  <a (click)="openWindow(menuListItem);closeMoreMenu(navigationObject.length);hoverNavInteraction('more', menuListItem.omniName)"
                    href="javascript:void(0);" *ngIf="menuListItem.external"
                    [id]="'navigation-link-' + navigationObject.length + '-' + (j)" (focus)="focusIndividual(j)"
                    role="menuitem"
                    attr.aria-setsize="{{targetLob === 'ie' ? (isPermission('MKTLN') ? content.list.moreLinks[0].secondaryLinks.length + 1 : content.list.moreLinks[0].secondaryLinks.length) : null}}"
                    attr.aria-posinset="{{targetLob === 'ie' ? j+1 : null}}">
                    {{menuListItem.displayName}}
                    <span *ngIf="menuListItem.icon" class="icon-class {{menuListItem.icon}}"></span>
                  </a>
                  <a *ngIf="!menuListItem.external && menuListItem.url !== '/txn/userPreferences'"
                    (click)="closeMoreMenu(navigationObject.length);hoverNavInteraction('more', menuListItem.omniName)"
                    routerLink="{{menuListItem.url}}" [id]="'navigation-link-' + navigationObject.length + '-' + j"
                    (focus)="focusIndividual(j)" role="menuitem"
                    attr.aria-setsize="{{targetLob === 'ie' ? (isPermission('MKTLN') ? content.list.moreLinks[0].secondaryLinks.length + 1 : content.list.moreLinks[0].secondaryLinks.length) : null}}"
                    attr.aria-posinset="{{targetLob === 'ie' ? j+1 : null}}">
                    {{menuListItem.displayName}}
                    <span *ngIf="menuListItem.icon" class="icon-class {{menuListItem.icon}}"></span>
                  </a>
                  <a *ngIf="!menuListItem.external && menuListItem.url === '/txn/userPreferences'"
                    (click)="navUserPref();closeMoreMenu(navigationObject.length);hoverNavInteraction('more', menuListItem.omniName)"
                    href="javascript:void(0);" [id]="'navigation-link-' + navigationObject.length + '-' + j"
                    (focus)="focusIndividual(j)" role="menuitem"
                    attr.aria-setsize="{{targetLob === 'ie' ? (isPermission('MKTLN') ? content.list.moreLinks[0].secondaryLinks.length + 1 : content.list.moreLinks[0].secondaryLinks.length) : null}}"
                    attr.aria-posinset="{{targetLob === 'ie' ? j+1 : null}}">
                    {{menuListItem.displayName}}
                    <span *ngIf="menuListItem.icon" class="icon-class {{menuListItem.icon}}"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </nav>

  <!-- Desktop Secondary level Navigation -->
  <div *ngIf="subLevelMenu?.length > 1 && !disableSecondaryNav" class="secondLevelNavWrapper navigation" (focusin)="focusIn(null)"
    role="menubar">
    <div class="secondaryLevelMenuOptions" id="nav-desktop-submenu" [ngClass]="{'sidebarIE': checkLob('IE')}">
      <div
        [ngClass]="{'secondaryLevelMenuListItem': j > 0 && (subLevelMenuItem.externalLink || (!(!showOptionCentre && subLevelMenuItem.childrenUrls[0] === '/txn/quotesResearch/optionCentre')))}"
        class="menuListItem secondLevelNavHover" *ngFor="let subLevelMenuItem of subLevelMenu; let j = index"
        (mouseover)="mouseOverSecondaryNav(j)" (mouseout)="mouseOutSecondaryNav(j)" (focusin)="focusInSecondNav(j)"
        (focusout)="focusOutSecondNav(j)">
        <a id="nav_secondLevelNavInteraction_id-{{j}}" *ngIf="subLevelMenuItem.externalLink" class="W12m color02" routerLinkActive
          [attr.aria-label]="subLevelMenuItem.displayName + ' ' + (subLevelMenuItem.showNewIconBig && subLevelMenuItem.showNewIconBig === 'true' ? content.text.new : '')"
          attr.aria-current="{{null}}" attr.aria-setsize="{{targetLob === 'ie' ? subLevelMenu.length : null}}"
          attr.aria-posinset="{{targetLob === 'ie' ? j+1 : null}}" role="menuitem"
          (click)="openWindow(subLevelMenuItem);secondLevelNavInteraction(navigationObject[subLevelMenuIndex].omniName,subLevelMenuItem.omniName)">
          <span>{{subLevelMenuItem.displayName}}</span>
          <span *ngIf="subLevelMenuItem.indicator && appStore.globalAlert[subLevelMenuItem.indicator]"
            attr.aria-hidden="true" class="red-indicator"></span>
          <span class='new-content-red-box'
            *ngIf="subLevelMenuItem.showNewIconBig && subLevelMenuItem.showNewIconBig === 'true' ">{{content.text.new}}</span>
        </a>
        <a id="nav_secondLevelNavInteraction2_id-{{j}}" class="secondLevelNavLink W12m color02"
          [ngClass]="{'subNav-active-link': subLevelMenuItem.isSecondaryLinkActive}"
          routerLink="{{subLevelMenuItem.childrenUrls[0]}}" routerLinkActive
          [attr.aria-label]="subLevelMenuItem.displayName + ' ' + (subLevelMenuItem.showNewIconBig && subLevelMenuItem.showNewIconBig === 'true' ? content.text.new : '')"
          attr.aria-current="{{subLevelMenuItem.isSecondaryLinkActive ? true : null}}"
          attr.aria-setsize="{{targetLob === 'ie' ? subLevelMenu.length : null}}"
          attr.aria-posinset="{{targetLob === 'ie' ? j+1 : null}}" role="menuitem"
          (click)="secondLevelNavInteraction(navigationObject[subLevelMenuIndex].omniName,subLevelMenuItem.omniName)"
          *ngIf="!subLevelMenuItem.externalLink &&
          !(!showOptionCentre && subLevelMenuItem.childrenUrls[0] === '/txn/quotesResearch/optionCentre')">
          <span>{{subLevelMenuItem.displayName}}</span>
          <span *ngIf="subLevelMenuItem.indicator && appStore.globalAlert[subLevelMenuItem.indicator]"
            attr.aria-hidden="true" class="red-indicator"></span>
          <span class='new-content-red-box'
            *ngIf="subLevelMenuItem.showNewIconBig && subLevelMenuItem.showNewIconBig === 'true' ">{{content.text.new}}</span>
          <div [attr.id]="'second-level-nav-chevron-' + j" *ngIf="subLevelMenuItem.thirdLevelNavigation"
            class="icon-Chevron-Down color03 thirdLevelNav-icon animateUp" aria-hidden="true"></div>
        </a>
        <div
          *ngIf="subLevelMenuItem.thirdLevelNavigation && checkLob('IE') && displaySecondNavDropdown && currentSecondNav == j"
          class="dropdownContainer open">
          <div class="dropdownArrow secondNavDropdownArrow"></div>
          <div class="dropdownContent secondNavDropdownContent">
            <ul class="color02 dropdownLinks" role="menu">
              <li *ngFor="let thirdLevelNavItem of subLevelMenuItem.thirdLevelNavigation; let k = index" role="none">
                <a routerLinkActive="thirdNav-active-link" [routerLinkActiveOptions]="{exact: true}"
                  class="thirdLevelNavLink" [routerLink]="[subLevelMenuItem.thirdLevelNavigation[k].url]"
                  [id]="'sub-navigation-link-' + (j) + '-' + (k)" attr.aria-setsize="{{thirdLevelNavItem.length}}"
                  attr.aria-posinset="{{k+1}}">
                  {{thirdLevelNavItem.displayName}} <span class='new-content-red-box'
                  *ngIf="thirdLevelNavItem.showNewIconBig && thirdLevelNavItem.showNewIconBig === 'true' ">{{content.text.new}}</span>
                </a>
                
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal [attr.id]="'dcoAppNavBar'" [id]="'dcoAppNavBar'" [header]="'dcoAppModalHeaderNav'" style='display: none;'
  (keydown.esc)="closeDCOModal()" targetElement="dcoAppLink">
  <div class="cibc-account-selector-modal-body" aria-controls="dcoApp">
    <div class="cibc-account-selector-modal-content">
      <div class="removeHeader allignCentre" id="dcoAppModalHeaderNav" tabindex="-1">
        {{content?.text?.dcoAPPModalHeader}}
      </div>
      <div class="marginTop30">
        <section [ngClass]="isDesktop()?'float-right':'page-bottom-left-button-group'">
          <button id="nav_continue_btn_id" type="submit" class='button primary marginBottom10-small' (click)="submitDCOApp()">
            {{content?.text?.continueLabel}}
          </button>
        </section>
        <section [ngClass]="isDesktop()?'':'page-bottom-right-button-group'">
          <button id="nav_cancel_btn_id" type="button" class='button secondary' (click)="closeDCOModal()">
            {{content?.text?.cancelLabel}}
          </button>
        </section>
      </div>
    </div>
  </div>
</app-modal>

<app-modal [attr.id]="'marketlensTredingPwdAppModalNav'" [id]="'marketlensTredingPwdAppModalNav'"
  [header]="'marketlensTredingPwdAppModalHeaderNav'" style='display: none;' (keydown.esc)="closeMTPModal()">
  <div class="cibc-account-selector-modal-body " [ngClass]="isDesktop() ? 'dis-top' : ''">
    <div class="cibc-account-selector-modal-content">
      <div class="removeHeader" id="marketlensTredingPwdAppModalHeaderNav" tabindex="-1">
        {{content?.text?.tradingPasswordModalHeader}}
      </div>
      <app-trading-password-form [globalContent]="content" [otvcUniqueId]="uniqueId" (close)="closeMTPModal()">
      </app-trading-password-form>
    </div>
  </div>
</app-modal>